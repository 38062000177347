import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProductsListDesktopNew from './ProductsListDesktopNew'
import ProductsListMobileNew from './ProductsListMobileNew'
import { withGetScreen } from 'react-getscreen'

class ProductsList extends Component {
  render() {
    if (this.props.isMobile()) {
      return <ProductsListMobileNew />;
    } else {
      return <ProductsListDesktopNew />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(ProductsList, options))
