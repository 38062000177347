import React from 'react';
import './styles.scss';
import { translate } from "../../translations/localization";
import { useState, useEffect } from 'react';
import { history } from "../../config/stores";
import { SensorsLoginStepOne, SensorsLoginStepTwo, SensorsLoginFailed } from '../../components';

export default function SensorsDesktop() {
  const [ phoneNumber, setPhoneNumber ] = useState(null);
  const [ codeNumbers, setCodeNumbers ] = useState(Array(4).fill(''));
  const [ loginStep, setLoginStep ] = useState(1);


  const handleChange = (e) => {
    const inputVal = e.target.value;
    const numericValue = inputVal.replace(/\D/g, '');

    if (numericValue.length < 10) setPhoneNumber(numericValue);
  };

  const handleChangeCode = (e, index) => {
    let newCodeNumbers = JSON.parse(JSON.stringify(codeNumbers))
    let newValue = e.target.value.replace(/\D/g, '');
    if (newValue.length > 1) {
      newValue = newValue.charAt(0); // Only keep the first digit
    }
    if (index > -1) {
      newCodeNumbers[index] = newValue
    }

    setCodeNumbers(newCodeNumbers)
  };

  return (
    <div className="sensors-wrapper">
      <div className="container">
        {loginStep === 0 && <SensorsLoginFailed phoneNumber={phoneNumber} setLoginStep={setLoginStep} />}
        {loginStep === 1 && <SensorsLoginStepOne phoneNumber={phoneNumber} handleChange={handleChange} setLoginStep={setLoginStep} />}
        {loginStep === 2 && <SensorsLoginStepTwo codeNumbers={codeNumbers} handleChangeCode={handleChangeCode} phoneNumber={phoneNumber} setLoginStep={setLoginStep} />}
      </div>
    </div>
  )
}
