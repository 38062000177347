import React, { Component } from 'react';
import { NavMainMobileItems } from '../../components'
import './mstyles.scss'

class NavMainMobile extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, toggleView: false };
  }
  closeMenu() {
    this.setState({ isOpen: false })
  }
  toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen });
  }


  render() {
    let toggleNavStyles ='nav-main-mobile-wrap';
    if (this.state.isOpen) {
      toggleNavStyles = 'nav-main-mobile-wrap isOpen';
      document.body.style.overflow = 'hidden';
    } else {
      toggleNavStyles = 'nav-main-mobile-wrap';
      document.body.style.overflow = 'auto';
    }

    return (
      <div className={toggleNavStyles}>
        <div className={ this.state.isOpen ? "burger-icon burger-icon-isOpen" : "burger-icon"} onClick={this.toggleMenu.bind(this)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {this.state.toggleView ? 
        <div className="additional-info-outer-container">
          <div className="additional-info-container">
          <div className="top-container">
            <div className="exit-btn" onClick={() => this.setState({toggleView: false})}/>
          </div>
          <div className="header-image" />
          <h1>КАЛКУЛАТОРИ</h1>
            <p>
            За да използвате тези функции на сайта, 
            моля влезте през устройство с размер
            на екрана над 10 инча {"("}таблет, лаптоп
            или настолен компютър{")"}.
            </p>
            </div> 
          </div>
          : 
          <div onClick={() => this.closeMenu()}  className='nav-main-toggle'>
          <NavMainMobileItems onToggle={() => this.setState({toggleView: true})} />
        </div>}
    
      </div>
    );
  }
}

export default NavMainMobile;
