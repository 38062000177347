export const CalculatorTypes = {
  POST_DATA_START: 'calculator/POST_DATA_START',
  POST_DATA_SUCCESS: 'calculator/POST_DATA_SUCCESS',
  POST_DATA_FAILURE: 'calculator/POST_DATA_FAILURE',
  REQUEST_CODE: "calculator/REQUEST_CODE",
  REQUEST_CODE_SUCCESS: 'calculator/REQUEST_CODE_SUCCESS',
  SEND_CODE: 'calculator/SEND_CODE',
  SEND_CODE_SUCCESS: 'calculator/SEND_CODE_SUCCESS',

  POST_CALCULATION: 'calculation/POST_CALCULATION',
  POST_CALCULATION_SUCCESS: 'calculation/POST_CALCULATION_SUCCESS',
}

export const postData = payload => ({
  type: CalculatorTypes.POST_DATA_START,
  payload
})

export const postCalculation = payload => ({
  type: CalculatorTypes.POST_CALCULATION,
  payload
})

export const postCalculationSuccess = payload => ({
  type: CalculatorTypes.POST_CALCULATION_SUCCESS,
  payload
})


export const requestCode = payload => ({
  type: CalculatorTypes.REQUEST_CODE,
  payload
})

export const sendCode = payload => ({
  type: CalculatorTypes.SEND_CODE,
  payload
})