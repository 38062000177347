import React from 'react';
import './styles.scss';
import moment from 'moment';

export default function NewsCard({ keyId, photoLink, title, date, onClick }) {
  const language = localStorage.getItem('code') ?? 'bg'

  return (
    <div className="single-card-wrapper" key={`${date}-${photoLink}-${keyId}`} onClick={onClick}>
      {/* <img src={require(`../../assets/images/${photoLink}`)} /> */}
      <img src={photoLink} alt="news photo" />
      {language === 'bg' ?
      <p>{moment(date).locale('bg').format('DD MMMM YYYY')}</p> :
      <p>{moment(date).locale('en').format('DD MMMM YYYY')}</p>}
      <h4 className='title'>{title}</h4>

      <div className="flex-wrapper-card wide">
        <div className="icon">
          <img src={require('../../assets/icons/arrow-bttn.svg')} alt="read more arrow" />
        </div>

      </div>
    </div>
  )
}