import React, { useState } from "react";
import { translate } from "../../translations/localization";
import { history } from "../../config/stores";
import "./styles.scss";
import { Utilities } from "../../utilities";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getProducts, getReferences, setModal } from "../../actions";

export default function SearchResultsDesktop() {
  const dispatch = useDispatch();

  const productsData = useSelector((state) => state.products.searchedProducts);
  const referenceData = useSelector((state) => state.references.searchedReferences);
  const query = useSelector((state) => state.general.query);
  const nextPageProducts = useSelector(state => state.products.nextPage);
  const nextPageReferences = useSelector(state => state.references.nextPage);

  const [activeTab, setActiveTab] = useState("Продукти");

  const productsList = productsData?.map((product, i) => {
    return (
      <div className="col" key={product?._id}>
        <Link
          to={product?.pdfFile ? '/Products-list/' + product?.name?.en : "/product/" + product?._id}
          className={`item-link ${product?.pdfFile ? 'pdf' : ''}`}>
          <span className="img" onClick={() => {
              if (product?.pdfFile) {
                window.open(product.pdfFile);
              }
            }}>
            {product?.pdfFile && <img src={require('../../assets/icons/pdf-file-blue.svg')} alt='pdf' className='pdf-icon' /> }
            <img className="main-photo" src={product?.mainPhoto} alt="product" />
          </span>
          <span className="info">
            <h2 className="h3">{Utilities.translateLanguage(product?.name)}</h2>
          </span>
        </Link>
      </div>
    );
  });

  const referencesList = referenceData?.map((ref, i) => {
    return (
      <Link
      to={`/reference/${ref?._id}`}
      style={{ textDecoration: "none" }}>
      <div className="reference">
      <div className="reference-text-container">
        <p id="title" className="no-upper-case no-space">{Utilities.translateLanguage(ref?.title)}</p>
        <p id="text" className="text overflow">{Utilities.translateLanguage(ref?.project)}</p>
      </div>
      <div className="icon-right" />
    </div>
    </Link>
    );
  });

  const loadPageOnScroll = () => {
    if (activeTab === "Продукти" && nextPageProducts) {
      let payload = { page: nextPageProducts, search: query }
      // dispatch(startLoading());
      dispatch(getProducts(payload)) 
    }
    else if (activeTab === "Референции" && nextPageReferences)
    if (nextPageReferences) {
      let payload = { page: nextPageReferences, search: query };
      // dispatch(startLoading());
      dispatch(getReferences(payload)); 
    }
  }

  return (
    <div className="search-results-container">
      <div className="search-results-black-wrapper">
        <div className="search-button-wrapper" onClick={() => history.goBack()}>
            <img src={require('../../assets/icons/arrow-back-white.svg')} alt="go back" />
            <h5>{translate('searchTitle')}</h5>
        </div>
        <div className="search-results-inner-container">
          <div className="search-bar-container">
           <div className="flex-container-new-search" onClick={() => dispatch(setModal(true))}>
              <input
                className="search-input"
                autoComplete="off"
                type="text"
                value={query}
                placeholder=""
              />
              <div className="search-icon-wrapper">
               <div className="search-icon" />
              </div>
             </div>
          </div>
       
          <div className="tabs-container">
            <div
              className={`tab ${activeTab === "Продукти" ? "active" : ""}`}
              onClick={() => setActiveTab("Продукти")}
            >
              {`${translate("headerProducts")}`}
            </div>
            <div
              className={`tab ${activeTab === "Референции" ? "active" : ""}`}
              onClick={() => setActiveTab("Референции")}
            >
              {`${translate("headerReferences")}`}
            </div>
          </div>

          <div className="main-content">
            <PerfectScrollbar onYReachEnd={() => loadPageOnScroll()}>
              {activeTab === "Продукти" ? 
             <div className="products-container row row-items">{productsList}</div>
             : activeTab === "Референции" ? (
              <div className="references-container">
                {referencesList}
              </div>
            ) : null}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   products: state.products.data,
//   query: state.search.query,
//   references: state.products.references,
// });

// export default connect(mapStateToProps, null)(SearchResultsDesktop);
