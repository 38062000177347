import React from "react";
import { FormContactUs } from "../../components";
import { translate } from "../../translations/localization";
import { history } from "../../config/stores";
import "./styles.scss";

class MakeAQueryBttn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      toggleStyle: "modal-make-a-query isClose",
    };
  }

  toggle = () => {
    if (this.state.isOpen) {
      document.getElementById("make-a-query").className = "make-a-query";
      document.getElementById("header").style.opacity = "1";
      this.setState({ toggleStyle: "modal-make-a-query isClose" });
    } else {
      this.setState({ toggleStyle: "modal-make-a-query isOpen" });
      document.getElementById("make-a-query").className =
        "make-a-query overlay";
      document.getElementById("header").style.opacity = ".1";
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div className="make-a-query" id="make-a-query" onClick={() => history.push('/contact/form')}>
        <div className="query-bttn">
          {/* {this.state.isOpen ? (
            <span className="bttn-close" onClick={this.toggle}>
              <span className="icon iconClose icon-close"></span>
            </span>
          ) : (
            <button className="bttn" onClick={this.toggle}>
              {translate("queryButton")}
            </button>
          )} */}
            <button className="bttn">
              {translate("queryButton")}
            </button>
        </div>
        <div className={this.state.toggleStyle} id="modal-make-a-query">
          <div className="form-contact-us-wrap">
            <FormContactUs />
          </div>
        </div>
      </div>
    );
  }
}
export default MakeAQueryBttn;
