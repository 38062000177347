// import { transformSync } from "@babel/core";
import { StarterTypes, ProductTypes } from "../actions";
import { translate } from "../translations/localization";
const initialState = {
  products: [],
  productsByCategory: [],
  searchedProducts: [],
  categories: [],
  category: {},
  page: 1,
  nextPage: null,
  nextPageCategory: null,
  hasNextPage: null,
  singleProduct: {},
  categoryId: ''
};

const getCategory = (payload, searchedCategory) => {
  let categoryData = {}

  if (!payload?.length) return {}
  if (payload?.length === 0) return payload[0]

  categoryData = payload.find(cat => cat?.name?.bg === searchedCategory || cat?.name?.en === searchedCategory)

  return categoryData
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case StarterTypes.START_LOADING:
      return { ...state };
    case StarterTypes.STOP_LOADING:
      return { ...state };
    case ProductTypes.GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload.page === 1 ? action.payload.docs : [...state.products, ...action.payload.docs], searchedProducts: action.payload.page === 1 ? action.payload.docs : [...state.products, ...action.payload.docs], hasNextPage: action.payload.hasNextPage, nextPage: action.payload.nextPage, singleProduct: {}, productsByCategory: [] };
    case ProductTypes.GET_PRODUCTS_BY_CATEGORY_SUCCESS:
      return { ...state, productsByCategory: action.payload.page === 1 ? action.payload.docs : [...state.productsByCategory, ...action.payload.docs], searchedProducts: action.payload.page === 1 ? action.payload.docs : [...state.productsByCategory, ...action.payload.docs], hasNextPage: action.payload.hasNextPage, nextPage: action.payload.nextPage, singleProduct: {} };
    case ProductTypes.GET_SINGLE_PRODUCT_SUCCESS:
      return { ...state, singleProduct: action.payload };
    case ProductTypes.GET_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload.page === 1 ? action.payload.docs : [...state.categories, ...action.payload.docs], hasNextPage: action.payload.hasNextPage, nextPageCategory: action.payload.nextPage, category: {}, products: [] };
    case ProductTypes.SEARCH_CATEGORIES_SUCCESS:
      return { ...state, category: getCategory(action.payload.payload.docs, action.payload.category) };
    case ProductTypes.SEARCH_CATEGORY_SUCCESS:
      return { ...state, categoryId: action?.payload?.payload?.docs?.[0]?._id };
    default:
      return state;
  }
};

export default products;
