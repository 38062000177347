import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "../../translations/localization";
import { Step1, Step2 } from "./";
import Cookies from "js-cookie";
import { addContactDetails, requestCode, sendCode } from "../../actions";
import "./SpecializedMaterialsForm.scss";
import { withRouter } from "react-router";

class SpecializedMaterialsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: "",
      fields: {},
      errors: {},
      currentStep: 1,
      stepOne: "",
      stepTwo: "",
      allInputs: [],
      step1data: [
        {
          name: "",
          company: "",
          email: "",
          phone: "",
        },
      ],
      message: {
        to: "hydroprobg1@gmail.com, team@fidweb.net",
        body: "",
      },
      submitting: false,
      error: false,
    };
    this._next = this._next.bind(this);
  }

  componentWillMount() {
    this.setState({ allInputs: [] });
  }

  componentDidUpdate(prevProps) {
    if (this.props.proceed !== prevProps.proceed) {
      if (this.props.proceed) {
        this._next();
      }
    }
    if (this.props.code !== prevProps.code) {
      if (this.props.code) {
        this.handleSubmit();
      }
    }
  }

  saveValues(input) {
    let allInputs = this.state.allInputs;
    allInputs.push(input);
    this.setState({ allInputs: allInputs });
  }

  _next() {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 2 ? 2 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  stepData(data) {
    this.setState({ step1data: data });
  }

  codePass(code) {
    this.setState({ insertedCode: code });
  }
  handleSubmit = () => {
    this.props.addContactDetails({
      ...this.state.step1data,
    });
    Cookies.set("name", this.state.step1data[0].name, { expires: 365 });
    Cookies.set("email", this.state.step1data[0].email, { expires: 365 });
    Cookies.set("phone", this.state.step1data[0].phone, { expires: 365 });
    Cookies.set("company", this.state.step1data[0].company, { expires: 365 });
    this.props.history.push("/specialized-materials-list");
  };

  get nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <div className="content-footer">
          <div className="form-row-bttn">
            {this.state.errors.name ? (
              <span className="error">{this.state.errors.name}</span>
            ) : null}
            {this.state.step1data[0].name.length <= 2 ||
            this.state.step1data[0].company.length <= 2 ||
            this.state.step1data[0].email.length <= 2 ||
            !this.state.step1data[0].email.includes("@") ||
            this.state.step1data[0].phone.length <= 10 ? (
              <button
                className="bttn"
                onClick={() => alert(translate("loginAlert"))}
              >
                {translate("loginNext")} <span className="icon icon-arrow-right"></span>
              </button>
            ) : (
              <button
                className="bttn"
                onClick={() => {
                  this.props.requestCode({
                    phoneNumber: this.state.step1data[0].phone,
                    name: this.state.step1data[0].name,
                    companyName: this.state.step1data[0].company,
                    email: this.state.step1data[0].email,
                  });
                }}
              >
                {translate("loginNext")} <span className="icon icon-arrow-right"></span>
              </button>
            )}
          </div>
          <div className="progress-line">
            <div className="line" style={{ width: "33.3333%" }}></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="content-footer">
          <div className="form-row-bttn">
            <button
              className="bttn"
              onClick={() => {
                this.props.sendCode({
                  code: this.state.insertedCode,
                  phoneNumber: this.state.step1data[0].phone,
                });
              }}
            >
              {translate("loginNext")} <span className="icon icon-arrow-right"></span>
            </button>
          </div>
          <div className="progress-line">
            <div className="line" style={{ width: "66.6666%" }}></div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="form-materials-contact">
        <Step1
          currentStep={this.state.currentStep}
          stepOne={this.state.stepOne}
          dataPass={this.stepData.bind(this)}
        />
        <Step2
          currentStep={this.state.currentStep}
          stepTwo={this.state.stepTwo}
          phone={this.state.step1data[0].phone}
          codePass={this.codePass.bind(this)}
        />
        {this.nextButton}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  proceed: state.calculator.proceed,
  code: state.calculator.code,
});
const mapDispatchToProps = (dispatch) => ({
  addContactDetails: (payload) => dispatch(addContactDetails(payload)),
  requestCode: (payload) => dispatch(requestCode(payload)),
  sendCode: (payload) => dispatch(sendCode(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SpecializedMaterialsForm));
