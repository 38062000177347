import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Utilities } from "../../utilities";
import { NavLink } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getReferences, startLoading } from "../../actions";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import './styles.scss';
import { translate } from "../../translations/localization";

export default function ReferencesDesktopNew() {
  const dispatch = useDispatch();

  const references = useSelector((state) => state.references.references);
  const hasNextPage = useSelector((state) => state.references.hasNextPage);
  const nextPage = useSelector((state) => state.references.nextPage);
  const [imageLocation, setImageLocation] = useState("");

  const imageChange = (currentImg) => {
    setImageLocation(currentImg);
  };

  const delayedScrolling = _.debounce(() => {
    if (hasNextPage) {
      loadPageOnScroll();
    }
  }, 300);

  const loadPageOnScroll = () => {
    let payload = { page: nextPage };
    dispatch(startLoading());
    dispatch(getReferences(payload));
  };

  useEffect(() => {
    dispatch(getReferences());
  }, []);

  useEffect(() => {
    if (references.length) {
      setImageLocation(references[0].coverPhoto)
    }
  }, [references]);

  return (
    <div className="references page-bg">
      <div className="container">
        <div className={`content references-list ${!references?.length ? 'center' : ''}`}>
          {references?.length ?
          <div className="tabs-content">
            <div className="row">
              <div className="col col-reference-img">
                <div className="reference-img">
                  <SwitchTransition mode={"in-out"}>
                    <CSSTransition
                      classNames="fade"
                      timeout={200}
                      key={imageLocation || ""}
                    >
                      <img src={imageLocation} alt="location" />
                    </CSSTransition>
                  </SwitchTransition>
                </div>
              </div>
              <div className="col col-references-list">
                <div className="scroll">
                  <PerfectScrollbar onYReachEnd={delayedScrolling}>
                    <div className="references-items">
                      {references?.map((ref, index) => {
                        return (
                          <div
                            key={ref?._id}
                            className="reference-item"
                            id={ref?._id}
                            onMouseOver={() => imageChange(ref?.coverPhoto)}
                          >
                            <NavLink
                              to={`/reference/${ref?._id}`}
                              style={{ textDecoration: "none" }}
                            >
                              <div className="row">
                                <div className="col col-reference-name">
                                  <span className="col-name">
                                    {Utilities.translateLanguage(ref?.title)}
                                  </span>
                                  <span className="col-info">
                                    {Utilities.translateLanguage(ref?.project)}
                                  </span>
                                </div>
                                <div className="col col-arrow">
                                  <span className="icon-arrow-right"></span>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        );
                      })}
                    </div> 
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div> :
          <p className="empty-state-text">{translate("referencesEmptyState")}</p>}
        </div>
      </div>
    </div>
  );
}
