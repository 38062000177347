import React from "react";
import { Footer } from "../../components";
import "./mstyles.scss";
import { translate } from "../../translations/localization";
import { history } from "../../config/stores";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleReference } from "../../actions";
import { Utilities } from "../../utilities";

export default function ReferenceMobileNew() {
  const { id } = useParams();
  const dispatch = useDispatch();
  let zIndex;

  const reference = useSelector((state) => state.references.singleReference);

  const [isImageOpen,] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  if (isImageOpen) {
    zIndex = "100";
  } else {
    zIndex = "10";
  }

  useEffect(() => {
    dispatch(getSingleReference({ _id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (reference) {
      setSelectedImage(reference?.coverPhoto);
    }
  }, [reference]);

  return (
    <div
      className="reference-m page-bg"
      style={{
        "z-index": zIndex,
        backgroundImage: "url(" + selectedImage + ")",
      }}
    >
      <div className="container-m">
        <div className="top-wrapper" onClick={() => history.goBack()}>
          <img src={require("../../assets/icons/arrow-back-white.svg")} alt="go back" />
          <h1>{Utilities.translateLanguage(reference?.title)}</h1>
        </div>
        <div className="content-m">
          <div className="tab-wrapper border">
            <div className="tab-name">{translate("project")}</div>
            <div className="tab-name subtitle">
              {Utilities.translateLanguage(reference?.project)}
            </div>
          </div>

          <div className="tab-wrapper border">
            <div className="tab-name">{translate("goal")}</div>
            <div className="tab-name subtitle">
              {Utilities.translateLanguage(reference?.objective)}
            </div>
          </div>

          <div className="tab-wrapper">
            <div className="tab-name">{translate("headerProducts")}</div>
            <div className="tab-name subtitle full-width">
              {reference?.products?.map((product) => {
                return (
                  <div
                    className="product-mobile-wrapper"
                    key={`reference-product-mobile-${product?._id}`}
                    onClick={() => window.open(`/product/${product?._id}`)}
                  >
                    <div className="product">
                      {Utilities.translateLanguage(product?.name)}
                    </div>
                    <img src={require("../../assets/icons/arrow-thic.svg")} alt="read more" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="images-wrapper">
            {reference?.photos?.map((photo) => (
              <img src={photo} className="image" alt="reference image" />
            ))}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
