import React from "react";
import "./styles.scss";
import { translate } from "../../translations/localization";
import { useState } from "react";
import { history } from "../../config/stores";

export default function SensorsObjects() {
  const [query, setQuery] = useState("");
//   const [ objectsData, setObjectsData ] = [ 
//     { title: 'Пробива', text: 'бул. Акад. Иван Евстатиев Гешов №2Д, гр. София'},
//     { title: 'Резбарска', text: 'ул.Резбарска 12В, Подуене, гр. София' },
//     { title: 'Рожен', text: 'бул. Рожен 22Е, Военна Рампа, гр. София' },
//     { title: 'Младост 1', text: 'бул. Цариградско Шосе 113,  гр. София' },
//     { title: 'Резбарска', text: 'ул.Резбарска 12В, Подуене, гр. София' },
//     { title: 'Рожен', text: 'бул. Рожен 22Е, Военна Рампа, гр. София' },
//   ]

  const objectsData = [ 
    { title: 'Пробива', text: 'бул. Акад. Иван Евстатиев Гешов №2Д, гр. София бул. Акад. Иван Евстатиев Гешов №2Д, гр. София'},
    { title: 'Резбарска', text: 'ул.Резбарска 12В, Подуене, гр. София' },
    { title: 'Рожен', text: 'бул. Рожен 22Е, Военна Рампа, гр. София' },
    { title: 'Младост 1', text: 'бул. Цариградско Шосе 113,  гр. София' },
    { title: 'Резбарска', text: 'ул.Резбарска 12В, Подуене, гр. София' },
    { title: 'Рожен', text: 'бул. Рожен 22Е, Военна Рампа, гр. София' },
  ]

  return (
    <div className="sensors-wrapper objects">
      <div className="container">
        <div className="inner-container">
          <div className="top-wrapper">
            <h1>{translate("sensorsObjectsTitle")}</h1>

            <div className="search-wrapper">
              <input
                className="search-input"
                type="text"
                // value=''
                placeholder={translate("searchByNamePlaceholder")}
              />

              <div className="search-icon-wrapper">
                <div
                  className="search-icon"
                  onClick={() => {
                    setQuery("");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="scroll-wrapper">
            {objectsData?.map((el, index) => {
                return (
                  <div className='card-wrapper' key={`${el?.title}-${index}`} onClick={() => history.push(`/sensors-object`)}>
                    <img className='card-image' src={require('../../assets/images/sensor-object-example-photo.jpg')} alt="sensor photo" />
                    <div className='card-text-wrapper'>
                      <h2>{el?.title}</h2>
                      <p className='card-content-wrapper'>{el?.text}</p>
                    </div>
                  </div>
                )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
