import React from "react";
import { NavLink } from "react-router-dom";
import { Utilities } from "../../utilities";
import { getReferences } from "../../actions";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./mstyles.scss";

export default function ReferencesMobileNew() {
  const dispatch = useDispatch();

  const references = useSelector((state) => state.references.references);

  useEffect(() => {
    dispatch(getReferences());
  }, [dispatch]);

  return (
    <div className="references-m page-bg">
      <div className="container-m">
        <div className="content-m">
          {references?.map((infrastructure, index) => {
            return (
              <div className="reference-item" id={index} key={`ref-item-mobile-${index}`}>
                <NavLink to={"/reference/" + infrastructure?._id}>
                  <span className="reference-img">
                    <img
                      src={infrastructure?.coverPhoto}
                      alt={infrastructure?.title?.bg || "infrastructure"}
                    />
                  </span>
                  <span className="reference-info">
                    <span className="reference-name">
                      {Utilities.translateLanguage(infrastructure?.title)}
                      <br />
                    </span>
                    <span className="icon-arrow-right"></span>
                  </span>
                  <span className="reference-text">
                    {Utilities.translateLanguage(infrastructure?.project)}
                  </span>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
