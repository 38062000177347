import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "../../translations/localization";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

class Step1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameError: "",
      name: "",
      company: "",
      email: "",
      phone: "",
      allFormControls: [],
      data: [{ name: "", company: "", email: "", phone: "" }],
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    this.setState(
      { [e.target.name]: e.target.value },
      () => (this.state.data[0].name = this.state.name)
    );
    this.setState(
      { [e.target.company]: e.target.value },
      () => (this.state.data[0].company = this.state.company)
    );
    this.setState(
      { [e.target.email]: e.target.value },
      () => (this.state.data[0].email = this.state.email)
    );
    this.setState(
      { [e.target.phone]: e.target.value },
      () => (this.state.data[0].phone = this.state.phone)
    );
    this.props.dataPass(this.state.data);
  };

  render() {
    if (this.props.currentStep !== 1) {
      return null;
    }

    return (
      <div className="content-step step-1">
        <div className="scroll">
          <PerfectScrollbar>
            <h4>{`${translate("loginStep")} 1 / 2`}</h4>
            <h2>{translate("loginContactData")}</h2>
            <div className="form-row">
              <label className="label">{translate("loginFullName")}</label>
              <div className="form-control">
                <input
                  value={this.state.name}
                  onChange={this.onChange}
                  type="text"
                  name="name"
                  id="name"
                  className="input"
                  required
                  autoFocus
                />
                {/* onChange={this.handleChange} */}
              </div>
              <div style={{ fontSize: 12, color: "red" }}>
                {this.state.nameError}
              </div>
            </div>
            <div className="form-row">
              <label className="label">{translate("loginCompany")}</label>
              <div className="form-control">
                <input
                  type="text"
                  name="company"
                  id="company"
                  value={this.state.company}
                  onChange={this.onChange}
                  className="input"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <label className="label">{translate("loginWorkEmail")}</label>
              <div className="form-control">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  className="input"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <label className="label">
                {translate("loginContactPhone")}
                <small>{translate("loginContactPhoneNote")}</small>
              </label>
              <div className="form-control">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  value={this.state.phone}
                  onChange={this.onChange}
                  className="input"
                  placeholder="+359xxxxxxxxx"
                  pattern="[+359]{4}[0-9]{9}"
                  required
                />
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

export default connect()(Step1);
