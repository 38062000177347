import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "../../translations/localization";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Base64 } from "js-base64";
import { history } from "../../config/stores";
import Cookies from "js-cookie";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./calculator.scss";

class Calculator extends Component {
  constructor() {
    super();
    this.state = {
      today: new Date(),
      to: "hydroprobg1@gmail.com, team@fidweb.net",
      // to: 'victor@thesocourt.com',
      subject: "HydroPro - Калкулатор хидравлично оразмеряване",
      contactDetails: { name: null, email: null, phone: null, company: null },
      pipepragma: "",
      // emailfrom: 'tsenov9@gmail.com',
      diameter: 0,
      volume: 0,
      height: 0,
      unknown: "",
      SN: "",
      lid: "",
      entrance: "",
      exit: "",
      revision_n: 0,
      revision_dn: 0,
      revision_h: 0,
      namefrom: "idk",
    };
  }
  componentDidMount() {
    if (this.state.contactDetails.name === null) {
      this.setState((prevState) => ({
        contactDetails: {
          ...prevState.contactDetails,
          name: Cookies.get("name"),
          email: Cookies.get("email"),
          phone: Cookies.get("phone"),
          company: Cookies.get("company"),
        },
      }));
    }
    this.setState({
      name: this.state.contactDetails.name,
      emailfrom: this.state.contactDetails.emailfrom,
      phone: this.state.contactDetails.phone,
      company: this.state.contactDetails.company,
    });
  }

  onChangeEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit(e) {
    this.setState({ loading: true });

    e.preventDefault();
    window
      .fetch("https://mailer.fidweb.net/mail/send/hydropro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic " +
            Base64.encode(
              "hydropro:P0div3h!!!ot!!T4z!!K4rantinaBEEEEE"
            ),
        },
        body: JSON.stringify({
          from: this.state.contactDetails.email,
          to: this.state.to,
          subject: this.state.subject,
          body: ` Изпратено от: ${this.state.namefrom} \n Email: ${this.state.contactDetails.email} \n Обем: ${this.state.volume} \n Дължина: ${this.state.height} \n Диаметър: ${this.state.diameter} \n SN: ${this.state.sn} \n Ревизионен отвор: ${this.state.revision_dn}, ${this.state.revision_h}, ${this.state.revision_n}, Капак ${this.state.lid} \n`,

          notifyBody: ` 
            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml">
            <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <title>HydroPro</title>
              <style type="text/css">
                * {font-family: Georgia,Times,Times New Roman,serif;}
                #outlook a {padding: 0;}
                body {width: 100% !important; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; margin: 0; padding: 0; }
                img {outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;}
                a img {border: none; }
                h1, h2, h3, h4, h5, h6,
                h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
                h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
                h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {font-family: Georgia,Times,Times New Roman,serif; color: #201E18;}
                a {outline: none; text-decoration: none; color: #DFC475; }
                @media only screen and (max-width:600px) {
                  *[class].full {width: 100% !important; overflow: hidden !important;}
                  *[class].cen {margin: 0 auto !important; text-align: center !important;}
                  *[class].img-full {display: block !important; width: 100% !important; height: auto !important;}
                }
              </style>
            </head>
            <body style="margin:0;padding:0;background-color:#ffffff;font-family:Georgia,Times,Times New Roman,serif;font-size:16px;">
              <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
                <tr>
                  <td valign="top" bgcolor="#ffffff" align="center" width="100%">
                    <table cellpadding="0" cellspacing="0" border="0" align="center" valign="top" width="600" class="full">
                      <tr>
                        <td valign="top">
    
                            <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="20" width="1" border="0" style="display:block;" /></td></tr></table>
            
                            <table cellpadding="0" cellspacing="0" border="0" width="100%">
                              <tr>
                                <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                                <td style="text-align:left;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">Контакти</td>
                                <td style="text-align:right;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">дата ${this.state.today}г.</td>
                                <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                              </tr>
                            </table>
            
                            <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
            
                            <table cellpadding="0" cellspacing="0" border="0" width="100%">
                              <tr><td align="center"><a href="/" target="_blank" style="text-decoration:none;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-header.jpg" alt="HydroPro" border="0" class="img-full" /></a></td></tr>
                            </table>
            
                            <table cellpadding="0" cellspacing="0" border="0" width="100%">
                              <tr>
                                <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                                <td style="text-align: left">
            
                                <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="45" width="1" border="0" style="display:block;" /></td></tr></table>
    
                                  <h1 style="margin:0;padding:0;text-align:center;font-size:34px;line-height:24px;color:#201E18;font-weight:normal;font-family:Georgia,Times,Times New Roman,serif">Свързахте се с HydroPro</h1>
    
                                  <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="25" width="1" border="0" style="display:block;" /></td></tr></table>
    
                                  <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif">Вие изпратихте запитване към HydroPro:</p>
            
                                  <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                    <tr>
                                        <td><img src="https://hydropro.bg/email/pix.gif" width="20" height="1" /></td>
                                        <td align="left">
                                            <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                              Изпратено от: ${this.state.contactDetails.name}<br/>
                                              Мейл: ${this.state.contactDetails.email}<br/>
                                              Телефон: ${this.state.contactDetails.phone}<br/>
                                              Фирма: ${this.state.contactDetails.company}<br/>
                                              Обем: ${this.state.volume}<br/>
                                              Дължина: ${this.state.height}<br/>
                                              Диаметър: ${this.state.diameter}<br/>
                                              SN: ${this.state.sn}<br/>
                                              Ревизионен отвор: ${this.state.revision_dn}, ${this.state.revision_h}, ${this.state.revision_n}<br/>
                                              Капак ${this.state.lid}<br/>
                                            </p>
                                        </td>
                                      </tr>
                                  </table>
            
                                  <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                    Съвсем скоро ще отговорим на вашето запитване!<br/>
                                    - HydroPro
                                  </p>
    
                                </td>
                                <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                              </tr>
                            </table>
    
                            <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                <tr>
                                  <td style="text-align:center;">
    
                                    <p style="text-align:center;font-size:12px;color:#DFC475;font-family:Georgia,Times,Times New Roman,serif;"><a href="/" target="_blank" style="text-decoration:none;font-size:12px;color:#DFC475;font-weight:bold;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-footer.png" alt="HydroPro" border="0" /></a></p>
            
                                    <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
                                    
                                    <p style="text-align:center;font-size:12px;line-height:21px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                        <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Телефон</span>: +359 882 666 777<br/>
                                        <!--span style="text-transform:lowercase">Мейл:</span> скоро<br/ -->
                                        <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Адрес:</span> бул. „Цариградско шосе“ 319, 1582 ж.к. Дружба 2, София, България
                                    </p>
            
                                    <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                        <a href="https://www.facebook.com/HydroPro-BG-108106688391858/?ref=pages_you_manage" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/facebook.jpg" alt="Facebook" /></a>
                                        &nbsp;&nbsp;
                                        <a href="https://www.instagram.com/" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/instagram.jpg" alt="Instagram" /></span></a>
                                    </p>
    
                                    <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">2020 © HYDROPRO. Всички права запазени.</p>
            
                                  </td>
                                </tr>
                              </table>
            
                              <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="40" width="1" border="0" style="display:block;" /></td></tr></table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </body>
            </html>
    
            `,
          notifySubject: "HydroPro - Получено запитване",
        }),
      })
      .then((result) => {
        alert(translate("formSuccessMessage"));
        //console.log("RESULTS:", result)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  }

  onChange(e) {
    // let value = e.target.value
    this.setState(
      { [e.target.name]: e.target.value.replace(/,/g, ".") },
      () => {
        if (this.state.unknown === "volume") {
          this.setState({
            volume:
              Math.pow(this.state.diameter / 2, 2) *
              Math.PI *
              this.state.height,
          });
        } else if (this.state.unknown === "height") {
          this.setState({
            height:
              this.state.volume /
              (Math.pow(this.state.diameter / 2, 2) * Math.PI),
          });
        } else {
          this.setState({
            diameter:
              2 * Math.sqrt(this.state.volume / (this.state.height * Math.PI)),
          });
        }
      }
    );
  }
  render() {
    // console.log('details', this.state.contactDetails.name, this.state.contactDetails.email, this.state.contactDetails.phone, this.state.contactDetails.company)
    const pragma = [
      { value: 200, label: "200" },
      { value: 300, label: "300" },
      { value: 315, label: "315" },
      { value: 360, label: "360" },
      { value: 400, label: "400" },
      { value: 500, label: "500" },
      { value: 600, label: "600" },
      { value: 700, label: "700" },
      { value: 800, label: "800" },
      { value: 900, label: "900" },
      { value: 1000, label: "1000" },
      { value: 1100, label: "1100" },
      { value: 1200, label: "1200" },
      { value: 1300, label: "1300" },
      { value: 1400, label: "1400" },
      { value: 1500, label: "1500" },
      { value: 1600, label: "1600" },
      { value: 1700, label: "1700" },
      { value: 1800, label: "1800" },
      { value: 1900, label: "1900" },
      { value: 2000, label: "2000" },
      { value: 2100, label: "2100" },
      { value: 2200, label: "2200" },
      { value: 2300, label: "2300" },
      { value: 2400, label: "2400" },
    ];
    // const slope = [
    //   { value: "1", label: "m/m" },
    //   // { value: '2', label: 'option2' },
    //   // { value: '3', label: 'option3' },
    // ];
    // const waterquantity = [
    //   { value: "1", label: "l/s" },
    //   // { value: '2', label: 'option2' },
    //   // { value: '3', label: 'option3' },
    // ];
    const customSelectStyles = {
      option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "#434141" : "transparent",
        color: state.isSelected ? "#ffffff" : "#ffffff",
        // padding: '5px 10px',
      }),
    };
    const unknown = [
      { value: "diameter", label: translate("hydroproTankCalculatorDiameter") },
      { value: "volume", label: translate("hydroproTankCalculatorVolume") },
      { value: "height", label: translate("hydroproTankCalculatorLength") },
    ];
    const SN = [
      { value: "2", label: "2" },
      { value: "4", label: "4" },
      { value: "6", label: "6" },
      { value: "8", label: "8" },
      { value: "10", label: "10" },
      { value: "12", label: "12" },
      { value: "14", label: "14" },
      { value: "16", label: "16" },
    ];
    const lid = [
      { label: `1. ${translate("hydroproTankCalculatorPolyethyleneCovers")}`, value: "Полиетиленови капаци" },
      { label: `2. ${translate("hydroproTankCalculatorCastIronCovers")}`, options: [{ value: "", label: "" }] },
      {
        label: `2.1 ${translate("productsHYDROTECFlangeFrames")}`,
        options: [
          {
            value: "Ricon 600 – с вентилационни отвори",
            label: translate("hydroproTankCalculatorRicon600VentilationOpenings"),
          },
          { value: "Ricon 600 - плътни", label: translate("hydroproTankCalculatorRicon600Dense") },
          {
            value: "Econ 600 - с вентилационни отвори",
            label: translate("hydroproTankCalculatorecon600VentilationOpenings"),
          },
          { value: "Econ 600 - плътни", label: translate("hydroproTankCalculatorecon600Dense") },
          {
            value: "Econ 800 - с вентилационни отвори",
            label: translate("hydroproTankCalculatorecon800VentilationOpenings"),
          },
          { value: "Econ 800- плътни ", label: translate("hydroproTankCalculatorecon800Dense") },
          { value: "Euro 600", label: "Euro 600" },
        ],
      },
      {
        label: `2.2 ${translate("productsHYDROTECFSelfleveling")}`,
        options: [
          {
            value: "Euro SN 600 – с вентилационни отвори",
            label: `Euro SN 600 - ${translate("hydroproVentilationOpenings")}`,
          },
          { value: "Euro SN 600 – плътни", label: `Euro SN 600 - ${translate("hydroproDense")}` },
          {
            value: "Econ SN 600/190 – с вентилационни отвори",
            label: `Econ SN 600/190 – ${translate("hydroproVentilationOpenings")}`,
          },
          {
            value: "Econ SN 600/190 – плътни",
            label: `Econ SN 600/190 – ${translate("hydroproDense")}`,
          },
          {
            value: "Econ 600 - с вентилационни отвори",
            label: `Econ 600 - ${translate("hydroproVentilationOpenings")}`,
          },
          { value: "Econ 600 - плътни ", label: `Econ 600 - ${translate("hydroproDense")}` },
          {
            value: "Scandic SN 800 - плътни",
            label: `Scandic SN 800 - ${translate("hydroproDense")}`,
          },
        ],
      },
      {
        label: "2.3 Ревизионни капаци/водоплътни",
        options: [
          { value: "HYDROtight 600", label: "HYDROtight 600" },
          { value: "HYDROproof 600", label: "HYDROproof 600" },
          { value: "Econ tight 800", label: "Econ tight 800" },
          {
            value: "HYDROtight 1000/600 – кръгла рамка",
            label: `HYDROtight 1000/600 – ${translate("hydroproRoundFrame")}`,
          },
          {
            value: "HYDROtight 1000/600 – квадратна рамка",
            label: `HYDROtight 1000/600 – ${translate("hydroproSquareFrame")}`,
          },
        ],
      },
      {
        label: `2.4 ${translate("productsHYDROTECStreetDrainGrille")}`,
        options: [
          { value: "Econ 300x500", label: "Econ 300x500" },
          { value: "Econ 500x500", label: "Econ 500x500" },
          {
            value: "Решетка за уличен отток 300х300",
            label: `${translate("hydroproStreetDrainGrille")} 300х300`,
          },
          {
            value: "Решетка за уличен отток 400х400",
            label: `${translate("hydroproStreetDrainGrille")} 400х400`,
          },
          {
            value: "Ревизии за водосточни тръби",
            label: translate("hydroproDrainPipeRevisions"),
          },
        ],
      },
      {
        label: `2.5 ${translate("productsHYDROTECBuiltin")}`,
        options: [
          { value: "Slaney 600x600", label: "Slaney 600x600" },
          { value: "Douglas 600x600", label: "Douglas 600x600" },
        ],
      },
    ];

    return (
      <div className="calculator-hydraulic-sizing">
        <form
          id="form-contact-us"
          className="form-contact-us"
          onSubmit={this.onSubmit.bind(this)}
        >
          <div id="s1" className="section">
            <h4>{translate("hydroproLineCalculatorHydraulicSizingInputParameters")}</h4>
            <div className="row">
              <div className="col col-pipe-data">
                <h4>{translate("hydroproTankCalculatorTankData")}</h4>
                <div className="radios-group">
                  {this.state.unknown !== "diameter" ? (
                    <div>
                      <div className="row row-cols-480px">
                        <div className="col col-30">
                          <span className="box">{translate("hydroproTankCalculatorDiameters")}</span>
                        </div>
                        <div className="col">
                          <div className="select-box">
                            <CreatableSelect
                              allowCreate={true}
                              multi={true}
                              options={pragma}
                              className="react-select"
                              styles={customSelectStyles}
                              onChange={(selecedOption) =>
                                this.setState(
                                  { diameter: selecedOption.value },
                                  () => {
                                    if (this.state.unknown === "volume") {
                                      this.setState({
                                        volume:
                                          Math.pow(this.state.diameter / 2, 2) *
                                          Math.PI *
                                          this.state.height,
                                      });
                                    } else if (this.state.unknown === "height") {
                                      this.setState({
                                        height:
                                          this.state.volume /
                                          (Math.pow(
                                            this.state.diameter / 2,
                                            2
                                          ) *
                                            Math.PI),
                                      });
                                    } else {
                                      this.setState({
                                        diameter:
                                          2 *
                                          Math.sqrt(
                                            this.state.volume /
                                              (this.state.height * Math.PI)
                                          ),
                                      });
                                    }
                                  }
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col col-3 opacity">[mm]</div>
                      </div>
                    </div>
                  ) : (
                    <div className="radios-group">
                      <div className="radios-group">
                        <div>
                          <div className="row  row-cols-480px">
                            <div className="col col-30 sub-item">
                              <label
                                className="radio"
                                form="smooth-walled-tube-2"
                              >
                                <input
                                  type="radio"
                                  name="smooth-walled-tube"
                                  id="smooth-walled-tube-2"
                                  value="Външен диаметър"
                                  checked
                                />
                                <span className="box">{translate("hydroproTankCalculatorDiameter")}</span>
                              </label>
                            </div>
                            <div className="col">
                              {/* <label className='label'>Dn [mm]</label> */}
                              <div className="row">
                                <div className="col">
                                  <input
                                    type="text"
                                    className="input"
                                    placeholder="0"
                                    onChange={this.onChange.bind(this)}
                                    name="diameter"
                                    value={this.state.diameter}
                                  />
                                </div>
                                <div className="col col-3 opacity">[mm]</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}{" "}
                </div>

                <div className="row row-top-space row-cols-480px">
                  <div className="col col-50">
                    <label className="label">{translate("hydroproTankCalculatorVolume")}</label>
                    <div className="row">
                      <div className="col">
                        <input
                          type="text"
                          className="input"
                          name="volume"
                          onInput={this.onChange.bind(this)}
                          value={this.state.volume}
                          id="volume"
                        />
                      </div>
                      <div className="col col-3 opacity">[m3]</div>
                    </div>
                  </div>
                  <div className="col col-50">
                    <label className="label">{translate("hydroproTankCalculatorLength")}</label>
                    <div className="row">
                      <div className="col">
                        <input
                          type="text"
                          className="input"
                          placeholder="0.0%"
                          name="height"
                          onChange={this.onChange.bind(this)}
                          value={this.state.height}
                          id=""
                        />
                      </div>
                      <div className="col col-3 opacity">[m]</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="select-box">
                  <label className="label">{translate("hydroproTankCalculatorSearching")}</label>
                  <Select
                    options={unknown}
                    className="react-select"
                    styles={customSelectStyles}
                    onChange={(selecedOption) =>
                      this.setState({ unknown: selecedOption.value })
                    }
                  />
                </div>
                {/* <img src="imgs/hydrotank-scheme.png" alt="hydropro-scheme" /> */}
              </div>
            </div>
          </div>

          <div id="result" className="section section-result">
            <h4>
              {translate("hydroproTankCalculatorAdditional")}
              {/* <small>(Премести мишката по графиката за да избереш височина на запълване)</small> */}
            </h4>
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col">SN:</div>
                  <div className="col-50" style={{ marginLeft: "20px" }}>
                    <Select
                      options={SN}
                      className="react-select"
                      styles={customSelectStyles}
                      onChange={(selecedOption) =>
                        this.setState({ SN: selecedOption.value })
                      }
                    />
                  </div>
                  <div className="col col-3 opacity">[kN/m2]</div>
                </div>
                <div className="row">
                  <div className="col">{translate("hydroproTankCalculatorEntrance")}</div>
                  <div className="col-50">
                    <input
                      type="text"
                      className="input"
                      name="entrance"
                      onInput={this.onChange.bind(this)}
                      value={this.state.entrance}
                    />
                  </div>
                  <div className="col col-3 opacity">[mm]</div>
                </div>
                <div className="row">
                  <div className="col">{translate("hydroproTankCalculatorExit")}</div>
                  <div className="col-50">
                    <input
                      type="text"
                      className="input"
                      name="exit"
                      onInput={this.onChange.bind(this)}
                      value={this.state.exit}
                    />
                  </div>
                  <div className="col col-3 opacity">[mm]</div>
                </div>
                <div className="row row-bottom-space">
                  <div className="col">{translate("hydroproTankCalculatorRevisionOpening")}</div>
                  <div className="col-50">
                    <div className="row">
                      <label style={{ marginTop: "10px" }}>n=</label>{" "}
                      <input
                        type="text"
                        style={{ maxWidth: "25%", marginLeft: "3px" }}
                        onInput={this.onChange.bind(this)}
                        value={this.state.revision_n}
                        className="input"
                        name="revision_n"
                      />
                      <div className="col col-3 opacity">[бр.]</div>
                      <label style={{ marginTop: "10px" }}>DN=</label>
                      {this.state.revision_n === 0 ? (
                        <input
                          type="text"
                          style={{ maxWidth: "25%", marginLeft: "3px" }}
                          disabled
                          onInput={this.onChange.bind(this)}
                          value={this.state.revision_dn}
                          className="input"
                          name="revision_dn"
                        />
                      ) : (
                        <input
                          type="text"
                          style={{ maxWidth: "25%", marginLeft: "3px" }}
                          onInput={this.onChange.bind(this)}
                          value={this.state.revision_dn}
                          className="input"
                          name="revision_dn"
                        />
                      )}
                      <div className="col col-3 opacity">[mm]</div>
                      <label style={{ marginTop: "10px" }}>h=</label>
                      {this.state.revision_n === 0 ? (
                        <input
                          type="text"
                          style={{ maxWidth: "25%", marginLeft: "3px" }}
                          disabled
                          onInput={this.onChange.bind(this)}
                          value={this.state.revision_h}
                          className="input"
                          name="revision_h"
                        />
                      ) : (
                        <input
                          type="text"
                          style={{ maxWidth: "25%", marginLeft: "3px" }}
                          onInput={this.onChange.bind(this)}
                          value={this.state.revision_h}
                          className="input"
                          name="revision_h"
                        />
                      )}
                      <div className="col col-3 opacity">[mm]</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">{translate("hydroproTankCalculatorCovering")}</div>
                  <div className="col-50">
                    <Select
                      options={lid}
                      className="react-select"
                      styles={customSelectStyles}
                      onChange={(selecedOption) =>
                        this.setState({ lid: selecedOption.value })
                      }
                    />
                  </div>
                </div>
                <div className="row row-bottom-space">
                  <label
                    className="radio"
                    for="pipes-data-1"
                    style={{ marginLeft: "12px" }}
                  >
                    <input
                      type="radio"
                      checked
                      name="pipes-data"
                      id="pipes-data-1"
                      value="Тръба PRAGMA"
                    />
                    <span className="box">{translate("hydroproTankCalculatorLadder")}</span>
                  </label>
                  <div className="col"></div>
                </div>
              </div>
              {/* <div className='col col-chart'>
                                <Chart
                                    fillHeight={this.state.height + '%'}
                                    chartHeight={this.chartHeight.bind(this)}
                                />
                            </div> */}
            </div>
            <button
              className="bttn"
              type="button"
              onClick={this.onChange.bind(this)}
            >
              {translate("hydroproTankCalculatorGeneratePDF")}
            </button>
          </div>
          {/* <div className='col'>
                        <h4>Последно съобщение</h4>
                        <div className='row'>
                            <div className='col'>

                                <div>
                                    <textarea type='text' name="lastmessage" id="lastmessage" className='input' placeholder='Последно съобщение' value={this.state.lastmessage} onChange={this.onChange} />
                                </div>
                            </div>


                        </div>
                    </div> */}
          <div className="row-bttn">
            <button className="bttn bttn-ask" onClick={() => history.push('/contact/form')}>{translate("formButton")}</button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  contactDetails: state.user.contactDetails,
});
export default connect(mapStateToProps)(Calculator);
