import React from "react";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSingleArticle } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { history } from "../../config/stores";
import Slider from "react-slick";
import moment from "moment";
import { Utilities } from "../../utilities";
import Lottie from "react-lottie";
import animation from "../../LottieAnimations/HydroPro-loading-inbutton.json";

export default function OpenedNews() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const articleData = useSelector((state) => state.articles.singleArticle);
  const language = localStorage.getItem('code') ?? 'bg'

  const [toggler, setToggler] = useState(false);
  const [, setImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const [loaded, setLoaded] = useState(false)

  const settings = {
    dots: false,
    speed: 800,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleImageLoad = () => {
    setLoaded(true)
  };

  const handleGalleryOpen = (index) => {
    setToggler(!toggler);
    setImageIndex(index + 1);
  };

  const handleImageOpen = (index) => {
    setImageIndex(index);
    setSelectedImage(articleData?.photos[index])
    setSelectedThumbnail(index)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSingleArticle({ _id: id }))
  }, [dispatch, id]);

  return (
    <div className="opened-news">
      <div className="container">
        <div
          className="top-button-wrapper"
          onClick={() => history.push("/about-us")}
        >
          <img alt='< go back'
            src={require("../../assets/icons/arrow-back-white.svg")}
            className="arrow-icon"
          />
          {language === 'bg' ? 
          <div className="text">{moment(articleData?.createdAt).locale('bg').format('DD MMMM YYYY')}</div> :
          <div className="text">{moment(articleData?.createdAt).locale('en').format('DD MMMM YYYY')}</div>}
        </div>

        <div className="news-columns-wrapper">
          <div className="column text">
            <h3>{Utilities.translateLanguage(articleData?.title)}</h3>
            <div className="text-conteiner"  dangerouslySetInnerHTML={{ __html: Utilities.translateLanguage((articleData?.text)) }} />
          </div>
          <div className="column images desktop">
            <div className={`images-wrapper ${articleData?.photos?.length === 3 ? "unset" : ""} ${!loaded ? "initial-width" : ""}`}>
              {!loaded && (<Lottie options={defaultOptions} height={50} width={50} />)}
              {articleData?.photos?.length <= 2 &&
                articleData?.photos?.map((el, index) => {
                  return (
                    <img
                      alt='news image'
                      src={el}
                      onLoad={handleImageLoad}
                      className="news-image full-width"
                      onClick={() => handleGalleryOpen(index)}
                      key={`${el}-${index}-`}
                    />
                  );
                })}

              {articleData?.photos?.length === 0 &&
                  <img alt='news main image'
                  src={articleData?.coverPhoto}
                  onLoad={handleImageLoad}
                  className="news-image full-width"
                  onClick={() => handleGalleryOpen(0)}
                />}

              {articleData?.photos?.length > 2 &&
                articleData?.photos?.slice(0, 2)?.map((el, index) => {
                  return (
                    <img alt='news image'
                      src={el}
                      className="news-image full-width"
                      onLoad={handleImageLoad}
                      onClick={() => handleGalleryOpen(index)}
                      key={`${el}-${index}-`}
                    />
                  );
                })}

              <img alt='open full screen icon'
                src={require("../../assets/icons/full-screen-icon.svg")}
                onClick={() => handleGalleryOpen(0)}
                className="fill-screen-icon"
              />
            </div>
          </div>

          <div className="column images mobile">
            {articleData?.photos?.map((el, index) => {
              return (
                <img alt='news image'
                  src={el}
                  className="news-image"
                  // onClick={() => handleGalleryOpen(index)}
                  key={`${el}-${index}-`}
                />
              );
            })}
          </div>
        </div>
      </div>

      {toggler && (
        <div className="images-gallery-wrapper">
          <img alt='close' src={require("../../assets/icons/close.svg")} className='close-icon' onClick={() => setToggler(false)} />
          <img alt='news main photo' src={selectedImage || articleData?.coverPhoto} className='main-img' />

          <div className="gallery-slider-thumbs">
            <Slider {...settings}>
              {articleData?.photos?.map((el, index) => {
                return (
                  <div className={index === selectedThumbnail ? "gallery-image-wrapper selected" : "gallery-image-wrapper"}>
                    <img alt='news image' src={el} className='single-img' onClick={() => handleImageOpen(index)} />
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
}
