import React, { Component } from "react";
import { withGetScreen } from "react-getscreen";
import SearchResultsDesktop from "./SearchResultsDesktop";
import SearchResultsMobile from "./SearchResultsMobile";
class SearchResults extends Component {
  render() {
    if (this.props.isMobile()) {
      return <SearchResultsMobile />;
    } else {
      return <SearchResultsDesktop />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true };
export default withGetScreen(SearchResults, options);
