import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../translations/localization'
import { Link } from 'react-router-dom'
import Calculator from './calculator'
import './mstyles.scss'

class CalculationTankVolumeMobile extends Component {

    render() {
        return (
            <div className='calculation-hydraulic-sizing-tool-m'>
                <div className='container-m'>
                    <div className='content-m'>
                        <h1>
                            <Link to='/specialized-materials-list' className='link-back'><span className='icon icon-arrow-left'></span>{translate("forDesignersSpecializedMaterials")}</Link>
                            <span>{translate("forDesignersHydraulicSizing")}</span>
                        </h1>
                        <Calculator />
                    </div>
                </div>
            </div>
        )
    }

}

export default connect()(CalculationTankVolumeMobile)