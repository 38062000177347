import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import "./styles.scss";
import googleMapStyles from "./GMapStyles";

export class GMap extends Component {
  render() {
    return (
      <div className="gmap-wrap">
        <Map
          google={this.props.google}
          zoom={14}
          initialCenter={{
            lat: 42.641114670537334,
            lng: 23.408411153653823,
          }}
          styles={this.props.GMapStyles}
          // fullscreenControl={false}
          streetViewControl={false}
          mapTypeControl={false}
          zoomControl={false}
        >
          <Marker
            // onClick={this.onMarkerClick}
            title={"бул. „Цариградско шосе“ 319, 1582 София"}
            name={"HYDROPRO"}
            position={{ lat: 42.641114670537334, lng: 23.408411153653823 }}
            icon={{
              url: "/icons/pin-map.svg",
              // anchor: new google.maps.Point(32,32),
              // scaledSize: new google.maps.Size(64,64)
            }}
            // position={markerCenter}
          />
        </Map>
      </div>
    );
  }
}

GMap.defaultProps = googleMapStyles;

export default GoogleApiWrapper({
  apiKey: "AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q",
})(GMap);

// export default connect()(GMap);
