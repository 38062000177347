import React, { Component } from 'react'
import { connect } from 'react-redux'
import CalculationTankVolumeDesktopNew from './CalculationTankVolumeDesktopNew'
import CalculationTankVolumeMobile from './CalculationTankVolumeMobile'
import { withGetScreen } from 'react-getscreen'

class CalculationHydraulicSizing extends Component {
  render() {
    if (this.props.isMobile()) {
      return <CalculationTankVolumeMobile />
    } else {
      return <CalculationTankVolumeDesktopNew />
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(CalculationHydraulicSizing, options))
