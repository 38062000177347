import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../translations/localization'
import { Link } from 'react-router-dom'
import { Footer } from './../../components'
import './mstyles.scss'

class SpecializedMaterialsListMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            materialsList: [
                {
                    img: '/imgs/hyropro-specialized-materials-list-3.jpg',
                    title: translate("forDesignersLinearDrainageCaps"),
                    link: '/calculation-linear-drainage'
                },
                {
                    img: '/imgs/hyropro-specialized-materials-list-1.jpg',
                    title: translate("forDesignersHydraulicSizing"),
                    link: '/calculation-hydraulic-sizing-tool'
                },
                {
                    img: '/imgs/hyropro-specialized-materials-list-2.jpg',
                    title: translate("forDesignersWallThickness"),
                    link: '/calculation-wall-thickness'
                }
            ]
        };
    }

    render() {
        const materialsItemsPhotos = this.state.materialsList.map((materialsList, i) =>
            <div>
                {i !== 2 ?
                    <div key={i} className='item'>
                        <Link to={materialsList.link}>
                            <span className='photo'>
                                <img src={materialsList.img} alt={materialsList.title} />
                            </span>
                            <span className='info'>
                                <span className='info-name'>{materialsList.title}</span>
                                <span className='icon icon-arrow-right' ></span>
                            </span>
                        </Link>
                    </div>
                    :
                    <div key={i} className='item' onClick={() => { alert('Coming soon!') }}>
                        <span className='photo'>
                            <img src={materialsList.img} alt={materialsList.title} />
                        </span>
                        <span className='info'>
                            <span className='info-name'>{materialsList.title}</span>
                            <span className='icon icon-arrow-right' ></span>
                        </span>
                    </div>
                }
            </div>
        )

        return (
            <div className='specialized-materials-list-m page-bg'>
                <div className='container-m'>
                    <div className='content-m'>
                        <div className='specialized-materials-items '>
                            {materialsItemsPhotos}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default connect()(SpecializedMaterialsListMobile)
