import React, { useState, useEffect } from "react";
import "./styles.scss";
import { translate } from "../../translations/localization";

const colorMap = {
  DN200: "#309FFA",
  DN300: "#ED47ED",
  DN400: "#09AF75",
  DN500: "#FD773B",
  DN600: "#FEBD2B",
  DN800: "#954CEA",
  DN900: "#49C5E8",
  DN1000: "#9BC14B",
  DN1100: "#227EE8",
};

const getMax = (arr) => {
  let len = arr.length;
  let max = 0;

  while (len--) {
    max = arr[len] > max ? arr[len] : max;
  }
  return max;
};

function GraphOne({ graph, dataPipes }) {
  const [graphWidthPercentile, setGraphWidthPercentile] = useState(null);
  const [graphHeightPercentile, setGraphHeightPercentile] = useState(null);
  const graphDOM = document.getElementById("graph");
  let totalDrainageArray = graph.map((el) => el.totalDrain);
  let maxTotalDrainage = getMax(totalDrainageArray);
  useEffect(() => {
    const rect = document.getElementById("graph");
    const graphWidth = rect.offsetWidth / 100;
    const graphHeight = rect.offsetHeight / 100;
    setGraphWidthPercentile(graphWidth);
    setGraphHeightPercentile(graphHeight);
  }, []);
  return (
    <div className="graph-container">
      <div className="graph-inner-container">
        <div className="top-container">
          <h1>{translate("hydroproLineCalculatorResultProductInformation")}</h1>
          <div className="top-flex-container">
            <div className="color" />
            <p>Q <span>{translate("hydroproLineCalculatorResultProductQ")}</span></p>
          </div>
        </div>
        <div className="main-container">
          <div className="y-label">{"Q[l/s]"}</div>
          <div className="graph" id="graph">
            <svg
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "0",
                bottom: "0",
              }}
            >
              {Object.keys(dataPipes).map((line) => {
                return dataPipes[line].map((el, index) => {
                  let n = dataPipes[line].length > 450 ? 15 : 1;
                  if (
                    dataPipes[line].length > 450 &&
                    index !== 0 &&
                    (index % 2 === 0 ||
                      index % 3 === 1 ||
                      index % 3 === 0 ||
                      index % 4 === 0 ||
                      index % 4 === 1 ||
                      index % 4 === 2)
                  ) {
                    return null;
                  } else {
                    return (
                      <>
                        {dataPipes[line][index + n] && (
                          <line
                            key={`graph-one-${index}`}
                            style={{
                              position: "absolute",
                            }}
                            x1={
                              ((el.step * 100) /
                                dataPipes[line][dataPipes[line].length - 1]
                                  .step) *
                              graphWidthPercentile
                            }
                            y1={
                              graphDOM &&
                              graphDOM.offsetHeight -
                                ((el.totalDrain * 100) / maxTotalDrainage) *
                                  graphHeightPercentile +
                                15
                            }
                            x2={
                              ((dataPipes[line][index + n].step * 100) /
                                dataPipes[line][dataPipes[line].length - 1]
                                  .step) *
                              graphWidthPercentile
                            }
                            y2={
                              graphDOM &&
                              graphDOM.offsetHeight -
                                ((dataPipes[line][index + n].totalDrain * 100) /
                                  maxTotalDrainage) *
                                  graphHeightPercentile +
                                15
                            }
                            stroke={colorMap[el.pipe]}
                            strokeWidth="2"
                          />
                        )}
                      </>
                    );
                  }
                });
              })}
              {graph.map((mainLine, index) => {
                let nextElement =
                  (graph[index + 1] && graph[index + 1].pipe) || mainLine.pipe;
                if (
                  mainLine.pipe === nextElement &&
                  graph.length > 500 &&
                  (index % 2 === 0 ||
                    index % 3 === 1 ||
                    index % 3 === 0 ||
                    index % 4 === 0 ||
                    index % 4 === 1 ||
                    index % 4 === 2)
                ) {
                  return null;
                } else {
                  return (
                    <>
                      {graph[index + 20] && (
                        <line
                          style={{
                            position: "absolute",
                          }}
                          x1={
                            ((mainLine.Q * 100) / graph[graph.length - 1].Q) *
                            graphWidthPercentile
                          }
                          y1={
                            graphDOM &&
                            graphDOM.offsetHeight -
                              ((mainLine.Q * 100) / maxTotalDrainage) *
                                graphHeightPercentile +
                              15
                          }
                          x2={
                            ((graph[index + 20].Q * 100) /
                              graph[graph.length - 1].Q) *
                            graphWidthPercentile
                          }
                          y2={
                            graphDOM &&
                            graphDOM.offsetHeight -
                              ((graph[index + 20].Q * 100) / maxTotalDrainage) *
                                graphHeightPercentile +
                              15
                          }
                          stroke="red"
                          stroke-width="2"
                        />
                      )}
                      {mainLine.drainage < 0 && (
                        <circle
                          r="3"
                          stroke="white"
                          stroke-width="2"
                          fill="none"
                          cy={
                            graphDOM &&
                            graphDOM.offsetHeight -
                              ((mainLine.Q * 100) / maxTotalDrainage) *
                                graphHeightPercentile +
                              15
                          }
                          cx={
                            ((mainLine.Q * 100) / graph[graph.length - 1].Q) *
                            graphWidthPercentile
                          }
                        />
                      )}
                    </>
                  );
                }
              })}
            </svg>
            <div className="graph-row first">
              <div className="graph-row-value">
                {(Math.ceil(maxTotalDrainage / 100) * 100).toFixed(0)}
              </div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">
                {(((Math.ceil(maxTotalDrainage / 100) * 100) / 5) * 4).toFixed(
                  0
                )}
              </div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">
                {(((Math.ceil(maxTotalDrainage / 100) * 100) / 5) * 3).toFixed(
                  0
                )}
              </div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">
                {(((Math.ceil(maxTotalDrainage / 100) * 100) / 5) * 2).toFixed(
                  0
                )}
              </div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">
                {((Math.ceil(maxTotalDrainage / 100) * 100) / 5).toFixed(0)}
              </div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">0</div>
            </div>
            <div className="x-axis-container">
              <div className="graph-col">
                <div className="graph-col-value">0</div>
              </div>
              <div className="graph-col">
                <div className="graph-col-value">
                  {Math.ceil(graph[Math.floor(graph.length / 5)].step / 10) *
                    10}
                </div>
              </div>
              <div className="graph-col">
                <div className="graph-col-value">
                  {Math.ceil(
                    graph[Math.floor((graph.length / 5) * 2)].step / 10
                  ) * 10}
                </div>
              </div>
              <div className="graph-col">
                <div className="graph-col-value">
                  {Math.ceil(
                    graph[Math.floor((graph.length / 5) * 3)].step / 10
                  ) * 10}
                </div>
              </div>
              <div className="graph-col">
                <div className="graph-col-value">
                  {Math.ceil(
                    graph[Math.floor((graph.length / 5) * 4)].step / 10
                  ) * 10}
                </div>
              </div>
              <div className="graph-col last">
                <div className="graph-col-value">
                  {Math.ceil(graph[graph.length - 1].step / 10) * 10}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h3>{translate("hydroproLineCalculatorResultProductLength")}</h3> */}
        <h3>L [m]</h3>
      </div>
      <div className="graph-info-container">
        {Object.keys(colorMap).map((pipe) => {
          return (
            <div className="pipe-element">
              <div
                className="pipe-color"
                style={{ backgroundColor: colorMap[pipe] }}
              />
              <p>{pipe}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GraphOne;
