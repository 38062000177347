import React from "react";
import { NavMainDesktopNew } from "../NavMain";
import { history } from "../../config/stores";
import { HeaderRightSection, SearchBar } from "../../components";
import "./styles.scss";

export default function HeaderDesktopNew() {
  return (
    <header className="header" id="header">
      <img src={require("../../assets/icons/white-hydropro.svg")} className='header-logo-new' onClick={() => history.push("/")} alt="logo" />
      <NavMainDesktopNew />
      <div className="header-right-section-wrapper">
        <SearchBar />
        <HeaderRightSection />
      </div>
      {/* <SpecializedMaterialsBttn /> */}
    </header>
  );
}
