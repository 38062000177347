import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { translate } from "../../translations/localization";
import moment from "moment";
import "./styles.scss";

const colorMap = {
  DN200: "#309FFA",
  DN300: "#ED47ED",
  DN400: "#09AF75",
  DN500: "#FD773B",
  DN600: "#FEBD2B",
  DN800: "#954CEA",
  DN900: "#49C5E8",
  DN1000: "#9BC14B",
  DN1100: "#227EE8",
};

const colorMapRectangle = [
  "#309FFA",
  "#ED47ED",
  "#09AF75",
  "#FD773B",
  "#FEBD2B",
  "#954CEA",
  "#49C5E8",
  "#9BC14B",
  "#227EE8",
];

function getMax(arr) {
  let len = arr.length;
  let max = 0;

  while (len--) {
    max = arr[len] > max ? arr[len] : max;
  }
  return max;
}

function CalculationLinearDrainagePDF() {
  const [graphWidthPercentile, setGraphWidthPercentile] = useState(null);
  const [graphHeightPercentile, setGraphHeightPercentile] = useState(null);
  const [rectGraphWidthPercentile, setRectGraphWidthPercentile] = useState(
    null
  );
  const [rectGraphHeightPercentile, setRectGraphHeightPercentile] = useState(
    null
  );
  const [areaGraphWidthPercentile, setAreaGraphWidthPercentile] = useState(
    null
  );
  const [areaGraphHeightPercentile, setAreaGraphHeightPercentile] = useState(
    null
  );
  const data = JSON.parse(window.sessionStorage.getItem("calculatorGraphData"));

  useEffect(() => {
    const graph = document.getElementById("graph");
    const graphWidthPercentile = graph.offsetWidth / 100;
    const graphHeightPercentile = graph.offsetHeight / 100;
    const rectGraph = document.getElementById("rectangle-graph");
    const rectangleGraphWidthPercentile = rectGraph.offsetWidth / 100;
    const rectangleGraphHeightPercentile = rectGraph.offsetHeight / 100;
    const areaGraph = document.getElementById("area-graph");
    const areaGraphWidthPercentile = areaGraph.offsetWidth / 100;
    const areaGraphHeightPercentile = areaGraph.offsetHeight / 100;
    setAreaGraphWidthPercentile(areaGraphWidthPercentile);
    setAreaGraphHeightPercentile(areaGraphHeightPercentile);
    setRectGraphHeightPercentile(rectangleGraphHeightPercentile);
    setRectGraphWidthPercentile(rectangleGraphWidthPercentile);
    setGraphWidthPercentile(graphWidthPercentile);
    setGraphHeightPercentile(graphHeightPercentile);
    return () => {
      sessionStorage.removeItem("calculatorGraphData");
    };
  }, []);

  const graph = document.getElementById("graph");
  const rectangleGraph = document.getElementById("rectangle-graph");
  const areaGraph = document.getElementById("area-graph");
  let totalDrainageArray = data.graphData.map((el) => el.totalDrain);
  let totalLengthsArray = data.areas.map((area) => parseInt(area.L));
  let maxWidthArray = data.areas.map((area) =>
    parseInt(area.B1) > parseInt(area.B2)
      ? parseInt(area.B1)
      : parseInt(area.B2)
  );
  let totalAreaArray = data.areas.map(
    (area) => ((parseInt(area.B1) + parseInt(area.B2)) / 2) * parseInt(area.L)
  );
  let maxTotalDrainage = getMax(totalDrainageArray);
  let maxLength = totalLengthsArray.reduce((acc, sum) => {
    return acc + sum;
  }, 0);
  let maxWidthSub10 = getMax(maxWidthArray);
  let maxWidth = maxWidthSub10 + (maxWidthSub10 / 100) * 10;
  let maxArea = totalAreaArray.reduce((acc, val) => acc + val, 0);
  let totalArea = totalAreaArray.reduce((acc, sum) => {
    return acc + sum;
  }, 0);

  const input = document.getElementById("divToPrint");
  const input2 = document.getElementById("page-2");
  input &&
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 10, 10, 180, 280);
      html2canvas(input2).then((canvas) => {
        const imgData2 = canvas.toDataURL("image/jpeg", 1.0);
        pdf.addPage();
        pdf.addImage(imgData2, "JPEG", 10, 10, 180, 60);
        sessionStorage.setItem("downloadReady", true);
      });
    });

  return (
    <div className="page-container">
      <div className="pdf-container" id="divToPrint">
        <div className="header-container">
          <div className="logo" />
          <div className="title">HYDROPRO Line v.1.2</div>
          <div className="dummy" />
        </div>
        <div className="flex-container border-bottom">
          <div className="left-side">
            <div className="flex-container flex-start">
              <span>{translate("hydroproLineCalculatorExternalObjectData")}:</span>
              <p>{data.objectName}</p>
            </div>
            <div className="flex-container flex-start">
              <span>{translate("hydroproLineCalculatorResultDate")}</span>
              <p>{moment().format("DD.MM.YYYY")}</p>
            </div>
            <div className="flex-container flex-start">
              <span>{translate("hydroproLineCalculatorResultPDFCompiled")}</span>
              <p>{translate("hydroproLineCalculatorResultPDFCompiledEngineers")}</p>
            </div>
          </div>
          <div className="right-side">
            <div className="right-side-inner-1">
              <h3>{translate("hydroproLineCalculatorResultPDFOutputData")}</h3>
              <div className="data-container">
                {/* <div className="data-row">
                  <p>h-звено</p>
                  <p id="blue">0,21</p>
                  <span>m2</span>
                </div> */}
                <div className="data-row">
                  <p>slope</p>
                  <p id="blue">{data.slope}</p>
                  <span>m/m</span>
                </div>
                {/* <div className="data-row">
                  <p>грапавина</p>
                  <p id="blue">0,0135</p>
                  <span>-</span>
                </div> */}
                {/* <div className="data-row no-border">
                  <p>L1=</p>
                  <p id="blue">5</p>
                  <span>m2</span>
                </div> */}
              </div>
            </div>
            <div className="right-side-inner-2">
              <div className="top-container">
                <h3>{translate("hydroproLineCalculatorInputDimensionalRainData")}</h3>
                <div className="data-row">
                  <p id="big">q [l/s/ha]</p>
                  <p>{data.rainQuantity}</p>
                </div>
              </div>
              <div className="top-container">
                <h3>{translate("hydroproLineCalculatorInputDrainedAreaData")}</h3>
                <div className="data-row">
                  <p id="big">⍦ [l0-1]</p>
                  <p>{data.roughness}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-data-container">
          <div className="blue-data-container">
            <div className="flex-container no-bottom">
              <div className="left-element">
                <p>{translate("hydroproLineCalculatorResultProductLengthLabel")}</p>
                <span>{translate("hydroproLineCalculatorResultProductLength")}</span>
              </div>
              <div className="right-element">
                {totalLengthsArray.reduce((acc, sum) => {
                  return acc + sum;
                }, 0)}
              </div>
            </div>
            <div className="flex-container no-bottom">
              <div className="left-element">
                <p>{translate("hydroproLineCalculatorInputDrainedAreaDrainageArea")}:</p>
                <span>F[m2]</span>
              </div>
              <div className="right-element">
                {totalAreaArray.reduce((acc, sum) => {
                  return acc + sum;
                }, 0)}
              </div>
            </div>
            <div className="flex-container">
              <div className="left-element">
                <p>{translate("products-Shafts-HydrooilEccentricQuantity")}: </p>
                <span>Q[l/s]</span>
              </div>
              <div className="right-element">65</div>
            </div>
          </div>
          {data.areas.map((area, index) => {
            return (
              <div
                className={`other-data-container ${
                  index !== 0 && index % 3 === 0 && "no-margin"
                }`}
              >
                <div className="data-type">{`${translate("hydroproLineCalculatorResultAreaCaps")}${index + 1}`}</div>
                <div className="data-right-side">
                  <div className="flex-container">
                    <span>L1</span>
                    <p>{area.L}</p>
                  </div>
                  <div className="flex-container">
                    <span>B1,1</span>
                    <p>{area.B1}</p>
                  </div>
                  <div className="flex-container">
                    <span>B1,2</span>
                    <p>{area.B2}</p>
                  </div>
                  <div className="flex-container no-border">
                    <span>F1</span>
                    <p id="white">
                      {((parseInt(area.B1) + parseInt(area.B2)) / 2) *
                        parseInt(area.L)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="graphs-container">
          <div className="top-graphs">
            <div className="graph-container-one">
              <div className="top-container">
                <h1>{translate("hydroproLineCalculatorResultProductDrainageSchema")}</h1>
                <div className="arrow-btn" />
              </div>
              <div className="main-container">
                <div className="y-label">{translate("hydroproLineCalculatorResultProductWidth")}</div>
                <div className="rectangle-graph" id="rectangle-graph">
                  <div className="arrow-image" />
                  <svg
                    style={{
                      width: "102%",
                      height: "100%",
                      position: "absolute",
                      left: "0",
                      bottom: "0",
                    }}
                  >
                    {data.areas.map((area, index) => {
                      let previousLengths = data.areas.slice(0, index);
                      let totalPreviousLengths = previousLengths.reduce(
                        (acc, sum) => {
                          return acc + parseInt(sum.L);
                        },
                        0
                      );
                      return (
                        <>
                          <line
                            style={{
                              position: "absolute",
                            }}
                            x1={
                              (totalPreviousLengths / maxLength) *
                              100 *
                              rectGraphWidthPercentile
                            }
                            y1={
                              rectangleGraph &&
                              rectangleGraph.offsetHeight / 2 -
                                (rectGraphHeightPercentile *
                                  ((parseInt(area.B1) / maxWidth) * 100)) /
                                  2
                            }
                            x2={
                              (totalPreviousLengths / maxLength) *
                              100 *
                              rectGraphWidthPercentile
                            }
                            y2={
                              rectangleGraph &&
                              rectangleGraph.offsetHeight / 2 +
                                (rectGraphHeightPercentile *
                                  ((parseInt(area.B1) / maxWidth) * 100)) /
                                  2
                            }
                            stroke={colorMapRectangle[index]}
                            stroke-width="2"
                          />
                          <line
                            style={{
                              position: "absolute",
                            }}
                            x1={
                              (totalPreviousLengths / maxLength) *
                              100 *
                              rectGraphWidthPercentile
                            }
                            y1={
                              rectangleGraph &&
                              rectangleGraph.offsetHeight / 2 -
                                (rectGraphHeightPercentile *
                                  ((parseInt(area.B1) / maxWidth) * 100)) /
                                  2
                            }
                            x2={
                              (area.L / maxLength) *
                                100 *
                                rectGraphWidthPercentile +
                              (totalPreviousLengths / maxLength) *
                                100 *
                                rectGraphWidthPercentile
                            }
                            y2={
                              rectangleGraph &&
                              rectangleGraph.offsetHeight / 2 -
                                (rectGraphHeightPercentile *
                                  ((parseInt(area.B2) / maxWidth) * 100)) /
                                  2
                            }
                            stroke={colorMapRectangle[index]}
                            stroke-width="2"
                          />
                          <line
                            style={{
                              position: "absolute",
                            }}
                            x1={
                              (area.L / maxLength) *
                                100 *
                                rectGraphWidthPercentile +
                              (totalPreviousLengths / maxLength) *
                                100 *
                                rectGraphWidthPercentile
                            }
                            y1={
                              rectangleGraph &&
                              rectangleGraph.offsetHeight / 2 -
                                (rectGraphHeightPercentile *
                                  ((parseInt(area.B2) / maxWidth) * 100)) /
                                  2
                            }
                            x2={
                              (area.L / maxLength) *
                                100 *
                                rectGraphWidthPercentile +
                              (totalPreviousLengths / maxLength) *
                                100 *
                                rectGraphWidthPercentile
                            }
                            y2={
                              rectangleGraph &&
                              rectangleGraph.offsetHeight / 2 +
                                (rectGraphHeightPercentile *
                                  ((parseInt(area.B2) / maxWidth) * 100)) /
                                  2
                            }
                            stroke={colorMapRectangle[index]}
                            stroke-width="2"
                          />
                          <line
                            style={{
                              position: "absolute",
                            }}
                            x1={
                              (area.L / maxLength) *
                                100 *
                                rectGraphWidthPercentile +
                              (totalPreviousLengths / maxLength) *
                                100 *
                                rectGraphWidthPercentile
                            }
                            y1={
                              rectangleGraph &&
                              rectangleGraph.offsetHeight / 2 +
                                (rectGraphHeightPercentile *
                                  ((parseInt(area.B2) / maxWidth) * 100)) /
                                  2
                            }
                            x2={
                              (totalPreviousLengths / maxLength) *
                              100 *
                              rectGraphWidthPercentile
                            }
                            y2={
                              rectangleGraph &&
                              rectangleGraph.offsetHeight / 2 +
                                (rectGraphHeightPercentile *
                                  ((parseInt(area.B1) / maxWidth) * 100)) /
                                  2
                            }
                            stroke={colorMapRectangle[index]}
                            stroke-width="2"
                          />
                        </>
                      );
                    })}
                  </svg>
                  <div className="graph-row">
                    <div className="graph-row-upper-value">
                      {Math.ceil(maxWidth / 10) * 10}
                    </div>
                    <div className="graph-row-value">
                      {Math.ceil(((maxWidth / 3) * 2) / 10) * 10}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {Math.ceil(maxWidth / 3 / 10) * 10}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">0</div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {Math.ceil(maxWidth / 3 / 10) * 10}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {Math.ceil(((maxWidth / 3) * 2) / 10) * 10}
                    </div>
                  </div>
                  <div className="graph-row last">
                    <div className="graph-row-value">
                      {Math.ceil(maxWidth / 10) * 10}
                    </div>
                  </div>
                  <div className="x-axis-container">
                    <div className="x-axis-value-container">
                      <div className="x-axis-value">0</div>
                    </div>
                    <div className="x-axis-value-container">
                      <div className="x-axis-value">
                        {Math.ceil(maxLength / 5 / 10) * 10}
                      </div>
                    </div>
                    <div className="x-axis-value-container">
                      <div className="x-axis-value">
                        {Math.ceil(((maxLength / 5) * 2) / 10) * 10}
                      </div>
                    </div>
                    <div className="x-axis-value-container">
                      <div className="x-axis-value">
                        {Math.ceil(((maxLength / 5) * 3) / 10) * 10}
                      </div>
                    </div>
                    <div className="x-axis-value-container">
                      <div className="x-axis-value">
                        {Math.ceil(((maxLength / 5) * 4) / 10) * 10}
                      </div>
                    </div>
                    <div className="x-axis-value-container">
                      <div className="x-axis-value">
                        {Math.ceil(maxLength / 10) * 10}
                      </div>
                    </div>
                  </div>
                  <h3 id="x-axis-unit">{translate("hydroproLineCalculatorInputDrainedAreaDrainageLength")}</h3>
                </div>
                <div className="areas-legend">
                  <div className="arrow-icon" />
                  <h3>{translate("hydroproLineCalculatorResultProductDrainageDirection")}</h3>
                  {data.areas.map((area, index) => {
                    return (
                      <div className="flex-container">
                        <div
                          className="area-color"
                          style={{
                            backgroundColor: colorMapRectangle[index],
                          }}
                        />
                        <p>{`${translate("hydroproLineCalculatorResultAreaDash")}${index + 1}`}</p>
                      </div>
                    );
                  })}
                  <p>F [m2] {totalArea}</p>
                  <p>L [m] {maxLength}</p>
                </div>
              </div>
            </div>
            <div className="graph-container-two">
              <div className="top-container">
                <h1>{translate("hydroproLineCalculatorResultProductInformation")}</h1>
                <div className="arrow-btn" />
              </div>
              <div className="main-container">
                <div className="y-label">{"Q[l/s]"}</div>
                <div className="graph" id="graph">
                  <svg
                    style={{
                      width: "100%",
                      height: "105%",
                      position: "absolute",
                      left: "0",
                      bottom: "0",
                    }}
                  >
                    {Object.keys(data.dataPipes).map((line) => {
                      return data.dataPipes[line].map((el, index) => {
                        let n = data.dataPipes[line].length > 450 ? 15 : 1;
                        if (
                          data.dataPipes[line].length > 450 &&
                          index !== 0 &&
                          (index % 2 === 0 ||
                            index % 3 === 1 ||
                            index % 3 === 0 ||
                            index % 4 === 0 ||
                            index % 4 === 1 ||
                            index % 4 === 2)
                        ) {
                          return null
                        } else {
                          return (
                            <>
                              {/* <div
                                    className="dot-coordinate"
                                    style={{
                                      left: `${
                                        (el.step * 100) /
                                        dataArray[dataArray.length - 1].step
                                      }%`,
                                      bottom: `${
                                        (el.totalDrain * 100) / maxTotalDrainage
                                      }%`,
                                      backgroundColor: colorMap[el.pipe],
                                    }}
                                  /> */}
                              {data.dataPipes[line][index + n] && (
                                <line
                                  style={{
                                    position: "absolute",
                                  }}
                                  x1={
                                    ((el.step * 100) /
                                      data.dataPipes[line][
                                        data.dataPipes[line].length - 1
                                      ].step) *
                                    graphWidthPercentile
                                  }
                                  y1={
                                    graph &&
                                    graph.offsetHeight -
                                      ((el.totalDrain * 100) /
                                        maxTotalDrainage) *
                                        graphHeightPercentile +
                                      15
                                  }
                                  x2={
                                    ((data.dataPipes[line][index + n].step *
                                      100) /
                                      data.dataPipes[line][
                                        data.dataPipes[line].length - 1
                                      ].step) *
                                    graphWidthPercentile
                                  }
                                  y2={
                                    graph &&
                                    graph.offsetHeight -
                                      ((data.dataPipes[line][index + n]
                                        .totalDrain *
                                        100) /
                                        maxTotalDrainage) *
                                        graphHeightPercentile +
                                      15
                                  }
                                  stroke={colorMap[el.pipe]}
                                  stroke-width="2"
                                />
                              )}
                            </>
                          );
                        }
                      });
                    })}
                    {data.graphData.map((mainLine, index) => {
                      let nextElement =
                        (data.graphData[index + 1] &&
                          data.graphData[index + 1].pipe) ||
                        mainLine.pipe;
                      if (
                        mainLine.pipe === nextElement &&
                        data.graphData.length > 500 &&
                        (index % 2 === 0 ||
                          index % 3 === 1 ||
                          index % 3 === 0 ||
                          index % 4 === 0 ||
                          index % 4 === 1 ||
                          index % 4 === 2)
                      ) {
                        return null
                      } else {
                        return (
                          <>
                            {/* <div
                                  className="dot-coordinate"
                                  style={{
                                    left: `${
                                      (mainLine.Q * 100) /
                                      graph[
                                        graph.length - 1
                                      ].Q
                                    }%`,
                                    bottom: `${
                                      (mainLine.Q * 100) / maxTotalDrainage
                                    }%`,
                                    backgroundColor: "red",
                                  }}
                                /> */}
                            {data.graphData[index + 20] && (
                              <line
                                style={{
                                  position: "absolute",
                                }}
                                x1={
                                  ((mainLine.Q * 100) /
                                    data.graphData[data.graphData.length - 1]
                                      .Q) *
                                  graphWidthPercentile
                                }
                                y1={
                                  graph &&
                                  graph.offsetHeight -
                                    ((mainLine.Q * 100) / maxTotalDrainage) *
                                      graphHeightPercentile +
                                    15
                                }
                                x2={
                                  ((data.graphData[index + 20].Q * 100) /
                                    data.graphData[data.graphData.length - 1]
                                      .Q) *
                                  graphWidthPercentile
                                }
                                y2={
                                  graph &&
                                  graph.offsetHeight -
                                    ((data.graphData[index + 20].Q * 100) /
                                      maxTotalDrainage) *
                                      graphHeightPercentile +
                                    15
                                }
                                stroke="red"
                                stroke-width="2"
                              />
                            )}
                            {mainLine.drainage < 0 && (
                              <circle
                                r="3"
                                stroke="black"
                                stroke-width="2"
                                fill="none"
                                cy={
                                  graph &&
                                  graph.offsetHeight -
                                    ((mainLine.Q * 100) / maxTotalDrainage) *
                                      graphHeightPercentile +
                                    15
                                }
                                cx={
                                  ((mainLine.Q * 100) /
                                    data.graphData[data.graphData.length - 1]
                                      .Q) *
                                  graphWidthPercentile
                                }
                              />
                            )}
                          </>
                        );
                      }
                    })}
                  </svg>
                  <div className="graph-row first">
                    <div className="graph-row-value">
                      {/* {Math.ceil(maxTotalDrainage / 10) * 10} */}
                      {(Math.ceil(maxTotalDrainage / 100) * 100).toFixed(0)}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {/* {Math.ceil(((maxTotalDrainage / 5) * 4) / 10) * 10} */}
                      {(((Math.ceil(maxTotalDrainage / 100) * 100) / 5) * 4).toFixed(0)}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {/* {Math.ceil(((maxTotalDrainage / 5) * 3) / 10) * 10} */}
                      {(((Math.ceil(maxTotalDrainage / 100) * 100) / 5) * 3).toFixed(0)}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {/* {Math.ceil(((maxTotalDrainage / 5) * 2) / 10) * 10} */}
                      {(((Math.ceil(maxTotalDrainage / 100) * 100) / 5) * 2).toFixed(0)}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {/* {Math.ceil(maxTotalDrainage / 5 / 10) * 10} */}
                      {((Math.ceil(maxTotalDrainage / 100) * 100) / 5).toFixed(0)}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">0</div>
                  </div>
                  <div className="x-axis-container">
                    <div className="graph-col">
                      <div className="graph-col-value">0</div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(
                          data.graphData[Math.floor(data.graphData.length / 5)]
                            .step / 10
                        ) * 10}
                      </div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(
                          data.graphData[
                            Math.floor((data.graphData.length / 5) * 2)
                          ].step / 10
                        ) * 10}
                      </div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(
                          data.graphData[
                            Math.floor((data.graphData.length / 5) * 3)
                          ].step / 10
                        ) * 10}
                      </div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(
                          data.graphData[
                            Math.floor((data.graphData.length / 5) * 4)
                          ].step / 10
                        ) * 10}
                      </div>
                    </div>
                    <div className="graph-col last">
                      <div className="graph-col-value">
                        {Math.ceil(
                          data.graphData[data.graphData.length - 1].step / 10
                        ) * 10}
                        {/* {(Math.ceil(maxTotalDrainage / 100) * 100).toFixed(0)} //test */}
                      </div>
                    </div>
                  </div>
                  <h3 id="x-axis-unit">{"L[m]"}</h3>
                </div>
                <div className="linear-graph-legend">
                  {data.calculator.map((line, index) => {
                    return (
                      <div className="flex-container">
                        <div
                          className="line-color"
                          style={{ backgroundColor: colorMap[line.pipe] }}
                        />
                        <p>{line.pipe}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-graphs">
            <div className="graph-container-three">
              <h1>{translate("hydroproLineCalculatorResultCharacteristicsAreaGrowth")}</h1>
              <div className="main-container">
                <div className="y-label">
                  {translate("hydroproLineCalculatorResultArea")}
                  <p>2</p>
                  <span id="margin">{"]"}</span>
                </div>
                <div className="area-graph" id="area-graph">
                  <svg
                    style={{
                      width: "105%",
                      height: "100%",
                      position: "absolute",
                      left: "0",
                      bottom: "0",
                    }}
                  >
                    {data.areas.map((area, index) => {
                      let previousLengths = data.areas.slice(0, index);
                      let totalPreviousAreas = previousLengths.reduce(
                        (acc, val) => {
                          return (
                            acc +
                            parseInt(val.L) *
                              ((parseInt(val.B1) + parseInt(val.B2)) / 2)
                          );
                        },
                        0
                      );
                      let totalPreviousLengths = previousLengths.reduce(
                        (acc, sum) => {
                          return acc + parseInt(sum.L);
                        },
                        0
                      );
                      // let previousArea =
                      //   index === 0
                      //     ? 0
                      //     : parseInt(data.areas[index - 1].L) *
                      //       ((parseInt(data.areas[index - 1].B1) +
                      //         parseInt(data.areas[index - 1].B2)) /
                      //         2);
                      let currentArea =
                        parseInt(area.L) *
                        ((parseInt(area.B1) + parseInt(area.B2)) / 2);
                      return (
                        <>
                          <line
                            x1={
                              index === 0
                                ? 0
                                : ((totalPreviousLengths * 100) / maxLength) *
                                  areaGraphWidthPercentile
                            }
                            y1={
                              areaGraph &&
                              areaGraph.offsetHeight -
                                (totalPreviousAreas / maxArea) *
                                  100 *
                                  areaGraphHeightPercentile
                            }
                            x2={
                              (((parseInt(area.L) + totalPreviousLengths) *
                                100) /
                                maxLength) *
                              areaGraphWidthPercentile
                            }
                            y2={
                              areaGraph &&
                              areaGraph.offsetHeight -
                                ((currentArea + totalPreviousAreas) / maxArea) *
                                  100 *
                                  areaGraphHeightPercentile
                            }
                            stroke-width="2"
                            stroke="red"
                          />
                          {index + 1 !== data.areas.length && (
                            <circle
                              r="3"
                              stroke="black"
                              stroke-width="2"
                              fill="none"
                              cy={
                                areaGraph &&
                                areaGraph.offsetHeight -
                                  ((currentArea + totalPreviousAreas) /
                                    maxArea) *
                                    100 *
                                    areaGraphHeightPercentile
                              }
                              cx={
                                (((parseInt(area.L) + totalPreviousLengths) *
                                  100) /
                                  maxLength) *
                                areaGraphWidthPercentile
                              }
                            />
                          )}
                        </>
                      );
                    })}
                  </svg>
                  <h3 id="x-axis-unit">{translate("hydroproLineCalculatorResultProductLength")}</h3>
                  <div className="graph-row first">
                    <div className="graph-row-value">
                      {Math.ceil(maxArea / 1000) * 1000}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {Math.ceil(((maxArea / 3) * 2) / 1000) * 1000}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">
                      {Math.ceil(maxArea / 3 / 1000) * 1000}
                    </div>
                  </div>
                  <div className="graph-row">
                    <div className="graph-row-value">0</div>
                  </div>
                  <div className="x-axis-container">
                    <div className="graph-col">
                      <div className="graph-col-value">0</div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(maxLength / 7 / 10) * 10}
                      </div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(((maxLength / 7) * 2) / 10) * 10}
                      </div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(((maxLength / 7) * 3) / 10) * 10}
                      </div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(((maxLength / 7) * 4) / 10) * 10}
                      </div>
                    </div>
                    <div className="graph-col last">
                      <div className="graph-col-value">
                        {Math.ceil(((maxLength / 7) * 5) / 10) * 10}
                      </div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(((maxLength / 7) * 6) / 10) * 10}
                      </div>
                    </div>
                    <div className="graph-col">
                      <div className="graph-col-value">
                        {Math.ceil(maxLength / 10) * 10}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-2" id="page-2">
        <div className="additional-info-container">
          <div className="pictures-container">
            {/* <div className="flex-container flex-start">
              <span>Изходни данни за обект:</span>
              <p>PORT V</p>
            </div> */}
            <div className="flex-container flex-start">
              <span>{translate("hydroproLineCalculatorResultDate")}</span>
              <p>{moment().format("DD.MM.YYYY")}</p>
            </div>
            <div className="flex-container flex-start">
              <span>{translate("hydroproLineCalculatorResultPDFCompiled")}</span>
              <p>{translate("hydroproLineCalculatorResultPDFCompiledEngineers")}</p>
            </div>
            <div className="pics">
              <div className="picture-1" />
              <div className="picture-2" />
            </div>
          </div>
          <div className="solutions-container">
            <h3>{translate("hydroproLineCalculatorResultProductLengthSolutions")}</h3>
            <div className="solutions-table">
              <div className="flex-container blue">
                <p id="left">№</p>
                <p id="mid">{translate("hydroproLineCalculatorResultProductType")}</p>
                <p id="right">{translate("hydroproLineCalculatorResultProductLength")}</p>
              </div>
              {data.calculator.map((solution, index) => {
                return (
                  <div className="flex-container">
                    <span id="left">{index + 1}</span>
                    <span id="mid">{solution.pipe}</span>
                    <span id="right">{solution.step}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CalculationLinearDrainagePDF;
