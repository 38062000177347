import React from "react";
import Slider from "react-slick";
import { Utilities } from "../../utilities";
import { Footer } from "../../components";
import { MakeAQueryBttn } from "../../components";
import { translate } from "../../translations/localization";
import "./mstylesnew.scss";
import { Helmet } from "react-helmet";
import { getSingleProduct } from "../../actions";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { history } from "../../config/stores";
import { CustomTable } from "../../components";

export default function ProductMobileNew() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const productData = useSelector((state) => state.products.singleProduct);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  let structuredJSON = {
    "@context": "https://schema.org/",
    "@type": "Product",
    brand: "Hydroil",
    name: productData?.title,
    description: productData?.description,
    image: productData?.mediaPhotos,
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      bestRating: "5",
      worstRating: "0",
      ratingCount: "1",
    },
  };

  useEffect(() => {
    dispatch(getSingleProduct({ _id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (productData) {
      structuredJSON = {
        "@context": "https://schema.org/",
        "@type": "Product",
        brand: "Hydroil",
        name: Utilities.translateLanguage(productData?.title),
        description: Utilities.translateLanguage(productData?.description),
        image: productData?.mediaPhotos,
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "5",
          bestRating: "5",
          worstRating: "0",
          ratingCount: "1",
        },
      };
    }
  }, [productData]);

  return (
    <div className="solution-m page-bg">
      <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(structuredJSON)}
        </script>
      </Helmet>
      <div className="container-m">
        <div className="top-wrapper" onClick={() => history.goBack()}>
          <img src={require("../../assets/icons/arrow-back-white.svg")} alt="go back" />
          <h1>{Utilities.translateLanguage(productData?.name)}</h1>
        </div>
        <div className="content-m">
          <div className="tabs-list-m">
            <div>
              <div
                className="tab-name"
                style={{ borderBottom: "2px solid #1fecff" }}
              >
                {translate("tabOptionAboutProduct")}
              </div>
            </div>

            <div>
              <div className="tab-name subtitle">
                {translate("description")}
              </div>
              <div className="description">
                {Utilities.translateLanguage(productData?.description)}
              </div>
            </div>

            {(productData?.specifications?.bg?.length || productData?.specifications?.en?.length
            || productData?.table?.bg?.length || productData?.table?.en?.length) ?
            <div>
              <div className="tab-name subtitle">
                {translate("tabOptionSpecificationProduct")}
              </div>

              {Utilities.translateLanguage(productData?.specifications)?.length ?
              <p className="link" onClick={() => window.open(Utilities.translateLanguage(productData?.specifications))}>{Utilities.translateLanguage(productData?.specifications)}</p>
              : <div/>}

              {(productData?.table?.bg?.length || productData?.table?.en?.length) ? 
              <CustomTable data={Utilities.translateLanguage(productData?.table)} /> : ''}
            </div> : ''}

            {Utilities.translateLanguage(productData?.appendix)?.length ?
            <div>
              <div className="tab-name subtitle">
                {translate("products-KMU-HydrooilBypassTabApplication")}
              </div>
              <div className="description">
                {Utilities.translateLanguage(productData?.appendix)}
              </div>
            </div> : ''}

            {Utilities.translateLanguage(productData?.quality)?.length ? 
            <div className="margin">
              <div className="tab-name subtitle">{translate("quality")}</div>
              <div className="description">
                {Utilities.translateLanguage(productData?.quality)}
              </div>
            </div> : ''}

            {Utilities.translateLanguage(productData?.effectiveness)?.length ?
            <div>
              <div className="tab-name subtitle">{translate("efficiency")}</div>
              <div className="description">
                {Utilities.translateLanguage(productData?.effectiveness)}
              </div>
            </div> : ''}

            {productData?.manufacturer === "hydropro" && (
              <div className="flex-col-wrapper margin">
                <div className="tab-name subtitle">{translate("producer")}</div>
                <img
                  src={require("../../assets/icons/white-hydropro.svg")}
                  className="header-logo-new"
                  alt="logo"
                />
              </div>
            )}

            <div className="full-width-query-bttn">
              <MakeAQueryBttn />
            </div>

            {(productData?.installation?.bg?.length
             || productData?.installation?.en?.length
             || productData?.technicalInformation?.bg?.length
             || productData?.technicalInformation?.en?.length
             || productData?.bimInformation?.bg?.length
             || productData?.bimInformation?.en?.length
             || productData?.bimFiles?.bg?.length
             || productData?.bimFiles?.en?.length) ?
             <div className="margin">
              <div className="tab-name" style={{ borderBottom: "2px solid #1fecff" }}>
                {translate("products-KMU-HydrooilBypassTabTechnicalInformation")}
              </div>
            </div> : ''}

            {Utilities?.translateLanguage(productData?.installation) ?
            <div className="flex-col-wrapper">
              <div className="tab-name subtitle">
                {translate("products-KMU-HydrooilBypassTabInstallation")}
              </div>
              <div className="description">
                {Utilities?.translateLanguage(productData?.installation)}
              </div>
            </div> : ''}

            {Utilities.translateLanguage(productData?.technicalInformation)?.length ?
            <div className="flex-col-wrapper margin">
              <div className="tab-name subtitle">
                {translate("products-KMU-HydrooilBypassTabTechnicalInformation")}
              </div>
              <div className="description">
                {Utilities.translateLanguage(productData?.technicalInformation)?.map((techinfo, index) => (
                  <div className="techinfo-item">
                    <a
                      href={techinfo}
                      title=""
                      target="_blank"
                      className="link"
                      rel="noopener noreferrer"
                    >
                      {Utilities.renderUrl(techinfo) || techinfo || ''}
                      {/* {techinfo} */}
                    </a>
                  </div>
                ))}
              </div>
            </div> : ''}

            {(Utilities.translateLanguage(productData?.bimInformation)?.length ||
             Utilities.translateLanguage(productData?.bimFiles)?.length) ? (
                <div className="flex-col-wrapper margin">
                  <div className="tab-name subtitle">
                    Building Information Modeling – BIM
                  </div>
                  {productData?.bimInformation && (
                  <div className="description">{Utilities.translateLanguage(productData?.bimInformation)}</div>)}

                  {productData?.bimFiles && (
                    <div className="description">
                      {Utilities.translateLanguage(productData?.bimFiles)?.map(
                        (file) => {
                          return (
                            <div className="box-p flex">
                              <a href={file} target="_blank" className="link">
                                {Utilities.renderUrl(file) || file || ''}
                                {/* {file} */}
                              </a>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              ) : ''}

            {Utilities.translateLanguage(productData?.solutions)?.length ? (
              <div className="flex-col-wrapper margin">
                <div
                  className="tab-name"
                  style={{ borderBottom: "2px solid #1fecff" }}
                >
                  {translate("headerSolutions")}
                </div>
                <div className="description">
                  {Utilities.translateLanguage(productData?.solutions)?.map(
                    (solution) => {
                      return (
                        <div className="box-p flex">
                          <h1 className="border">
                            {solution.split(":")[0] + ":"}&nbsp;
                          </h1>
                          <span className="regular">
                            {solution.split(":")[1]}
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : ''}

            {(productData?.mediaPhotos?.length || productData?.mediaVideos?.length || productData?.drawings?.bg?.length || productData?.drawings?.en?.length) ?
            <div className="margin">
              <div
                className="tab-name"
                style={{ borderBottom: "2px solid #1fecff" }}
              >
                {translate("tabOptionMediaAndDrawings")}
              </div>
            </div> : ''}

            {(productData?.mediaPhotos?.length || productData?.mediaVideos?.length) ?
              <>
                <h2>{translate("tabOptionMedia")}</h2>
                  <div className="product-gallery-m">
                    <div className="slider-main">
                      <Slider {...settings}>
                        {productData?.mediaPhotos &&
                          productData?.mediaVideos &&
                          productData?.mediaPhotos
                            .concat(productData?.mediaVideos)
                            ?.map((media) => {
                              return (
                                <>
                                  {media?.includes("mp4") ? (
                                    <div
                                      className="video-container"
                                      style={{
                                        position: "relative",
                                        height: "100%",
                                        width: "100%",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <video
                                        controls={true}
                                        playsInline={false}
                                        muted={true}
                                        preload="auto"
                                        loop={true}
                                        autoPlay={true}
                                        style={{
                                          position: "absolute",
                                          top: "20%",
                                          bottom: "73%",
                                          maxWidth: "100%",
                                          minWidth: "100%",
                                          height: "auto",
                                          objectFit: "contain",
                                        }}
                                      >
                                        <source src={media} type="video/mp4" />
                                      </video>
                                    </div>
                                  ) : (
                                    <div className="img-wrapper-margin">
                                      <div className="product-img">
                                        <img src={media} alt="image product" />
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                      </Slider>
                    </div>
                  </div>
              </> : ''}

            {Utilities.translateLanguage(productData.drawings) ?
            <div className={`flex-col-wrapper ${(productData?.mediaPhotos?.length || productData?.mediaVideos?.length) ? 'margin' : ''}`}>
              <div className="tab-name subtitle">
                {translate("products-KMU-HydrooilBypassTabDrawings")}
              </div>
              <div className="description">
                {Utilities.translateLanguage(productData.drawings)?.map(
                  (drawings, index) => (
                    <div className="drawings-item">
                      <a href={drawings} target="_blank" title="">
                        {Utilities.renderUrl(drawings) || drawings || ''}
                        {/* {drawings} */}
                      </a>
                    </div>
                  )
                )}
              </div>
            </div> : ''}

            <div className="full-width-query-bttn">
              <MakeAQueryBttn />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
