import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { configureStore } from "./config/stores";
import Routes from "./components/routes/Routes";
import { history } from "./config/stores";
import { translate } from "./translations/localization";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import "moment/locale/bg";

let store = configureStore();

class App extends Component {
  constructor() {
    super();
    // const INITIAL_STATE = window.innerWidth
    this.state = {
      // desktop: INITIAL_STATE > 900,
      loading: true,
    };
  }

  // updateScreen () {
  //   const width = window.innerWidth
  //   if (width > 900 && this.state.desktop === false) {
  //     this.setState({
  //       desktop: true
  //     })
  //     setTimeout(function () {
  //       window.location.reload()
  //     })
  //   } else if (width <= 900 && this.state.desktop === true) {
  //     this.setState({
  //       desktop: false
  //     })
  //     setTimeout(function () {
  //       window.location.reload()
  //     })
  //   }
  // }
  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 1500);

    document.title = translate("hydroproWebsiteTitle")

    // this.updateScreen()
    // window.addEventListener('resize', this.updateScreen.bind(this))
  }

  // componentWillUnmount () {
  //   window.removeEventListener('resize', this.updateScreen.bind(this))
  // }
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    );
  }
}

export default App;
