import React from 'react';
import {Spreadsheet, createFormulaParser} from "react-spreadsheet";
import './styles.scss';

export default function CustomTable({ data }) {
    // const data = [
    //     [{ value: "Vanilla" }, { value: "Chocolate" }, { value: "" }],
    //     [{ value: "Strawberry" }, { value: "Cookies" }, { value: "" }],
    //   ];
  return (
    <div className="custom-table-wrapper">
        {data?.[0]?.[0]?.value && <Spreadsheet data={data} disabled />}
    </div>
  )
}
