import React, { useState, useEffect } from "react";
import "./styles.scss";
import { translate } from "../../translations/localization";

const colorMapRectangle = [
  "#309FFA",
  "#ED47ED",
  "#09AF75",
  "#FD773B",
  "#FEBD2B",
  "#954CEA",
  "#49C5E8",
  "#9BC14B",
  "#227EE8",
];

const getMax = (arr) => {
  let len = arr.length;
  let max = 0;

  while (len--) {
    max = arr[len] > max ? arr[len] : max;
  }
  return max + (max / 100) * 5;
};

function GraphTwo({ data }) {
  const [rectGraphWidthPercentile, setGraphWidthPercentile] = useState(null);
  const [rectGraphHeightPercentile, setGraphHeightPercentile] = useState(null);
  const rectangleGraph = document.getElementById("rectangle-graph");

  let maxWidthArray = data.areas.map((area) =>
    parseInt(area.B1) > parseInt(area.B2)
      ? parseInt(area.B1)
      : parseInt(area.B2)
  );
  let maxWidth = getMax(maxWidthArray);
  let totalLengthsArray = data.areas.map((area) => parseInt(area.L));
  let totalAreaArray = data.areas.map(
    (area) => ((parseInt(area.B1) + parseInt(area.B2)) / 2) * parseInt(area.L)
  );
  let maxLength = totalLengthsArray.reduce((acc, sum) => {
    return acc + sum;
  }, 0);
  let totalArea = totalAreaArray.reduce((acc, sum) => {
    return acc + sum;
  }, 0);
  useEffect(() => {
    const rect = document.getElementById("rectangle-graph");
    const graphWidth = rect.offsetWidth / 100;
    const graphHeight = rect.offsetHeight / 100;
    setGraphWidthPercentile(graphWidth);
    setGraphHeightPercentile(graphHeight);
  }, []);

  return (
    <div className="graph-two">
      <div className="graph-two-inner-container">
        <div className="top-container">
          <h1>{translate("hydroproLineCalculatorResultProductDrainageSchema")}</h1>
          <div className="arrow-btn" />
        </div>
        <div className="main-container">
          <div className="y-label">{translate("hydroproLineCalculatorResultProductWidth")}</div>
          <div className="rectangle-graph" id="rectangle-graph">
            <div className="arrow-image" />
            <svg
              style={{
                width: "103%",
                height: "100%",
                position: "absolute",
                left: "0",
                bottom: "0",
              }}
            >
              {data.areas.map((area, index) => {
                let previousLengths = data.areas.slice(0, index);
                let totalPreviousLengths = previousLengths.reduce(
                  (acc, sum) => {
                    return acc + parseInt(sum.L);
                  },
                  0
                );
                return (
                  <>
                    <line
                      key={`graph-two-${index}`}
                      style={{
                        position: "absolute",
                      }}
                      x1={
                        (totalPreviousLengths / maxLength) *
                        100 *
                        rectGraphWidthPercentile
                      }
                      y1={
                        rectangleGraph &&
                        rectangleGraph.offsetHeight / 2 -
                          (rectGraphHeightPercentile *
                            ((parseInt(area.B1) / maxWidth) * 100)) /
                            2
                      }
                      x2={
                        (totalPreviousLengths / maxLength) *
                        100 *
                        rectGraphWidthPercentile
                      }
                      y2={
                        rectangleGraph &&
                        rectangleGraph.offsetHeight / 2 +
                          (rectGraphHeightPercentile *
                            ((parseInt(area.B1) / maxWidth) * 100)) /
                            2
                      }
                      stroke={colorMapRectangle[index]}
                      stroke-width="2"
                    />
                    <line
                      style={{
                        position: "absolute",
                      }}
                      x1={
                        (totalPreviousLengths / maxLength) *
                        100 *
                        rectGraphWidthPercentile
                      }
                      y1={
                        rectangleGraph &&
                        rectangleGraph.offsetHeight / 2 -
                          (rectGraphHeightPercentile *
                            ((parseInt(area.B1) / maxWidth) * 100)) /
                            2
                      }
                      x2={
                        (area.L / maxLength) * 100 * rectGraphWidthPercentile +
                        (totalPreviousLengths / maxLength) *
                          100 *
                          rectGraphWidthPercentile
                      }
                      y2={
                        rectangleGraph &&
                        rectangleGraph.offsetHeight / 2 -
                          (rectGraphHeightPercentile *
                            ((parseInt(area.B2) / maxWidth) * 100)) /
                            2
                      }
                      stroke={colorMapRectangle[index]}
                      stroke-width="2"
                    />
                    <line
                      style={{
                        position: "absolute",
                      }}
                      x1={
                        (area.L / maxLength) * 100 * rectGraphWidthPercentile +
                        (totalPreviousLengths / maxLength) *
                          100 *
                          rectGraphWidthPercentile
                      }
                      y1={
                        rectangleGraph &&
                        rectangleGraph.offsetHeight / 2 -
                          (rectGraphHeightPercentile *
                            ((parseInt(area.B2) / maxWidth) * 100)) /
                            2
                      }
                      x2={
                        (area.L / maxLength) * 100 * rectGraphWidthPercentile +
                        (totalPreviousLengths / maxLength) *
                          100 *
                          rectGraphWidthPercentile
                      }
                      y2={
                        rectangleGraph &&
                        rectangleGraph.offsetHeight / 2 +
                          (rectGraphHeightPercentile *
                            ((parseInt(area.B2) / maxWidth) * 100)) /
                            2
                      }
                      stroke={colorMapRectangle[index]}
                      stroke-width="2"
                    />
                    <line
                      style={{
                        position: "absolute",
                      }}
                      x1={
                        (area.L / maxLength) * 100 * rectGraphWidthPercentile +
                        (totalPreviousLengths / maxLength) *
                          100 *
                          rectGraphWidthPercentile
                      }
                      y1={
                        rectangleGraph &&
                        rectangleGraph.offsetHeight / 2 +
                          (rectGraphHeightPercentile *
                            ((parseInt(area.B2) / maxWidth) * 100)) /
                            2
                      }
                      x2={
                        (totalPreviousLengths / maxLength) *
                        100 *
                        rectGraphWidthPercentile
                      }
                      y2={
                        rectangleGraph &&
                        rectangleGraph.offsetHeight / 2 +
                          (rectGraphHeightPercentile *
                            ((parseInt(area.B1) / maxWidth) * 100)) /
                            2
                      }
                      stroke={colorMapRectangle[index]}
                      stroke-width="2"
                    />
                  </>
                );
              })}
            </svg>
            <div className="graph-row">
              <div className="graph-row-upper-value">
                {Math.ceil(maxWidth / 10) * 10}
              </div>
              <div className="graph-row-value">
                {Math.ceil(((maxWidth / 3) * 2) / 10) * 10}
              </div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">
                {Math.ceil(maxWidth / 3 / 10) * 10}
              </div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">0</div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">
                {Math.ceil(maxWidth / 3 / 10) * 10}
              </div>
            </div>
            <div className="graph-row">
              <div className="graph-row-value">
                {Math.ceil(((maxWidth / 3) * 2) / 10) * 10}
              </div>
            </div>
            <div className="graph-row last">
              <div className="graph-row-value">
                {Math.ceil(maxWidth / 10) * 10}
              </div>
            </div>
            <div className="x-axis-container">
              <div className="x-axis-value-container">
                <div className="x-axis-value">0</div>
              </div>
              <div className="x-axis-value-container">
                <div className="x-axis-value">
                  {Math.ceil(maxLength / 5 / 10) * 10}
                </div>
              </div>
              <div className="x-axis-value-container">
                <div className="x-axis-value">
                  {Math.ceil(((maxLength / 5) * 2) / 10) * 10}
                </div>
              </div>
              <div className="x-axis-value-container">
                <div className="x-axis-value">
                  {Math.ceil(((maxLength / 5) * 3) / 10) * 10}
                </div>
              </div>
              <div className="x-axis-value-container">
                <div className="x-axis-value">
                  {Math.ceil(((maxLength / 5) * 4) / 10) * 10}
                </div>
              </div>
              <div className="x-axis-value-container">
                <div className="x-axis-value">
                  {Math.ceil(maxLength / 10) * 10}
                </div>
              </div>
            </div>
            <h3 id="x-axis-unit">{translate("hydroproLineCalculatorInputDrainedAreaDrainageLength")}</h3>
          </div>
        </div>
      </div>
      <div className="graph-two-info">
        <div className="graph-legend-arrow" />
        <h4>{translate("hydroproLineCalculatorResultProductDrainageDirection")}</h4>
        {data.areas.map((area, index) => {
          return (
            <div className="area-color-container">
              <div
                className="area-color"
                style={{ backgroundColor: colorMapRectangle[index] }}
              />
              <p>{translate("hydroproLineCalculatorResultAreaDash")}{index + 1}</p>
            </div>
          );
        })}
        <p>F [m2] {totalArea}</p>
        <p>L [m] {maxLength}</p>
      </div>
    </div>
  );
}

export default GraphTwo;
