import React, { useState, useEffect } from "react";
import "./styles.scss";
import { translate } from "../../translations/localization";

// const getMax = (arr) => {
//   let len = arr.length;
//   let max = 0;

//   while (len--) {
//     max = arr[len] > max ? arr[len] : max;
//   }
//   return max;
// };

function GraphThree({ data }) {
  const [graphWidthPercentile, setGraphWidthPercentile] = useState(null);
  const [graphHeightPercentile, setGraphHeightPercentile] = useState(null);

  // const graphDOM = document.getElementById("area-graph");
  let totalLengthsArray = data.areas.map((area) => parseInt(area.L));
  let totalAreaArray = data.areas.map(
    (area) => ((parseInt(area.B1) + parseInt(area.B2)) / 2) * parseInt(area.L)
  );
  let maxLength = totalLengthsArray.reduce((acc, sum) => {
    return acc + sum;
  }, 0);
  let maxArea = totalAreaArray.reduce((acc, val) => acc + val, 0);
  let dataArray = [];
  totalLengthsArray.forEach((area, index) => {
    for (let i = 0; i <= area; i++) {
      let x;
      if (index !== 0) {
        x = totalLengthsArray[index - 1] + i;
      } else {
        x = i;
      }
      let y =
        ((parseInt(data.areas[index].B1) + parseInt(data.areas[index].B2)) /
          2) *
        x;
      dataArray.push({ x: x, y: y });
    }
  });
  useEffect(() => {
    const rect = document.getElementById("area-graph");
    const graphWidth = rect.offsetWidth / 100;
    const graphHeight = rect.offsetHeight / 100;
    setGraphWidthPercentile(graphWidth);
    setGraphHeightPercentile(graphHeight);
  }, []);

  return (
    <div className="graph-three">
      <h1>{translate("hydroproLineCalculatorResultCharacteristicsAreaGrowth")}</h1>
      <div className="main-container">
        <div className="y-label">
          {translate("hydroproLineCalculatorResultArea")}
          <p>2</p>
          <span id="margin">{"]"}</span>
        </div>
        <div className="area-graph" id="area-graph">
          <div className="graph-dot" />
          <svg
            style={{
              width: "105%",
              height: "105%",
              position: "absolute",
              left: "0",
              bottom: "0",
              transform: "scaleY(-1)",
            }}
          >
            {data.areas.map((area, index) => {
              let previousLengths = data.areas.slice(0, index);
              let totalPreviousAreas = previousLengths.reduce((acc, val) => {
                return (
                  acc +
                  parseInt(val.L) * ((parseInt(val.B1) + parseInt(val.B2)) / 2)
                );
              }, 0);
              let totalPreviousLengths = previousLengths.reduce((acc, sum) => {
                return acc + parseInt(sum.L);
              }, 0);
              // let previousArea =
              //   index === 0
              //     ? 0
              //     : parseInt(data.areas[index - 1].L) *
              //       ((parseInt(data.areas[index - 1].B1) +
              //         parseInt(data.areas[index - 1].B2)) /
              //         2);
              let currentArea =
                parseInt(area.L) *
                ((parseInt(area.B1) + parseInt(area.B2)) / 2);
              let x2Length = totalPreviousLengths + parseInt(area.L);
              return (
                <>
                  <line
                    key={`graph-three-${index}`}
                    x1={
                      index === 0
                        ? 0
                        : ((totalPreviousLengths * 100) / maxLength) *
                          graphWidthPercentile
                    }
                    y1={
                      (totalPreviousAreas / maxArea) *
                      100 *
                      graphHeightPercentile
                    }
                    x2={((x2Length * 100) / maxLength) * graphWidthPercentile}
                    y2={
                      ((totalPreviousAreas + currentArea) / maxArea) *
                      100 *
                      graphHeightPercentile
                    }
                    stroke-width="2"
                    stroke="red"
                  />
                  <circle
                    r="3"
                    stroke="white"
                    stroke-width="2"
                    fill="none"
                    cy={
                      ((totalPreviousAreas + currentArea) / maxArea) *
                      100 *
                      graphHeightPercentile
                    }
                    cx={
                      (((parseInt(area.L) + totalPreviousLengths) * 100) /
                        maxLength) *
                      graphWidthPercentile
                    }
                  />
                </>
              );
            })}
          </svg>
          <h3 id="x-axis-unit">{translate("hydroproLineCalculatorResultProductLength")}</h3>
          <div className="graph-row first">
            <div className="graph-row-value">
              {(Math.ceil(maxArea / 1000) * 1000).toFixed(0)}
            </div>
          </div>
          <div className="graph-row">
            <div className="graph-row-value">
              {Math.ceil(((maxArea / 3) * 2) / 1000) * 1000}
            </div>
          </div>
          <div className="graph-row">
            <div className="graph-row-value">
              {Math.ceil(maxArea / 3 / 1000) * 1000}
            </div>
          </div>
          <div className="graph-row">
            <div className="graph-row-value">0</div>
          </div>
          <div className="x-axis-container">
            <div className="graph-col">
              <div className="graph-col-value">0</div>
            </div>
            <div className="graph-col">
              <div className="graph-col-value">
                {(Math.ceil(maxLength / 7 / 10) * 10).toFixed(0)}
              </div>
            </div>
            <div className="graph-col">
              <div className="graph-col-value">
                {(Math.ceil(((maxLength / 7) * 2) / 10) * 10).toFixed(0)}
              </div>
            </div>
            <div className="graph-col">
              <div className="graph-col-value">
                {(Math.ceil(((maxLength / 7) * 3) / 10) * 10).toFixed(0)}
              </div>
            </div>
            <div className="graph-col">
              <div className="graph-col-value">
                {(Math.ceil(((maxLength / 7) * 4) / 10) * 10).toFixed(0)}
              </div>
            </div>
            <div className="graph-col last">
              <div className="graph-col-value">
                {(Math.ceil(((maxLength / 7) * 5) / 10) * 10).toFixed(0)}
              </div>
            </div>
            <div className="graph-col">
              <div className="graph-col-value">
                {(Math.ceil(((maxLength / 7) * 6) / 10) * 10).toFixed(0)}
              </div>
            </div>
            <div className="graph-col">
              <div className="graph-col-value">
                {Math.ceil(maxLength / 10) * 10}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraphThree;
