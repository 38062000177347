export const ArticleTypes = {
    GET_ARTICLES: "article/GET_ARTICLES",
    GET_ARTICLES_SUCCESS: "article/GET_ARTICLES_SUCCESS",
    GET_SINGLE_ARTICLE: "article/GET_SINGLE_ARTICLE",
    GET_SINGLE_ARTICLE_SUCCESS: "article/GET_SINGLE_ARTICLE_SUCCESS",
  };
  

  export const getArticles = (payload) => ({
    type: ArticleTypes.GET_ARTICLES,
    payload,
  });

  export const getArticlesSuccess = (payload) => ({
    type: ArticleTypes.GET_ARTICLES_SUCCESS,
    payload,
  });
  
  export const getSingleArticle = (payload) => ({
    type: ArticleTypes.GET_SINGLE_ARTICLE,
    payload,
  });

  export const getSingleArticleSuccess = (payload) => ({
    type: ArticleTypes.GET_SINGLE_ARTICLE_SUCCESS,
    payload,
  });