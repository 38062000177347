import React from "react";
import {Socials} from '../Socials';
import { translate } from "../../translations/localization";
import {history} from '../../config/stores'
import "./styles.scss";

function MobileFooter() {

  return (
    <div className="mobile-footer-container">
        <div className="flex-container-column">
            <p onClick={() => history.push("/")}>{translate("headerHome")}</p>
            <p onClick={() => history.push("/Products/home")}>{translate("headerProducts")}</p>
            <p onClick={() => history.push("/solutions/urban")}>{translate("headerSolutions")}</p>
            <p onClick={() => history.push("/references")}>{translate("headerReferences")}</p>
            <p onClick={() => history.push("/about-us")}>{translate("headerAboutUs")}</p>
            <p>{translate("headerCalcButton")}</p>
        </div>
        <Socials />
    </div>
  );
}

export default MobileFooter;
