import { GeneralTypes } from "../actions";

const initialState = {
  headerTab: "",
  modal: false,
  query: ""
};

const general = (state = initialState, { type, payload }) => {
  switch (type) {
    case GeneralTypes.SET_TAB:
      return { ...state, headerTab: payload };
    case GeneralTypes.SET_MODAL:
      return { ...state, modal: payload };
    case GeneralTypes.SEARCH_QUERY:
      return { ...state, query: payload };
    default:
      return state;
  }
};
export default general;
