import React from 'react';
import { translate } from '../../translations/localization'
import { Link } from 'react-router-dom'
import Calculator from './calculator'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import './stylesNew.scss'

export default function CalculationTankVolumeDesktopNew() {
  return (
    <div className='calculation-hydraulic-sizing-tool page-bg'>
    <div className='container'>

        <div className='content'>

            <div className='top-content'>
                <div className='breadcrumbs'>
                    <Link to='/specialized-materials-list' className='link-back'><span>{translate("forDesignersTankCalculation")}</span></Link>
                </div>
            </div>

            <div className='main-content'>
                <div className='scroll'>
                    <PerfectScrollbar>
                        <h1>{translate("forDesignersTankCalculation")}</h1>
                        <p>{translate("extraData")}</p>
                        <Calculator/>
                    </PerfectScrollbar>
                </div>
            </div>

        </div>
    </div>
</div>
  )
}
