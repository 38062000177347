import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "../../translations/localization";
import { Link } from "react-router-dom";
import "./mstyles.scss";

class CalculationLinearDrainageMobile extends Component {
  render() {
    return (
      <div className="calculation-liner-drainage-m">
        <div className="container-m">
          <div className="content-m">
            <h1>
              <Link to="/specialized-materials-list" className="link-back">
                <span className="icon icon-arrow-left"></span>
                {translate("forDesignersSpecializedMaterials")}
              </Link>
              <span>{translate("forDesignersLinearDrainage")}</span>
            </h1>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(CalculationLinearDrainageMobile);
