export const SensorTypes = {
    SET_SENSOR_TAB: "sensor/SET_SENSOR_TAB",
    GET_SENSORS: "sensor/GET_SENSORS",
    GET_SENSORS_SUCCESS: "sensor/GET_SENSORS_SUCCESS",
  };
  
  export const setSensorTab = (payload) => ({
    type: SensorTypes.SET_SENSOR_TAB,
    payload
  });

  export const getSensors = (payload) => ({
    type: SensorTypes.GET_SENSORS,
    payload
  });
  
  export const getProductsSuccess = (payload) => ({
    type: SensorTypes.GET_SENSORS_SUCCESS,
    payload
  });