// import { transformSync } from "@babel/core";
import { 
  // StarterTypes, 
  ArticleTypes 
} from "../actions";
// import { translate } from "../translations/localization";

const initialState = {
  articles: [],
  page: 1,
  nextPage: null,
  hasNextPage: null,
  singleArticle: {}
};

const articles = (state = initialState, action) => {
  switch (action.type) {
    case ArticleTypes.GET_ARTICLES_SUCCESS:
      return { ...state, articles: action.payload.page === 1 ? action.payload.docs : [...state.articles, ...action.payload.docs], hasNextPage: action.payload.hasNextPage, nextPage: action.payload.nextPage, singleArticle: {} };
    case ArticleTypes.GET_SINGLE_ARTICLE_SUCCESS:
      return { ...state, singleArticle: action.payload };
    default:
      return state;
  }
};

export default articles;
