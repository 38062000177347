import React from 'react';
import './styles.scss';

export default function TableMobile({ cardElements }) {

  return (
    <div className='table-wrapper mobile'>
        {cardElements}
    </div>
  )
}
