import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReferencesDesktopNew from './ReferencesDesktopNew'
import ReferencesMobileNew from './ReferencesMobileNew'
import { withGetScreen } from 'react-getscreen'

class References extends Component {
  render() {
    if (this.props.isMobile()) {
      return <ReferencesMobileNew />;
    } else {
      return <ReferencesDesktopNew />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(References, options))