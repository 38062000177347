import React from "react";
import Slider from "react-slick";
import { Utilities } from "../../utilities";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleReference } from "../../actions";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import Lottie from "react-lottie";
import animation from "../../LottieAnimations/HydroPro-loading-inbutton.json";

export default function ReferenceDesktopNew() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const reference = useSelector((state) => state.references.singleReference);

  const [isImageOpen, setIsImageOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const [loaded, setLoaded] = useState(false)

  const settings = {
    dots: false,
    speed: 800,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    // slidesToShow: reference?.photos?.length <= 5 ? reference?.photos?.length : 5,
    // slidesToScroll: reference?.photos?.length > 5 ? (reference?.photos?.length / 5) : 0,
    // slidesToShow: reference?.photos || 0,
    // slidesToScroll: reference?.photos || 0,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleImageLoad = (index, length) => {
    if (index === length - 1) setLoaded(true)
  };

  const openedGalleryThumbnails = reference?.photos?.map((url, index) => {
    return (
      <div
        key={`${index}-${url}`}
        onClick={() => setSelectedImage(url)}
      >
        <div className={index === selectedThumbnail ? "thumb thumb-selected" : "thumb"}>
          <div
            id={index + 1}
            className="single-img"
            onClick={() => setSelectedThumbnail(index)}
          >
            <img src={url} alt="reference image" />
          </div>
        </div>
      </div>
    );
  });

  useEffect(() => {
    dispatch(getSingleReference({ _id: id }));
  }, [dispatch, id]);

  return (
    <div className="reference page-bg" id="reference-page">
      <div className="container">
        <div className="top-content">
          <div className="breadcrumbs" onClick={() => history.goBack()}>
            <img
              src={require("../../assets/icons/arrow-back-white.svg")}
              alt="<"
              className="arrow-icon"
            />
            <span className="top-text uppercase">
              {Utilities.translateLanguage(reference?.title)}
            </span>
          </div>
        </div>

        <div className="main-content">
          <div className="gallery-photos">
            <div className="thumbnails-wrapper">
              {reference?.photos?.length <= 3 &&
                reference?.photos?.map((el, i) => {
                  return (
                    <div className={`single-img-wrapper ${i !== 2 ? 'half-height' : ''}`} key={`${i}-${el}`}>
                      <div
                        className="img"
                        style={{ backgroundImage: "url(" + el + ")" }}
                      >
                        <img src={el} alt="ref image" onLoad={() => handleImageLoad(i, reference?.photos?.length)} />
                      </div>
                    </div>
                  );
                })}

              {reference?.photos?.length > 3 &&
                reference?.photos?.slice(0, 4)?.map((el, i) => {
                  return (
                    <div className="single-img-wrapper half-height" key={`${i}-${el}-${11 + i}`}>
                      <div className="img">
                        <img src={el} alt="ref image" onLoad={() => handleImageLoad(i, 4)} />
                      </div>
                    </div>
                  );
                })}
            </div>

            {!loaded && (<Lottie options={defaultOptions} height={50} width={50} />)}

            <img
              src={require("../../assets/icons/open-gallery.svg")}
              className="icon-open-gallery"
              onClick={() => {
                setIsImageOpen(true)
                setSelectedThumbnail(0)
                setSelectedImage(reference?.photos?.[0])
                // setSelectedImage(reference?.coverPhoto)
              }}
              alt="open gallery"
            />
          </div>

          <div className="info-container">
            <div className="box-p">
              <h1>{translate("project")}</h1>
              <p className="regular">
                {Utilities.translateLanguage(reference?.project)}
              </p>
            </div>

            <div className="box-p">
              <h1>{translate("goal")}</h1>
              <p className="regular">
                {Utilities.translateLanguage(reference?.objective)}
              </p>
            </div>

            {reference?.products?.length ?
            <div className="box-p">
              <h1>{translate("headerProducts")}</h1>
              <div className="rows-wrapper">
                {reference?.products?.map((product, index) => {
                  return (
                    <div
                    key={`${product?.name}-${product?._id}-${index}`}
                      className="row"
                      onClick={() => window.open(`/product/${product?._id}`)}
                    >
                      <p className="medium">
                        {Utilities.translateLanguage(product?.name)}
                      </p>
                      <img
                        src={require("../../assets/icons/arrow.svg")}
                        alt=">"
                      />
                    </div>
                  );
                })}
              </div>
            </div> : ''}
          </div>
        </div>
      </div>

      {isImageOpen && (
        <div className="images-gallery-wrapper">
          <img
            src={require("../../assets/icons/close.svg")}
            className="close-icon"
            onClick={() => setIsImageOpen(false)}
            alt="close"
          />

          <div className="main-img">
            <img src={selectedImage || reference?.coverPhoto} alt="selected ref image" />
          </div>

          <div className="gallery-slider-thumbs">
            <Slider {...settings}>
            {/* <div key='cover-photo-ref' onClick={() => setSelectedImage(reference?.coverPhoto)}>
              <div className={selectedThumbnail === 0 ? "thumb thumb-selected" : "thumb"}>
                <div id='cover-0' className="single-img" onClick={() => setSelectedThumbnail(0)}>
                  <img src={reference?.coverPhoto} alt="reference image" />
                </div>
              </div>
            </div> */}
              {openedGalleryThumbnails}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
}
