import React, { Component } from 'react'
import { connect } from 'react-redux'
import SpecializedMaterialsListDesktopNew from './SpecializedMaterialsListDesktopNew'
import SpecializedMaterialsListMobileNew from './SpecializedMaterialsListMobileNew'
import { withGetScreen } from 'react-getscreen'

class SpecializedMaterialsList extends Component {
  render() {
    if (this.props.isMobile()) {
      return <SpecializedMaterialsListMobileNew />
    } else {
      return <SpecializedMaterialsListDesktopNew />
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(SpecializedMaterialsList, options))
