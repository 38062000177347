import React, { Component } from "react";
import { connect } from "react-redux";
import { FormContactUs, GMap } from "../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { translate } from "../../translations/localization";
import "./styles.scss";

class ContactsDesktop extends Component {
  render() {
    return (
      <div className="contacts page-bg">
        <div className="container">
          <div className="row">
            <div className="col col-form">
              <div className="content">
                <div className="scroll">
                  <PerfectScrollbar>
                    <FormContactUs />
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="col col-map">
              <GMap />
              {/* <div className='gmail-wrap'>
                  <a className='map-link' href='https://goo.gl/maps/96EiqJ81hVWbcnBeA' target="_blank" rel="noopener noreferrer">
                  <img src="/imgs/contacts-map.jpg" alt="Contacts-Map" />
                  </a>
                </div> */}
            </div>
          </div>
          <div className="row row-contacts-info">
            <div className="address">
              <span
                style={{ marginRight: 5 }}
                className="icon icon-pin-full"
              ></span>
              {translate("contactsAddress")}
            </div>
            <div className="col col-info">
              <span
                style={{ marginRight: 5 }}
                className="icon icon-phone"
              ></span>
              +359 2/99 280 16 +359 893 644 135
            </div>
            <div className="col col-info">
              <span
                className="icon icon-mail"
                style={{ marginRight: 5 }}
              ></span>
              office@hydropro.bg
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ContactsDesktop);
