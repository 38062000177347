import React, { useState, useRef } from "react";
import { translate } from "../../translations/localization";
import { history } from "../../config/stores";
import { Utilities } from "../../utilities";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getProducts, getReferences } from "../../actions";
import "./mstyles.scss";
import _ from "lodash";

export default function SearchResultsDesktop() {
  const dispatch = useDispatch();
  const resultsRef = useRef();

  const productsData = useSelector((state) => state.products.searchedProducts);
  const referenceData = useSelector((state) => state.references.searchedReferences);
  const query = useSelector((state) => state.general.query);
  const nextPageProducts = useSelector(state => state.products.nextPage);
  const nextPageReferences = useSelector(state => state.references.nextPage);

  const [activeTab, setActiveTab] = useState("Продукти");

  const productsList = productsData?.map((product) => {
    return (
      <div className="col solution-item product-container" key={product?._id}>
        <Link
          to={product?.pdfFile ? '/Products-list/' + product?.name?.en : "/product/" + product?._id}
          className={`item-link ${product?.pdfFile ? 'pdf' : ''}`}>
          <span className="img" onClick={() => {
              if (product?.pdfFile) {
                window.open(product.pdfFile);
              }
            }}>
            {product?.pdfFile && <img src={require('../../assets/icons/pdf-file-blue.svg')} alt='pdf' className='pdf-icon' /> }
            <img className="main-photo" src={product?.mainPhoto} alt="product" />
          </span>
          <span className="info">
            <h2 className="h3">{Utilities.translateLanguage(product?.name)}</h2>
          </span>
        </Link>
      </div>
    );
  });

  const referencesList = referenceData?.map((ref, i) => {
    return (
      <Link
      to={`/reference/${ref?._id}`}
      style={{ textDecoration: "none" }}
      className='reference-container'
      >
      <div className="reference reference-item">
      <div className="reference-text-container">
        <div className="flex">
          <p id="title" className="no-upper-case no-space">{Utilities.translateLanguage(ref?.title)}</p>
          <div className="icon-right" />
        </div>
        <p id="text" className="text overflow">{Utilities.translateLanguage(ref?.project)}</p>
      </div>
    </div>
    </Link>
    );
  });

  const loadPageOnScroll = () => {
    if (activeTab === "Продукти" && nextPageProducts) {
      let payload = { page: nextPageProducts, search: query }
      // dispatch(startLoading());
      dispatch(getProducts(payload)) 
    }
    else if (activeTab === "Референции" && nextPageReferences)
    if (nextPageReferences) {
      let payload = { page: nextPageReferences, search: query };
      // dispatch(startLoading());
      dispatch(getReferences(payload)); 
    }
  }

  const handleScrolling = () => {
    if (resultsRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = resultsRef.current;
      console.log(Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && ((activeTab === "Продукти" && nextPageProducts) || (activeTab === "Референции" && nextPageReferences)) && scrollTop !== 0)

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && ((activeTab === "Продукти" && nextPageProducts) || (activeTab === "Референции" && nextPageReferences)) && scrollTop !== 0) {
        loadPageOnScroll();
      }
    }
  };

  const delayedScrolling = _.debounce(() => {
    handleScrolling();
  }, 300);

  return (
    <div className="search-results-container-mobile">
      <div className="search-results-inner-mobile">
        <div className="search-results-inner-container">
          <div className="breadcrumbs" onClick={() => history.goBack()}>
            <img src={require("../../assets/icons/arrow-back-white.svg")} alt="<" className="arrow-icon" />
            <span className="title-container">{translate("searchResults")}</span>
          </div>
          <div className="tabs-container">
            <div
              className={`tab ${activeTab === "Продукти" ? "active" : ""}`}
              onClick={() => setActiveTab("Продукти")}
            >
              {translate("headerProducts")}
            </div>
            <div
              className={`tab ${activeTab === "Референции" ? "active" : ""}`}
              onClick={() => setActiveTab("Референции")}
            >
              {/* {`Референции (${referencesToDisplay.length})`} */}
              {translate("headerReferences")}
            </div>
          </div>

          <div className="main-content scroll-container" ref={resultsRef} onScroll={delayedScrolling}>
              {activeTab === "Продукти" ?
              <div className="products-container row row-items Products-m">{productsList}</div> :
              activeTab === "Референции" ?
              <div className="references-container references-m">{referencesList}</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
