import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setLanguage } from "../../actions";
import { translate } from "../../translations/localization.js";
import Cookies from "js-cookie";
import "./styles.scss";

class SpecializedMaterialsBttn extends Component {
  render() {
    return (
      <div className="flex-wrapper">
        <div className="specialized-materials-bttn">
          <Link
            to={
              Cookies.get("name")
                ? "/specialized-materials-list"
                : "/specialized-materials"
            }
          >
            {/* <Link to="/specialized-materials"> */}
           {translate("headerCalcButton")} <span className="icon icon-arrow-right"></span>
          </Link>
        </div>
        <div className="languages-container">
          <div
            className={`lang ${this.props.language === "en" ? "active" : ""}`}
            onClick={() => {
              this.props.setLanguage("en");
              window.location.reload();
            }}
          >
            EN
          </div>
          <div
            className={`lang ${this.props.language === "bg" ? "active" : ""}`}
            onClick={() => {
              this.props.setLanguage("bg");
              window.location.reload();
            }}
          >
            BG
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  contactDetails: state.user.contactDetails,
  language: state.language.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (payload) => dispatch(setLanguage(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecializedMaterialsBttn);
