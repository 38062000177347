import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProductDesktopNew from './ProductDesktopNew'
import ProductMobileNew from './ProductMobileNew'
import { withGetScreen } from 'react-getscreen'

class Product extends Component {
  render() {
    if (this.props.isMobile()) {
      return <ProductMobileNew />
    } else {
      return <ProductDesktopNew />
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Product, options))
