import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../translations/localization';
import './SpecializedMaterialsItems.scss'

class SpecializedMaterialsItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            materialsList: [
                { img: '/imgs/hyropro-specialized-materials-list-3.jpg', title: translate("forDesignersLinearDrainage"), info: translate("forDesignersLinearDrainageShortDescription"), link: '/calculation-linear-drainage' },
                { img: '/imgs/hyropro-specialized-materials-list-1.jpg', title: translate("forDesignersHydraulicSizing"), info: translate("forDesignersHydraulicSizingShortDescription"), link: '/calculation-hydraulic-sizing-tool' },
                { img: '/imgs/hyropro-specialized-materials-list-2.jpg', title: translate("forDesignersWallThickness"), info: translate("forDesignersWallThicknessShortDescription"), link: '/calculation-wall-thickness' }
            ]
        }
    }
    render() {
        const SpecializedMaterialsItems = this.state.materialsList.map((materialsList, index) =>
            <div className='item' id={'item-' + index} >
                <div className='photo'>
                    <img src={materialsList.img} alt={materialsList.title} />
                </div>
                <div className='info'>
                    <span className='info-name'>
                        {materialsList.title}
                    </span>
                </div>
            </div>
        )

        return (
            <div className='specialized-materials-items'>
                {SpecializedMaterialsItems}
            </div>
        )
    }
}

export default connect()(SpecializedMaterialsItems)
