import React, { Component } from 'react'
import { connect } from 'react-redux'
import CalculationHydraulicSizingToolDesktop from './CalculationHydraulicSizingToolDesktop'
import CalculationHydraulicSizingToolMobile from './CalculationHydraulicSizingToolMobile'
import { withGetScreen } from 'react-getscreen'

class CalculationHydraulicSizingTool extends Component {
  render() {
    if (this.props.isMobile()) {
      return <CalculationHydraulicSizingToolMobile />
    } else {
      return <CalculationHydraulicSizingToolDesktop />
    }
  }
}

const options = { mobileLimit: 1200, shouldListenOnResize: true }
export default connect()(withGetScreen(CalculationHydraulicSizingTool, options))
