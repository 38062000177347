export const GeneralTypes = {
    SET_TAB: "general/SET_TAB",
    SET_MODAL: "general/SET_MODAL",
    SEARCH_QUERY: "general/SEARCH_QUERY",
  };
  
  export const setTab = (payload) => ({
    type: GeneralTypes.SET_TAB,
    payload,
  });
  
  export const setModal = (payload) => ({
    type: GeneralTypes.SET_MODAL,
    payload,
  });
  
  export const searchQuery = (payload) => ({
    type: GeneralTypes.SEARCH_QUERY,
    payload,
  });
  