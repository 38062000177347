import React from 'react';
import { translate } from "../../translations/localization";
import { history } from "../../config/stores";
import { useRef, useEffect } from 'react';

export default function SensorsLoginStepOne({ phoneNumber, handleChange, setLoginStep }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef) inputRef.current.focus();
  }, []);

  return (
    <div className='inner-container step-one'>
    <div className='headers-wrapper'>
        <h1>{translate("loginHeadingClients")}</h1>
        <p>{translate("loginHeadingText")}</p>
    </div>
    <div className='input-container'>
      <label>{translate("sensorsLoginPhoneNumber")}</label>

      <div className='input-text-wrapper'>
        <span className='light'>+359 | &nbsp;</span>
        <input className='phone-input' value={phoneNumber || ''} onChange={handleChange} ref={inputRef} onKeyDown={(e) => e.key === 'Enter' && setLoginStep(2)} />
      </div>
    </div>

    <div className='bottom-wrapper'>
      <div className="button-wrapper">
         <button className="bttn" 
         disabled={phoneNumber?.length !== 9}
        onClick={() => {
          setLoginStep(2)
          // -> if phone number is invalid
          // setLoginStep(0) 
        }}
         >{translate("loginMoveOn")}</button>
      </div>

      <div className='bottom-text'>{translate("sensorsLoginTextContact")} <span className='underline' onClick={() => history.push('/about-us')}>{translate("sensorsLoginContactUs")}</span></div>
    </div>
    </div>
  )
}
