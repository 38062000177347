import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import starter from "./starter";
import user from "./user";
import products from "./products";
import calculator from "./calculator";
import search from "./search";
import language from "./language";
import general from "./general";
import references from "./references";
import articles from "./articles";
import sensors from "./sensors";

export default combineReducers({
  starter,
  products,
  routerReducer,
  user,
  calculator,
  search,
  language,
  general,
  references,
  articles,
  sensors
});
