import React from 'react';
import './styles.scss';
import { SensorsData } from '../../components';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSensorTab } from '../../actions';

export default function SensorsObject() {
  // const [ tab, setTab ] = useState('table')
  const dispatch = useDispatch();

  const tab = useSelector(state => state.sensors.tab)
  const sensorsData = useSelector(state => state.sensors.data);

  const [ currentTab, setCurrentTab ] = useState('')

  useEffect(() => {
    dispatch(setSensorTab('table'))

    // return dispatch(setSensorTab(''))
  }, []);

  useEffect(() => {
    setCurrentTab(tab)
  }, [tab]);

  return (
    <div className='sensors-object-wrapper'>
      <div className='container'>
        {currentTab === 'table' ? <SensorsData /> : <SensorsData sensorsHistory={true} />}
      </div>
    </div>
  )
}
