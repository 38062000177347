import { ofType, ActionsObservable } from "redux-observable";
import { CalculatorTypes, StarterTypes } from "../actions";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { API_URL } from "../config/settings";

export const calculationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(CalculatorTypes.POST_DATA_START),
    switchMap(({ payload }) => {
      return ajax({
        url: "https://hydro-pro-server.herokuapp.com/calculate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({
              type: CalculatorTypes.POST_DATA_SUCCESS,
              payload: response,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};

export const calculationNewEpic = (action$, state$) => {
  return action$.pipe(
    ofType(CalculatorTypes.POST_CALCULATION),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/calculation`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({
              type: CalculatorTypes.POST_CALCULATION_SUCCESS,
              payload: response?.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};

export const requestCode = (action$, state$) => {
  return action$.pipe(
    ofType(CalculatorTypes.REQUEST_CODE),
    switchMap(({ payload }) => {
      return ajax({
        url: "https://hydro-pro-server.herokuapp.com/code/request",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: CalculatorTypes.REQUEST_CODE_SUCCESS,
              payload: response.response,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );
};

export const sendCode = (action$, state$) => {
  return action$.pipe(
    ofType(CalculatorTypes.SEND_CODE),
    switchMap(({ payload }) => {
      return ajax({
        url: "https://hydro-pro-server.herokuapp.com/code/activate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: CalculatorTypes.SEND_CODE_SUCCESS,
              payload: response.success,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );
};
