import { StarterTypes } from "../actions";

const initialState = {
  loading: false,
};

const starter = (state = initialState, action) => {
  switch (action.type) {
    case StarterTypes.START_LOADING:
      return { ...state, loading: true };
    case StarterTypes.STOP_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default starter;
