import { LanguageTypes } from "../actions";
import { setLocale } from "../translations/localization";

let lang = localStorage.getItem("code");

const defaultState = {
  selectedLanguage: lang || "bg",
};

const language = (state = defaultState, action) => {
  switch (action.type) {
    case LanguageTypes.SET_LANGUAGE:
      setLocale(action.payload);
      localStorage.setItem("code", action.payload);
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    default:
      return state;
  }
};

export default language;
