import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "../../translations/localization.js";
import { setLanguage } from "../../actions";
import "./styles.scss";

class NavMain extends Component {
  render() {
    return (
      <div className="nav-main-wrap">
        <nav className="nav-main">
          <div className="items-l">
            <NavLink className="item" to="/Products/home">
              <span>{translate("headerProducts")}</span>
            </NavLink>
            <NavLink className="item" to="/solutions/urban">
              <span>{translate("headerSolutions")}</span>
            </NavLink>
            <NavLink className="item" to="/BIM">
              <span>BIM</span>
            </NavLink>
          </div>
          <div className="logo">
            <NavLink to="/">
              <img src="/hydropro.svg" alt="HydroPro" />
            </NavLink>
          </div>
          <div className="items-r">
            <NavLink className="item" to="/references">
              <span>{translate("headerReferences")}</span>
            </NavLink>
            <NavLink className="item" to="/about-us">
              <span>{translate("headerAboutUs")}</span>
            </NavLink>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (payload) => dispatch(setLanguage(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavMain));
