import React from "react";
import { Base64 } from "js-base64";
import { GMap } from "../../components";
import { history } from "../../config/stores";
import PerfectScrollbar from "react-perfect-scrollbar";
import { translate } from "../../translations/localization";
import { useState } from "react";
import "./styles.scss";

export default function ContactFormScreen() {
  const [payload, setPayload] = useState({
    today: new Date(),
    namefrom: "",
    emailfrom: "",
    message: "",
    company: "",
    phone: "",
    object: "",
    facilities: "",
    to: "hydroprobg1@gmail.com, team@fidweb.net",
    subject: "HydroPro - Контакти",
  });

  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  // let isDisabled = !(payload?.namefrom || payload?.emailfrom || payload?.facilities || payload?.company || payload?.phone || payload?.object)

  const onChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    setLoading(true);

    e.preventDefault();
    window
      .fetch("https://mailer.fidweb.net/mail/send/hydropro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic " +
            Base64.encode(
              "hydropro:P0div3h!!!ot!!T4z!!K4rantinaBEEEEE"
            ),
        },
        body: JSON.stringify({
          from: payload?.emailfrom,
          to: "hydroprobg1@gmail.com, team@fidweb.net",
          subject: payload?.subject,
          body: ` Изпратено от: ${payload?.namefrom} \n Email: ${payload?.emailfrom} \n Телефон за връзка: ${payload?.phone} \n Фирма: ${payload?.company} \n Обект: ${payload?.object} \n Съоръжения / Тръби: ${payload?.facilities} \n\n Съобщение: ${payload?.message}`,

          notifyBody: ` 
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>HydroPro</title>
          <style type="text/css">
            * {font-family: Georgia,Times,Times New Roman,serif;}
            #outlook a {padding: 0;}
            body {width: 100% !important; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; margin: 0; padding: 0; }
            img {outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;}
            a img {border: none; }
            h1, h2, h3, h4, h5, h6,
            h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
            h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
            h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {font-family: Georgia,Times,Times New Roman,serif; color: #201E18;}
            a {outline: none; text-decoration: none; color: #DFC475; }
            @media only screen and (max-width:600px) {
              *[class].full {width: 100% !important; overflow: hidden !important;}
              *[class].cen {margin: 0 auto !important; text-align: center !important;}
              *[class].img-full {display: block !important; width: 100% !important; height: auto !important;}
            }
          </style>
        </head>
        <body style="margin:0;padding:0;background-color:#ffffff;font-family:Georgia,Times,Times New Roman,serif;font-size:16px;">
          <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
            <tr>
              <td valign="top" bgcolor="#ffffff" align="center" width="100%">
                <table cellpadding="0" cellspacing="0" border="0" align="center" valign="top" width="600" class="full">
                  <tr>
                    <td valign="top">

                        <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="20" width="1" border="0" style="display:block;" /></td></tr></table>
        
                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                          <tr>
                            <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                            <td style="text-align:left;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">Контакти</td>
                            <td style="text-align:right;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">дата ${this.state.today}г.</td>
                            <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                          </tr>
                        </table>
        
                        <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
        
                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                          <tr><td align="center"><a href="/" target="_blank" style="text-decoration:none;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-header.jpg" alt="HydroPro" border="0" class="img-full" /></a></td></tr>
                        </table>
        
                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                          <tr>
                            <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                            <td style="text-align: left">
        
                            <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="45" width="1" border="0" style="display:block;" /></td></tr></table>

                              <h1 style="margin:0;padding:0;text-align:center;font-size:34px;line-height:24px;color:#201E18;font-weight:normal;font-family:Georgia,Times,Times New Roman,serif">Свързахте се с HydroPro</h1>

                              <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="25" width="1" border="0" style="display:block;" /></td></tr></table>

                              <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif">Вие изпратихте запитване към HydroPro:</p>
        
                              <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                <tr>
                                    <td><img src="https://hydropro.bg/email/pix.gif" width="20" height="1" /></td>
                                    <td align="left">
                                        <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                          Изпратено от: ${payload?.namefrom}<br/>
                                          Мейл: ${payload?.emailfrom}<br/>
                                          Телефон за връзка: ${payload?.phone}<br/>
                                          Фирма: ${payload?.company}<br/>
                                          Обект: ${payload?.object}<br/>
                                          Съоръжения / Тръби: ${payload?.facilities}<br/>
                                          Съобщение: ${payload?.message}
                                        </p>
                                    </td>
                                  </tr>
                              </table>
        
                              <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                Съвсем скоро ще отговорим на вашето запитване!<br/>
                                - HydroPro
                              </p>

                            </td>
                            <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                          </tr>
                        </table>

                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                            <tr>
                              <td style="text-align:center;">

                                <p style="text-align:center;font-size:12px;color:#DFC475;font-family:Georgia,Times,Times New Roman,serif;"><a href="/" target="_blank" style="text-decoration:none;font-size:12px;color:#DFC475;font-weight:bold;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-footer.png" alt="HydroPro" border="0" /></a></p>
        
                                <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
                                
                                <p style="text-align:center;font-size:12px;line-height:21px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                    <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Телефон</span>: +359 882 666 777<br/>
                                    <!--span style="text-transform:lowercase">Мейл:</span> скоро<br/ -->
                                    <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Адрес:</span> бул. „Цариградско шосе“ 319, 1582 ж.к. Дружба 2, София, България 
                                </p>
        
                                <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                    <a href="https://www.facebook.com/HydroPro-BG-108106688391858/?ref=pages_you_manage" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/facebook.jpg" alt="Facebook" /></a>
                                    &nbsp;&nbsp;
                                    <a href="https://www.instagram.com/" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/instagram.jpg" alt="Instagram" /></span></a>
                                </p>

                                <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">2020 © HYDROPRO. Всички права запазени.</p>
        
                              </td>
                            </tr>
                          </table>
        
                          <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="40" width="1" border="0" style="display:block;" /></td></tr></table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </body>
        </html>

        `,
          notifySubject: "HydroPro - Получено запитване",
        }),
      })
      .then((result) => {
        alert(translate("formSuccessMessage"));
        //console.log("RESULTS:", result)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false)
        setToggle(true)
    });
  };

  return (
    <div className="contact-form page-bg">
      <div className="container">
        <div className="top-content">
          <div className="breadcrumbs" onClick={() => history.goBack()}>
            <img
              src={require("../../assets/icons/arrow-back-white.svg")}
              alt="<"
              className="arrow-icon"
            />
            <span className="top-text">{translate("contactFormTitle")}</span>
          </div>
        </div>

        <div className="main-content">
          {!toggle ?
          <form
            id="form-contact-us"
            className="form-contact-us"
            onSubmit={onSubmit}
          >
            <div className="scroll">
              <PerfectScrollbar>
                <h1 className="form-title">{translate("contactFormQuestionProduct")}</h1>

                  <div className="row">
                    <div className="form-row">
                      <div className="label-wrapper">
                        <label className="label">{translate("nameLastname")}</label>
                        <span className="blue">*</span>
                      </div>
                      <input
                        className="input"
                        type="text"
                        name="namefrom"
                        id="namefrom"
                        value={payload?.namefrom}
                        onChange={onChange}
                      />
                    </div>

                    <div className="form-row">
                      <div className="label-wrapper">
                        <label className="label">{translate("company")}</label>
                        <span className="blue">*</span>
                      </div>
                      <input
                        className="input"
                        type="text"
                        name="company"
                        id="company"
                        value={payload?.company}
                        onChange={onChange}
                      />
                    </div>

                    <div className="form-row">
                      <div className="label-wrapper">
                        <label className="label">{translate("email")}</label>
                        <span className="blue">*</span>
                      </div>
                      <input
                        className="input"
                        type="text"
                        name="emailfrom"
                        id="emailfrom"
                        value={payload?.emailfrom}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-row">
                      <div className="label-wrapper">
                        <label className="label">{translate("phone")}</label>
                        <span className="blue">*</span>
                      </div>
                      <input
                        className="input"
                        type="text"
                        name="phone"
                        id="phone"
                        value={payload?.phone}
                        onChange={onChange}
                      />
                    </div>
                    <div className="form-row">
                      <label className="label">{translate("object")}</label>
                      <input
                        className="input"
                        type="text"
                        name="object"
                        id="object"
                        value={payload?.object}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-row full-width">
                      <div className="label-wrapper">
                        <label className="label">{translate("pipes")}</label>
                        <span className="blue">*</span>
                      </div>
                      <input
                        className="input"
                        type="text"
                        name="facilities"
                        id="facilities"
                        value={payload?.facilities}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-row full-width">
                      <label className="label">{translate("message")}</label>
                      <textarea
                        className="textarea"
                        name="message"
                        id="message"
                        value={payload?.message}
                        onChange={onChange}
                      ></textarea>
                    </div>
                  </div>
                  
               
              </PerfectScrollbar>
            </div>

            <div className="button-footer">
                <div className="checkbox-wrapper">
                    <input type="checkbox" />
                    <label>{translate("contactFormAgree")}<span className="underline">{translate("contactFormPolicy")}</span></label>
                </div>
              <div className="form-row-bttn">
                {payload?.namefrom &&
                payload?.emailfrom &&
                payload?.message &&
                payload?.phone ? (
                  <button type="submit" className="bttn" disabled={loading}>
                    {!loading && (
                      <span>
                        {translate("hydroproSend")}{" "}
                        <span className="icon icon-arrow-right"></span>
                      </span>
                    )}
                    {loading && <span>Съобщението се изпраща...</span>}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="bttn"
                    onClick={() => alert("Моля попълнете всички полета.")}
                  >
                    {translate("hydroproSend")}
                    <span className="icon icon-arrow-right"></span>
                  </button>
                )}
              </div>
            </div>
          </form> :
          <div className="image-wrapper-form">
            <img src={require("../../assets/icons/sent-query.svg")} alt=""/>
            <h3>Успешно изпратено запитване</h3>
            <p>Благодарим за изпратеното запитване! Ще се свържем с Вас при първа възможност.</p>
          </div>}

          <div className="contact-section-wrapper">
            <h1>{translate("contacts")}</h1>

            <div className="contacts-info-wrapper">
              <div className="row">
                <img src={require("../../assets/icons/phone-blue.svg")} className='icon'  alt='icon'/>
                <p>+359 2/99 280 16 +359 893 644 135</p>
              </div>
              <div className="row">
                <img src={require("../../assets/icons/mail-blue.svg")} className='icon'  alt='icon'/>
                <p>office@hydropro.bg</p>
              </div>
              <div className="row">
                <img src={require("../../assets/icons/location.svg")} className='icon' alt='icon' />
                <p>{translate("contactsAddress")}</p>
              </div>
            </div>

            <div className="map">
              <GMap />
            </div>

            <div className="contacts-info-wrapper margin end">
              <div className="row">
                <img src={require("../../assets/icons/linkedIn.svg")} className='icon' alt='icon'/>
                <p className="underline">HydroproBG</p>
              </div>
              <div className="row">
                <img src={require("../../assets/icons/facebook.svg")} className='icon' alt='icon'/>
                <p className="underline">Hydropro BG</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
