import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReferenceDesktopNew from './ReferenceDesktopNew'
import ReferenceMobileNew from './ReferenceMobileNew'
import { withGetScreen } from 'react-getscreen'

class Reference extends Component {
  render() {
    if (this.props.isMobile()) {
      return <ReferenceMobileNew  {...this.props}  />;
    } else {
      return <ReferenceDesktopNew {...this.props} />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Reference, options))