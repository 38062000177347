import { combineEpics } from "redux-observable";
//import * as starter from "./starter";
import * as calculator from "./calculator";
import * as products from "./products";
import * as references from "./references";
import * as articles from "./articles";

export const rootEpic = combineEpics(
  //starter.loadingEpic,
  calculator.calculationEpic,
  calculator.requestCode,
  calculator.sendCode,
  calculator.calculationNewEpic,
  products.getProductsEpic,
  products.getProductsByCategoryEpic,
  products.getSingleProductEpic,
  products.getCategoriesEpic,
  products.searchCategoriesEpic,
  products.searchCategoryEpic,
  references.getReferencesEpic,
  references.getSingleReferenceEpic,
  articles.getArticlesEpic,
  articles.getSingleArticleEpic
);
