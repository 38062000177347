import React from 'react';
import './styles.scss';
import _ from "lodash";
import { translate } from '../../translations/localization';
import { SensorsElements, SensorsHistoryElements, Table, TableMobile } from '../../components';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../config/stores';
import { setSensorTab } from '../../actions';
import moment from 'moment';
import { TableCardsSensors, TableCardsHistory } from '../../components';

export default function SensorsData({ sensorsHistory }) {
    const dispatch = useDispatch();
    const sensorsRef = useRef();
    const [selectedSortValue, setSelectedSortValue] = useState({});
    const [ width, setWidth ] = useState(0);

    // const sensorsTable = useSelector(state => state.sensors.data);
    const hasNextPage = useSelector(state => state.sensors.hasNextPage);
    const nextPage = useSelector(state => state.sensors.nextPage);

    const sensorsTable = [
        { number: '4349249209', details: 'Шахта 1 - Паркинг', levelSlime: '100', levelGrease: '25', battery: '90' },
        { number: '4345439208', details: 'Паркинг', levelSlime: '12', levelGrease: '27', battery: '97' },
        { number: '4349249276', details: 'Шахта 2', levelSlime: '15', levelGrease: '21', battery: '20' },
    ]

    const sensorsHistoryTable = [
        { date: moment(), levelSlime: '100', levelGrease: '25', battery: '90' },
        { date: moment(), levelSlime: '12', levelGrease: '27', battery: '97' },
        { date: moment(), levelSlime: '15', levelGrease: '21', battery: '20' },
    ]

    const tableHistoryHeaderElements = [
        {name: 'Дата и час', size: 'medium'},
        {name: 'Ниво кал', size: 'small'},
        {name: 'Ниво масло', size: 'small'},
        {name: 'Батерия', size: 'large'},
    ]

    const tableHeaderElements = [
        {name: 'Датчик номер', size: 'medium'},
        {name: 'Детайли', size: 'medium'},
        {name: 'Ниво кал', size: 'small'},
        {name: 'Ниво масло', size: 'small'},
        {name: 'Батерия', size: 'large'},
    ]

    let isMobile = width < 900;

    const sortValues = {
        'Датчик номер': 'number',
        'Детайли': 'details',
        'Ниво кал': 'levelSlime',
        'Ниво масло': 'levelGrease',
        'Батерия': 'battery'
    }

    const handleScrolling = () => {
        if (sensorsRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = sensorsRef.current;
    
          if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && hasNextPage && scrollTop !== 0) {
            loadPageOnScroll()
          }
        }
      }
    
      const delayedScrolling = _.debounce(() => {
        handleScrolling() 
      }, 300)
    
      const loadPageOnScroll = () => {
        // let payload = { pageNumber: nextPage }
    
        // if (selectedSortValue) {
        //   payload.sortBy = selectedSortValue
        // }
    
        // dispatch(getVMVReasonsAsync(payload))
      }

    const handleSort = (type, sortValue) => {
    }

    // const handleSort = (type, sortValue) => {
    //     let sortType = sortValues[type];
    //     let newSort = JSON.parse(JSON.stringify(selectedSortValue))
    
    //     if (!selectedSortValue[sortType]) {
    //       newSort = { [sortType]: 1 }
    //       setSelectedSortValue({ [sortType]: 1 })
    //     }
    //     else if (selectedSortValue[sortType]) {
    //       newSort = { [sortType]: selectedSortValue[sortType] === 1 ? -1 : 1 }
    //       setSelectedSortValue({ [sortType]: selectedSortValue[sortType] === 1 ? -1 : 1 })
    //     }
    
    //     let payload = { sortBy: newSort, pageNumber: 1 };
    //     let newTableHeaderElements = JSON.parse(JSON.stringify(tableHeaderElements))
    //     newTableHeaderElements = tableHeaderElements.map((el) => el.name === type ? {...el, sort: true} : {...el, sort: false} )
    //     setTableHeaderElements(newTableHeaderElements)
    
    //     vmvReasonsRef.current && vmvReasonsRef.current.scrollTo({top: 0, behavior: 'smooth'})
    //     dispatch(getVMVReasonsAsync(payload))
    // }

    useEffect(() => {
      window.addEventListener('resize', setWidth(window.innerWidth));
      isMobile = window.innerWidth < 900
  
      return () => window.removeEventListener('resize', setWidth(window.innerWidth));
    }, [window.innerWidth]);

  return (
    <div className='inner-container'>
      {!sensorsHistory ? 
      <>
        <div className="top-content" onClick={() => history.goBack()}>
          <div className="breadcrumbs">
            <img src={require("../../assets/icons/arrow-thic.svg")} alt="< go back" className="arrow-icon" />
            <span className="top-text bold margin">Пробива</span>

            <div className="top-text light">бул. Акад. Иван Евстатиев Гешов №2Д, гр. София</div>
          </div>
        </div>

        <h3 className='table-heading'>{translate("sensorsObjectTitle")}</h3>

        {isMobile ?
        <TableMobile cardElements={<TableCardsSensors data={sensorsTable} headerElements={tableHeaderElements} />} /> :
        <Table headerElements={tableHeaderElements} rowElements={<SensorsElements data={sensorsTable} />} onScroll={delayedScrolling} scrollRef={sensorsRef} handleSort={handleSort} />}
      </> :
      <>
        <div className="top-content">
          <div className="breadcrumbs" onClick={() => dispatch(setSensorTab('table'))}>
            <img src={require("../../assets/icons/arrow-thic.svg")} alt="< go back" className="arrow-icon" />
            <span className="top-text bold margin">4349249209</span>
            <span className="top-text light">Шахта 1 - Паркинг</span>
          </div>
        </div>

        <h3 className='table-heading'>{translate("sensorsObjectTableHistory")}</h3>

        {isMobile ?
        <TableMobile cardElements={<TableCardsHistory data={sensorsHistoryTable} headerElements={tableHistoryHeaderElements} />} /> :
        <Table headerElements={tableHistoryHeaderElements} rowElements={<SensorsHistoryElements data={sensorsHistoryTable} />} onScroll={delayedScrolling} scrollRef={sensorsRef} handleSort={handleSort} />}
      </>}
    </div>
  )
}
