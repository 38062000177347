import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { startLoading } from "../../actions";
import "./styles.scss";
import { translate } from "../../translations/localization";

class HomeDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsList: [
        {
          name: translate("homepageTreatmentPlant"),
          img: "/home/prechistvatelna-stancia.png",
          description: translate("homepageTreatmentPlantDescription"),
          link: "",
          pdf: false,
        },
        {
          name: translate("homepageHydrotank"),
          img: "/home/hydrotank.png",
          description: translate("homepageHydrotankDescription"),
          link: "/solution/tanks",
          pdf: false,
        },
        {
          name: translate("homepagePumpingStation"),
          img: "/home/pompena-stancia.png",
          description: translate("homepagePumpingStationDescription"),
          link: "",
          pdf: false,
        },
        // {
        //   name: translate("homepageBench"),
        //   img: "/home/bench.png",
        //   description: translate("homepageBenchDescription"),
        //   link: "",
        //   pdf: false,
        // },
        {},
        {
          name: translate("homepageCascadeShaft"),
          img: "/home/kaskadna.png",
          description: translate("homepageCascadeShaftDescription"),
          link: "/solution/shaft-cascading",
        },
        {
          name: translate("homepageHydroproLine"),
          img: "/home/hydroline.png",
          description: translate("homepageHydroproLineDescription"),
          link: "/solution/hydropro-line",
          pdf: false,
        },
        {
          name: translate("homepageMaxi150"),
          img: "/home/61540000-maxi-150-d400-r00045.png",
          description: translate("homepageMaxi150Description"),
          link:
            "",
          pdf: true,
        },
        {
          name: translate("homepageTreeProtectionSystem"),
          img: "/home/treeprotectors.png",
          description: translate("homepageTreeProtectionSystemDescription"),
          link: "/data/hydrotek-centralparts-bars/HYDROTEC-CATALOG-TREE.pdf",
          pdf: true,
        },
        {
          name: translate("homepageDsrsRevision"),
          img: "/home/dsrs-s-cap.png",
          description: translate("homepageDsrsRevisionDescription"),
          link: "/solution/outflowpoints-revision",
          pdf: false,
        },
        {
          name: translate("homepageHydrooilBypass"),
          img: "/home/hydrooil-bypass.png",
          description: translate("homepageHydrooilBypassDescription"),
          link: "/solution/hydrooil-bypass",
          pdf: false,
        },
        // {
        //   name: translate("homepageBin"),
        //   img: "/home/bin.png",
        //   description: translate("homepageBinDescription"),
        //   link:
        //     "https://hydropro.s3.eu-central-1.amazonaws.com/hydropro-website-data/hydrotek-centralparts-container/HYDROTEC-FAIL-1.pdf",
        //   pdf: true,
        // },
        {},
        {
          name: translate("homepageHydrotank"),
          img: "/home/hydrotank.png",
          description: translate("homepageHydrotankDescription"),
          link: "/solution/tanks",
          pdf: false,
        },
      ],
      prevSelectedId: "",
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  startLoading() {
    this.props.dispatch(startLoading());
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      event.srcElement.offsetParent === null ||
      event.srcElement.offsetParent.classList[0] !== "box-info"
    ) {
      if (this.state.prevSelectedId || this.state.prevSelectedId === 0) {
        document.getElementById(
          "box-dashed-" + this.state.prevSelectedId
        ).className = "box-lines";
        document.getElementById(
          "box-info-" + this.state.prevSelectedId
        ).className = "box-info";
      }
    }
  }

  productsToggle = (id) => {
    let boxPoint = document.getElementById("box-point-" + id);
    let boxInfo = document.getElementById("box-info-" + id);
    let boxDashed = document.getElementById("box-dashed-" + id);

    boxInfo.className = "box-info box-info-active";
    boxDashed.className = "box-dashed-active";

    let screenHeight = document.body.clientHeight;
    let bgHeight = document.getElementById("bg").offsetHeight;
    let pointPosition = boxPoint.offsetTop; // top space to 'bg' parent element
    let boxInfoPosition = boxInfo.getBoundingClientRect().top;

    let boxDashedHeight =
      boxInfoPosition - (pointPosition - (bgHeight - screenHeight) / 2) - 11;

    boxDashed.getElementsByClassName("box-dashed")[0].style.height =
      boxDashedHeight + 0 + "px";

    //hide previous selected elements
    let prevId = this.state.prevSelectedId;
    if (prevId !== "") {
      if (id !== prevId) {
        document.getElementById("box-info-" + prevId).className = "box-info";
        document.getElementById("box-dashed-" + prevId).className = "box-lines";
      }
    }
    this.setState({ prevSelectedId: id });
  };

  render() {
    const productsListPoints = this.state.productsList.map(
      (productsList, index) =>
        productsList.name && (
          <div key={index} id={"box-point-" + index}>
            <div
              className="box-point"
              onClick={() => this.productsToggle(index)}
            >
              <span className="point"></span>
            </div>
          </div>
        )
    );

    const productsListBoxInfo = this.state.productsList.map(
      (productsList, index) =>
        productsList.name && (
          <div key={index} className="box-info" id={"box-info-" + index}>
            <div className="cell-info">
              <h1>{productsList.name}</h1>
              <p>
                {productsList.description}
                {/* {productsList.pdf && productsList.link ? (
                  <a
                    href={productsList.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translate("readMore")} &raquo;
                  </a>
                ) : productsList.link ? (
                  <Link to={productsList.link}>
                    {translate("readMore")} &raquo;
                  </Link>
                ) : null} */}
              </p>
            </div>
            <div className="cell-img">
              {productsList.pdf && productsList.link ? (
                <a
                  href={productsList.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={productsList.img} alt={productsList.name} />
                </a>
              ) : productsList.link ? (
                <Link to={productsList.link}>
                  <img src={productsList.img} alt={productsList.name} />
                </Link>
              ) : (
                <img src={productsList.img} alt={productsList.name} />
              )}
            </div>
          </div>
        )
    );

    const productsListBoxDashed = this.state.productsList.map(
      (productsList, index) => (
        <div key={index} id={"box-dashed-" + index} className="box-lines">
          <div className="box-dashed">
            <img src="/home/dot.svg" alt="home" />
          </div>
          {index === 8 ? (
            <div className="box-dashed box-dashed-2">
              <img src="/home/dot.svg" alt="home" />
            </div>
          ) : null}
        </div>
      )
    );
    let structuredJSON = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "HydroPro Ltd",
      image: "https://hydropro.bg/HydroPro-color.png",
      "@id": "",
      url: "https://hydropro.bg/",
      telephone: "+359 2/99 280 16",
      address: {
        "@type": "PostalAddress",
        streetAddress: "bul. Tsarigradsko shose 164",
        addressLocality: "Sofia",
        postalCode: "1138",
        addressCountry: "BG",
      },
    };
    return (
      <div className="home" id="products-list">
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(structuredJSON)}
        </script>
        <div className="bg" id="bg">
          {productsListPoints}
          <div id="box-point-8a">
            <div className="box-point" onClick={() => this.productsToggle("8")}>
              <span className="point"></span>
            </div>
          </div>

          {productsListBoxDashed}
          <img
            src="/home/bg@2x.jpg"
            alt="HYDROPRO"
            className="desktop bg-img"
          />
          <img src="/home/bg.jpg" alt="HYDROPRO" className="laptop bg-img" />
        </div>
        {productsListBoxInfo}
      </div>
    );
  }
}

export default connect()(HomeDesktop);
