const servers = {
    dev: "https://hydropro-website-backend-dev.herokuapp.com",
    prod: "https://hydropro-website-backend-2c196d291e0d.herokuapp.com"
};

// export const API_URL = process.env.REACT_APP_API_URL || servers.dev
export const API_URL = servers.prod

//DEV
// export const s3Credentials = {
//     bucketName: 'dev-fidweb',
//     dirName: 'hydropro-website',
//     region: 'eu-central-1',
//     accessKeyId: 'AKIA5L7PZ7W5LIVFASGG',
//     secretAccessKey: '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',
// }


// //PROD
export const s3Credentials = {
  bucketName: "hydropro",
  dirName: "hydropro-website",
  region: "eu-central-1",
  accessKeyId: "AKIA5L7PZ7W5MOIAMLOR",
  secretAccessKey: "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",
};