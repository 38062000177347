import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { setSearchQuery, setModal, searchQuery } from "../../actions";
import { history } from "../../config/stores";
import { useSelector, useDispatch } from "react-redux";
import { getProducts, getReferences } from "../../actions";
import { translate } from "../../translations/localization";
import "./styles.scss";
import { Utilities } from "../../utilities";

export default function SearchModal() {
  const dispatch = useDispatch();

  const productData = useSelector((state) => state.products.searchedProducts);
  const referenceData = useSelector((state) => state.references.searchedReferences);

  const [query, setQuery] = useState("");
  const [items, setItems] = useState([]);
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    setItems([...productData, ...referenceData]);
  }, [productData, referenceData]);

  useEffect(() => {
    dispatch(getProducts({ search: "" }));
    dispatch(getReferences({ search: "" }));
  }, []);

  return (
    <div className={`search-bar-container modal ${isClosed ? 'closed' : ''}`}>
      <div className="search-section-wrapper">
        <div
          className="close-icon desktop"
          onClick={() => {
            setItems([]);
            dispatch(setModal(false));
          }}
        />
        <div className="flex-container-new-search">
          <h5>{translate("searchTitle")}</h5>
          <input
            className="search-input"
            autoComplete="off"
            type="text"
            value={query}
            placeholder=""
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                history.push("/results");
                setSearchQuery(query);
                setQuery("");
                dispatch(setModal(false));
              }
            }}
            onChange={(event) => {
              setQuery(event.target.value);

              if (event.target.value === "") {
                setItems([]);
              } else {
                dispatch(searchQuery(event.target.value))
                dispatch(getProducts({ search: event.target.value }));
                dispatch(getReferences({ search: event.target.value }));
              }
            }}
          />
          <div className="search-icon-wrapper">
            <div
              className="search-icon"
              onClick={() => {
                setQuery("");
                dispatch(setModal(true));
              }}
            />
          </div>
          <div className="drop-down-menu">
            {items &&
              items?.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    id="item"
                    to={`${ item?.project ? `/reference/${item?._id}` : `/product/${item?._id}` }`}
                    onClick={() => {
                      dispatch(setModal(false));
                      setQuery("");
                      setItems([]);
                      dispatch(getProducts({ search: item?.name?.bg || item?.name?.en }));
                      dispatch(getReferences({  search: item?.name?.bg || item?.name?.en,}));
                    }}
                  >
                    <span>
                      {Utilities.translateLanguage(item?.name) || Utilities.translateLanguage(item?.title)}
                    </span>
                  </NavLink>
                );
              })}
          </div>
        </div>

        <div
          className="close-icon mobile"
          onClick={() => {
            setItems([]);
            setIsClosed(true)
            dispatch(setModal(false));
          }}
        />
      </div>
    </div>
  );
}
