// import { transformSync } from "@babel/core";
import { StarterTypes, SensorTypes } from "../actions";
import { translate } from "../translations/localization";

const initialState = {
  tab: 'table',
  sensors: [],
  searchedSensors: [],
  page: 1,
  nextPage: null,
  hasNextPage: null,
  singleSensor: {},
  sensorHistory: []
};

const sensors = (state = initialState, action) => {
  switch (action.type) {
    case SensorTypes.SET_SENSOR_TAB:
      return { ...state, tab: action.payload };
    case StarterTypes.START_LOADING:
      return { ...state };
    case StarterTypes.STOP_LOADING:
      return { ...state };
    case SensorTypes.GET_SENSORS_SUCCESS:
    //   return { ...state, sensors: action.payload.page === 1 ? action.payload.docs : [...state.products, ...action.payload.docs], searchedProducts: action.payload.page === 1 ? action.payload.docs : [...state.products, ...action.payload.docs], hasNextPage: action.payload.hasNextPage, nextPage: action.payload.nextPage, singleProduct: {} };
      return { ...state, sensors: action.payload?.page === 1 ? action.payload.docs : [...state.sensors, ...action.payload.docs] };
    default:
      return state;
  }
};

export default sensors;
