import React from 'react';
import { translate } from "../../translations/localization";
import { history } from "../../config/stores";
import { useState, useEffect, useRef } from 'react';

export default function SensorsLoginStepTwo({ codeNumbers, handleChangeCode, phoneNumber, setLoginStep }) {
  const [ code, setCode ] = useState(0);
  const [ isValid, setIsValid ] = useState(true);
  const [ codeSent, setCodeSent ] = useState(false);
  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefThree = useRef(null);
  const inputRefFour = useRef(null);

  let refMap = [ inputRefOne, inputRefTwo, inputRefThree, inputRefFour ]

  const buildCode = () => {
    let newLoginCode = ''
    newLoginCode = codeNumbers.reduce((acc, curr) => {
     return acc + curr
    }, '')

    setCode(newLoginCode)
  }

  const handleInputChange = (event) => {
    if (!event.target.value) return

    if (event.target === inputRefOne.current) {
      inputRefTwo.current.focus();
    }
    else if (event.target === inputRefTwo.current) {
      inputRefThree.current.focus();
    }
    else if (event.target === inputRefThree.current) {
      inputRefFour.current.focus();
    }
  };

  const checkCode = () => {
   let validCode = codeNumbers.filter(symbol => symbol !== '')
   if (validCode.length === codeNumbers.length) return false
  
   return true
  }

  useEffect(() => {
    if (inputRefOne) inputRefOne.current.focus();
  }, []);

  useEffect(() => {
    buildCode()
  }, [codeNumbers]);

  return (
    <div className='inner-container step-two'>
    <div className='headers-wrapper'>
        <div className='flex-heading'>
          <img src={require("../../assets/icons/arrow-thic.svg")} alt="< go back" className="arrow-icon" onClick={() => setLoginStep(1)} />
          <h1 className='flex-title'>{translate("loginHeadingClients")}</h1>
        </div>
        <p>{translate("loginHeadingTextStepTwo")}</p>
        <h2 className='bold'>{`+359${phoneNumber}`}</h2>
    </div>
    <div className='code-container'>
      {codeNumbers?.map((el, i) => {
        return <input className={`box ${!el ? 'empty' : ''} ${!isValid ? 'invalid' : ''}`} key={`box-code-${i}-${el}`} value={el} ref={refMap[i]}
          onChange={(e) => {
          handleChangeCode(e, i)
          handleInputChange(e)
        }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (checkCode()) {
                history.push('/sensors-objects')
                // setLoginStep(0)
              }
            }
        }} />
      })}
    </div>

    <div className='code-text-wrapper'>
      {isValid ?
      <div className='bottom-text'>{translate("sensorsTextCodeQuestion")} <span className='underline' onClick={() => setCodeSent(true)}>{translate("sensorsTextCodeSendAgain")}</span></div> :
      <div className='bottom-text'>{translate("sensorsInvalidCode")} <span className='underline' onClick={() => setCodeSent(true)}>{translate("sensorsTextCodeSendAgain")}</span></div>}
    </div>

    {codeSent && <p className='margin-top'>{translate("sensorsTextInvalidCode")}</p>}

    <div className='bottom-wrapper wide'>
      <div className="button-wrapper">
         <button
         className="bttn" 
         disabled={checkCode()}
         onClick={() => {
          history.push('/sensors-objects')
          // setIsValid(false)
          // setLoginStep(0)
        }}
         >{translate("hydroproTankCalculatorEntrance")}</button>
      </div>
    </div>
    </div>
  )
}
