import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../translations/localization'
import { SpecializedMaterialsForm, SpecializedMaterialsGallery } from './index'
import './styles.scss'

class SpecializedMaterials extends Component {

  render() {
    return (
      <div className='specialized-materials page-bg'>
        <div className='container-full'>

          <div className='content'>
            <div className='row'>
              <div className='col col-materials-gallery'>

                <div className='materials-gallery-box'>
                  <div>
                    <h1>{translate("forDesignersLogin")}</h1>
                  </div>
                  <div>
                    <SpecializedMaterialsGallery />
                  </div>
                </div>

              </div>
              <div className='col col-form-materials-contact'>

                <SpecializedMaterialsForm />

              </div>
            </div>
          </div>

        </div>

        {/* <NavSide /> */}

      </div>

    )
  }
}

export default connect()(SpecializedMaterials)
