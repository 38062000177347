import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Utilities } from "../../utilities";
import { history } from "../../config/stores";
import "./styles.scss";

class SolutionsListDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      categoryName: "",
      //categories: [],
      // categoryName: ''
    };
  }

  componentDidMount() {
    const products = Utilities.getProductsByCategory(
      this.props.products,
      this.props.match.params.name
    );
    const category = Utilities.getCategoryName(
      this.props.categories,
      this.props.match.params.name
    );

    this.setState({
      products,
      categoryName: category.categoryName,
    });
  }

  render() {
    const productsList = this.state.products.map((products, i) => (
      <div
        className="col"
        onClick={() => {
          if (products.pdfURL) {
            window.open(products.pdfURL);
          } else {
            history.push(`/solution/${products.id}`);
          }
        }}
      >
        <div className="item-link">
          {/* <Link
          to={products.pdf ? products.pdfURL : "/solution/" + products.id}
          target={products.pdf ? "_blank" : ""}
          className="item-link"
        > */}
          {/* <NavLink to={'/solution/' + products.id} className='item-link'> */}
          {/* to={products.pdf ? '/solution/' + products.id : products.pdfURL  }  target='_blank'  */}

          <span className="img">
            <img src={products.images[0]} alt="product" />
          </span>
          <span className="info">
            <h2 className="h3">{products.title}</h2>
            {/* {(products.categoriesNames === '') ? 'Няма добавени' : products.categoriesNames} */}
            <p>{products.shortdescription}</p>
          </span>
          {/* </Link> */}
        </div>
      </div>
    ));

    return (
      <div className="solutions-list page-bg">
        <div className="container">
          <div className="content">
            <div className="top-content">
              <div className="breadcrumbs">
                {/* <Link to='/solutions' className='link-back'> {this.state.categoryName}</Link> */}
                {(this.state.categoryName.includes("Градска")
                 || this.state.categoryName.includes("Urban")) ? (
                  <Link to="/solutions/urban" className="link-back">
                    {" "}
                    {this.state.categoryName}
                  </Link>
                ) : (this.state.categoryName.includes("Транспортна")
                   || this.state.categoryName.includes("Transport")) ? (
                  <Link to="/solutions/transport" className="link-back">
                    {" "}
                    {this.state.categoryName}
                  </Link>
                ) : (this.state.categoryName.includes("Търговски") 
                   || this.state.categoryName.includes("Trade")) ? (
                  <Link to="/solutions/shopping" className="link-back">
                    {" "}
                    {this.state.categoryName}
                  </Link>
                ) : (this.state.categoryName.includes("Индустриални")
                     || this.state.categoryName.includes("Industrial")) ? (
                  <Link to="/solutions/industrial" className="link-back">
                    {" "}
                    {this.state.categoryName}
                  </Link>
                ) : (this.state.categoryName.includes("Жилищно")
                    || this.state.categoryName.includes("Housing")) ? (
                  <Link to="/solutions/house" className="link-back">
                    {" "}
                    {this.state.categoryName}
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="main-content">
              <div className="scroll">
                <PerfectScrollbar>
                  <div className="row row-items">
                    {productsList === ""
                      ? "Няма добавени продукти"
                      : productsList}
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
        {/* <NavSide /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products.data,
  categories: state.products.dataCategories,
});

export default connect(mapStateToProps)(withRouter(SolutionsListDesktop));
