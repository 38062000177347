import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, HeaderSensors, SearchModal } from "../../components";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { MobileFooter } from "../MobileFooter";

import {
  Home,
  AboutUs,
  Sensors,
  SensorsObjects,
  SensorsObject,
  SpecializedMaterials,
  SpecializedMaterialsList,
  CalculationHydraulicSizingTool,
  CalculationLinearDrainage,
  CalculationWallThickness,
  Contacts,
  References,
  Reference,
  // SolutionsList,
  // Solution,
  CalculationTankVolume,
  Products,
  ProductsList,
  Product,
  SearchResults,
  CalculationLinearDrainagePDF,
  BIM,
  ContactFormScreen,
  OpenedNews
} from "../../screens";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
// Preferences,
class Routes extends Component {
  render() {
    return (
      <div className="wrapall" id="wrapall">
        <ScrollToTop />
        {(window.location.pathname !== "/calculation-linear-drainage-pdf" 
        && window.location.pathname !== "/sensors"
        && window.location.pathname !== "/sensors-object"
        && window.location.pathname !== "/sensors-objects") && (
          <Header />
        )}
        
        {window.location.pathname === "/sensors" && 
        <HeaderSensors login={false} />}
        {(window.location.pathname === "/sensors-objects"
        || window.location.pathname === "/sensors-object")
         && (
          <HeaderSensors login={true} />
        )}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/sensors" component={Sensors} />
          <Route path="/sensors-objects" component={SensorsObjects} />
          <Route path="/sensors-object" component={SensorsObject} />
          {/* <Route path="/solutions/:tab" component={Solutions} /> */}
          <Route path="/products/:tab" component={Products} />
          <Route path="/products-list/:name" component={ProductsList} />
          {/* <Route path="/solutions-list/:name" component={SolutionsList} /> */}
          {/* <Route path="/solution/:id" component={Solution} /> */}
          <Route path="/product/:id" component={Product} />
          <Route path="/BIM" component={BIM} />
          <Route
            path="/specialized-materials"
            component={SpecializedMaterials}
          />
          <Route
            path="/specialized-materials-list"
            component={SpecializedMaterialsList}
          />
          <Route
            path="/calculation-hydraulic-sizing-tool"
            component={CalculationHydraulicSizingTool}
          />
          <Route
            path="/calculation-linear-drainage"
            component={CalculationLinearDrainage}
          />
          <Route
            exact
            path="/calculation-linear-drainage-pdf"
            component={CalculationLinearDrainagePDF}
          />
          <Route
            path="/calculation-wall-thickness"
            component={CalculationWallThickness}
          />
          <Route
            path="/calculation-tank-volume"
            component={CalculationTankVolume}
          />
          <Route path="/references" component={References} />
          {/* <Route path="/reference/:name" component={Reference} /> */}
          <Route path="/reference/:id" component={Reference} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/contact/form" component={ContactFormScreen} />
          <Route path="/news/:id" component={OpenedNews} />
          <Route path="/results" component={SearchResults} />
        </Switch>
        {this?.props?.modal && <SearchModal />}
        {window.innerWidth < 900 && <MobileFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    loading: state.starter.loading,
    modal: state.general.modal,
   };
};

export default withRouter(connect(mapStateToProps)(Routes));
