import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../translations/localization';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';


class Step2 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code: ''
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value },
      () => { this.props.codePass(this.state.code = this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4) }
    );
  }
  
  render() {
    this.inputRef2 = React.createRef();
    this.inputRef3 = React.createRef();
    this.inputRef4 = React.createRef();
    if (this.props.currentStep !== 2) {
      return null
    }

    return (
      <div className='content-step step-2'>
        <div className='scroll'>
          <PerfectScrollbar>
            <h4>{`${translate("loginStep")} 2 / 2`}</h4>
            <h2>{translate("forDesignersConfirmationCode")}</h2>
            <p>{translate("forDesignersEnterCode")} <span>{this.props.phone}</span>:</p>
            <div className='form-row'>
              <div className='form-control'>
                <div className='row code'>
                  <div className='col'>
                    <input type='text' name='code1' ref='code1' value={this.state.code1} onChange={(e) => { this.inputRef2.current.focus(); this.onChange(e) }} className='input input-code' maxLength='1' required autoFocus />
                  </div>
                  <div className='col'>
                    <input type='text' name='code2' ref={this.inputRef2} value={this.state.code2} onChange={(e) => { this.inputRef3.current.focus(); this.onChange(e) }} className='input input-code' maxLength='1' required />
                  </div>
                  <div className='col'>
                    <input type='text' name='code3' ref={this.inputRef3} value={this.state.code3} onChange={(e) => { this.inputRef4.current.focus(); this.onChange(e) }} className='input input-code' maxLength='1' required />
                  </div>
                  <div className='col'>
                    <input type='text' name='code4' ref={this.inputRef4} value={this.state.code4} onChange={this.onChange} className='input input-code' maxLength='1' required />
                  </div>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>

    )
  }
}

export default connect()(Step2)
