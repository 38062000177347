import React, { useState, useRef, useEffect } from "react";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { translate } from "../../translations/localization";
import { postData, startLoading, postCalculation } from "../../actions";
import { GraphOne, GraphTwo, GraphThree } from "../../components";
import Lottie from "react-lottie";
import animation from "../../LottieAnimations/HydroPro-loading-inbutton.json";
import "./calculator2.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const gravel = 0.0135;

const dropdownOptions = [
  { value: 0.9, label: translate("hydroproLineCalculatorDropdownOptionRoof") },
  {
    value: 0.85,
    label:
      translate("hydroproLineCalculatorDropdownOptionDenseCoverings"),
  },
  {
    value: 0.5,
    label:
      translate("hydroproLineCalculatorDropdownOptionPavements"),
  },
  { value: 0.35, label: translate("hydroproLineCalculatorDropdownOptionCobblestone") },
  { value: 0.3, label: translate("hydroproLineCalculatorDropdownOptionMacadamPavements") },
  {
    value: 0.15,
    label: translate("hydroproLineCalculatorDropdownOptionUnpaidYards"),
  },
  {
    value: 0.1,
    label:
      translate("hydroproLineCalculatorDropdownOptionLawns"),
  },
];

function Calculator2({
  calculator,
  graph,
  postData,
  postCalculation,
  loading,
  startLoading,
  dataPipes,
}) {
  const [others, setOthers] = useState(false);
  const [object, setObject] = useState("");
  const [email, setEmail] = useState("");
  const [opened, setOpened] = useState(false);
  const [mailerLoading] = useState(false);
  const scrollDownRef = useRef(null);
  const [dropdownValue, setDropdownValue] = useState({
    label: "",
    value: null,
  });
  const [pipes, setPipes] = useState([
    "DN200",
    "DN300",
    "DN400",
    "DN500",
    "DN600",
    "DN700",
    "DN800",
    "DN900",
    "DN1000",
    "DN1100",
  ]);
  const [areas, setAreas] = useState([{ L: "", B1: "", B2: "" }]);
  const [slope, setSlope] = useState("");
  const [Q, setQ] = useState("");

  useEffect(() => {
    if (calculator?.length) {
      let el = document.getElementById("scroll-to");
      scrollDownRef.current = el;
    }
    if (scrollDownRef.current) {
      scrollDownRef.current.scrollIntoView({
        behaviour: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [scrollDownRef, calculator]);

  // console.log({
  //   graphData: graph,
  //   calculator: calculator,
  //   dataPipes: dataPipes,
  //   objectName: object,
  //   slope: slope,
  //   roughness: dropdownValue.value,
  //   rainQuantity: Q,
  //   areas: areas,
  // })

  return (
    <div className="calculator-container">
      {/* {modalOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="close-btn" />
            <h1>ИЗХОДНИ ДАННИ ЗА ОТВОДНЯВАНАТА ТЕРИТОРИЯ</h1>
            <p>Моля въведете число между 0 и 1</p>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder=""
                value={dropdownValue.value}
                onChange={(event) =>
                  setDropdownValue({
                    label: "Други",
                    value: parseInt(event.target.value),
                  })
                }
              />
            </div>
            <div className="modal-btn">Въведи данни</div>
          </div>
        </div>
      )} */}
      <div className="flex-container space-between">
        <div className="flex-container width45 padding space-between color">
          <div className="input-elements">
            <h2>{translate("hydroproLineCalculatorExternalObjectData")}</h2>
            <p>{translate("hydroproLineCalculatorObjectType")}</p>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder={translate("hydroproLineCalculatorInputPlaceholder")}
                value={object}
                onChange={(event) => setObject(event.target.value)}
              />
            </div>
          </div>
          <div className="input-elements">
            <h2>{translate("hydroproLineCalculatorExternalProductData")}</h2>
            <p>{translate("hydroproLineCalculatorSlope")}</p>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="0 m/m"
                value={slope.replace(".", ",")}
                onChange={(event) => setSlope(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex-container width45 padding space-between color">
          <div className="input-elements">
            <h2>{translate("hydroproLineCalculatorInputDimensionalRainData")}</h2>
            <p>Q [l/s/ha]</p>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder=""
                value={Q.replace(".", ",")}
                onChange={(event) => {
                  setQ(event.target.value);
                }}
              />
            </div>
          </div>
          {others ? (
            <div className="input-elements">
              <h2>{translate("hydroproLineCalculatorInputDrainedAreaData")}</h2>
              <p>[0-1]</p>
              <div className="flex-container">
                <div className="input-wrapper">
                  <input
                    type="text"
                    placeholder=""
                    value={
                      dropdownValue.value &&
                      dropdownValue.value.toString().replace(".", ",")
                    }
                    onChange={(event) =>
                      setDropdownValue({
                        label: "Други",
                        value: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="close-btn" onClick={() => setOthers(false)} />
              </div>
            </div>
          ) : (
            <div className="input-elements">
              <h2>{translate("hydroproLineCalculatorInputDrainedAreaData")}</h2>
              <p>[0-1]</p>
              <div
                className="dropdown-container"
                onClick={() => setOpened((prevOpened) => !prevOpened)}
              >
                <div className={`arrow ${opened ? "up" : "down"}`} />
                {dropdownValue.value || "Choose an option"}
                {opened && (
                  <div className="options-container">
                    {dropdownOptions?.map((option) => {
                      return (
                        <div
                          className="option"
                          onClick={() => {
                            setDropdownValue({
                              value: option.value,
                              label: option.label,
                            });
                          }}
                        >
                          {option.label}
                        </div>
                      );
                    })}
                    <div className="option" onClick={() => setOthers(true)}>
                      {translate("hydroproLineCalculatorDropdownOptionOthers")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex-container space-between align-top">
        <div className="areas-container">
          <h2>{translate("hydroproLineCalculatorInputDrainedAreaDrainage")}</h2>
          {areas?.map((area, index) => {
            return (
              <div
                className="flex-container space-between padding"
                key={`area-el-${index}`}
              >
                <div className="input-elements width30">
                  <p>{translate("hydroproLineCalculatorInputDrainedAreaDrainageLength")}</p>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder="0"
                      value={area.L}
                      onChange={(event) => {
                        let newAreas = areas.slice(0);
                        newAreas[index].L = event.target.value;
                        setAreas(newAreas);
                      }}
                    />
                  </div>
                </div>
                <div className="input-elements width30">
                  <p>{translate("hydroproLineCalculatorInputDrainedAreaDrainageInitialWidth")}</p>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder="0"
                      value={area.B1}
                      onChange={(event) => {
                        let newAreas = areas.slice(0);
                        newAreas[index].B1 = event.target.value;
                        setAreas(newAreas);
                      }}
                    />
                  </div>
                </div>
                <div className="input-elements width30">
                  <p>{translate("hydroproLineCalculatorInputDrainedAreaDrainageFinalWidth")}</p>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder="0"
                      value={area.B2}
                      onChange={(event) => {
                        let newAreas = areas.slice(0);
                        newAreas[index].B2 = event.target.value;
                        setAreas(newAreas);
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`remove-area-btn ${index === 0 && "hidden"}`}
                  onClick={() => {
                    if (index !== 0) {
                      let newAreas = areas.slice(0);
                      newAreas.splice(index, 1);
                      setAreas(newAreas);
                    }
                  }}
                />
              </div>
            );
          })}
          <div
            className="add-area-button"
            onClick={() => {
              let newAreas = areas.slice(0);
              newAreas.push({ L: "", B1: "", B2: "" });
              setAreas(newAreas);
            }}
          >
            {translate("hydroproLineCalculatorInputDrainedAreaDrainageAddArea")}
          </div>
        </div>
        <div className="pipes-container">
          <h2>{translate("hydroproLineCalculatorInputDiameters")}</h2>
          <span>
            {translate("hydroproLineCalculatorInputDiametersCheckDescription")}
          </span>
          <div className="flex-container space-between">
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN200");
                if (index === -1) {
                  newPipes.push("DN200");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN200") !== -1
                }
              />
              HYDROPRO Line - 200 <span className="checkmark"></span>
            </label>
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN300");
                if (index === -1) {
                  newPipes.push("DN300");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN300") !== -1
                }
              />
              HYDROPRO Line - 300 <span className="checkmark"></span>
            </label>
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN400");
                if (index === -1) {
                  newPipes.push("DN400");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN400") !== -1
                }
              />
              HYDROPRO Line - 400 <span className="checkmark"></span>
            </label>
          </div>
          <div className="flex-container space-between">
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN500");
                if (index === -1) {
                  newPipes.push("DN500");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN500") !== -1
                }
              />
              HYDROPRO Line - 500 <span className="checkmark"></span>
            </label>
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN600");
                if (index === -1) {
                  newPipes.push("DN600");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN600") !== -1
                }
              />
              HYDROPRO Line - 600 <span className="checkmark"></span>
            </label>
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN800");
                if (index === -1) {
                  newPipes.push("DN800");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN800") !== -1
                }
              />
              HYDROPRO Line - 800 <span className="checkmark"></span>
            </label>
          </div>
          <div className="flex-container space-between">
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN900");
                if (index === -1) {
                  newPipes.push("DN900");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN900") !== -1
                }
              />
              HYDROPRO Line - 900 <span className="checkmark"></span>
            </label>
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN1000");
                if (index === -1) {
                  newPipes.push("DN1000");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN1000") !== -1
                }
              />
              HYDROPRO Line - 1000 <span className="checkmark"></span>
            </label>
            <label
              onClick={() => {
                let newPipes = pipes.slice(0);
                let index = newPipes.findIndex((pipe) => pipe === "DN1100");
                if (index === -1) {
                  newPipes.push("DN1100");
                } else {
                  newPipes.splice(index, 1);
                }
                setPipes(newPipes);
              }}
            >
              <input
                className="checkmark"
                type="checkbox"
                defaultChecked={
                  pipes.findIndex((pipe) => pipe === "DN1100") !== -1
                }
              />
              HYDROPRO Line - 1100 <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="flex-container space-between align-center padding shadow">
        <div className="text-container">
          <h2>{translate("calculations")}</h2>
          <p>{translate("receiveCalculationsInstructions")}</p>

          <div className="input-wrapper">
              <input
                type="text"
                placeholder=''
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
        </div>
      <div
        // className="calc-button"
        className={`calc-button ${!(email?.length > 3 && email?.includes('@') && email?.includes('.') && email[email?.length - 1] !== '.') ? 'disabled' : ''}`}
        onClick={() => {
          let check = false;
          areas.forEach((area) => {
            if (area.B1 === "" || area.B2 === "" || area.L === "") {
              check = true;
            }
          });
          if (
            slope === "" ||
            Q === "" ||
            dropdownValue.value === null ||
            check
          ) {
            alert("Моля попълнете полетата");
          } else {
            let areaConfirmation = areas?.map((area) => {
              if (isNaN(area.B1) || isNaN(area.B2) || isNaN(area.L)) {
                return true;
              } else {
                return false;
              }
            });
            if (
              isNaN(parseInt(slope.replace(",", "."))) ||
              isNaN(parseInt(Q.replace(",", "."))) ||
              areaConfirmation[0]
            ) {
              alert("Моля въведете цифри!");
            } else {
              startLoading();
              const details = {
                areas: areas,
                pipesToCheck: pipes,
                slope: slope.replace(",", "."),
                gravel: gravel,
                q: Q.replace(",", "."),
                psi: dropdownValue.value.toString().replace(",", "."),
              };
              // postData(details);
              postCalculation(details);
            }
          }
        }}
      >
        {loading ? (
          <Lottie options={defaultOptions} height={50} width={50} />
        ) : (
          translate("hydroproLineCalculatorInputButtonCalculate")
        )}
      </div>
      </div>

      {calculator?.length !== 0 && (
        <div className="results-container">
          <h2>{translate("hydroproLineCalculatorHydraulicResults")}</h2>
          <div className="flex-container space-between">
            <div className="solutions-container">
              <h2>{translate("hydroproLineCalculatorInputButtonSolution")}</h2>
              <span>{translate("hydroproLineCalculatorResultSolutionSuggestions")}</span>
              <div className="results-table">
                <div className="results-header">
                  <div className="number">№</div>
                  <div className="product-type">{translate("hydroproLineCalculatorResultProductType")}</div>
                  <div className="length-result">{translate("hydroproLineCalculatorResultProductLength")}</div>
                </div>
                {calculator?.map((el, index) => {
                  return (
                    <div className="table-row" key={`table-row-${index}`}>
                      <div className="table-col small">{index + 1}</div>
                      <div className="table-col large">
                        HYDROPRO Line - {el.pipe}
                      </div>
                      <div className="table-col medium">{el.step}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="graph-one-container" id="scroll-to">
              <GraphOne
                calculator={calculator}
                graph={graph}
                dataPipes={dataPipes}
              />
            </div>
          </div>
          <div className="flex-container space-between">
            <div className="graph-two-container">
              <GraphTwo data={{ areas: areas }} />
            </div>
            <div className="graph-three-container">
              <GraphThree data={{ areas: areas }} />
            </div>
          </div>
          <div className="flex-container space-between">
            <div
              className="white-button"
              // onClick={(e) => {
              //   setMailerLoading(true);
              //   e.preventDefault();
              //   window
              //     .fetch("https://mailer.fidweb.net/mail/send/hydropro", {
              //       method: "POST",
              //       headers: {
              //         "Content-Type": "application/json",
              //         Authorization:
              //           "Basic " +
              //           Base64.encode(
              //             "hydropro" +
              //               ":" +
              //               "P0div3h!!!ot!!T4z!!K4rantinaBEEEEE"
              //           ),
              //       },
              //       body: JSON.stringify({
              //         from: Cookies.get("email"),
              //         to: "hydroprobg1@gmail.com, team@fidweb.net",
              //         subject: object,
              //         body: ` Изпратено от: ${Cookies.get(
              //           "name"
              //         )} \n Email: ${Cookies.get(
              //           "email"
              //         )} \n Телефон за връзка: ${Cookies.get(
              //           "phone"
              //         )} \n Фирма: ${Cookies.get(
              //           "company"
              //         )} \n Обект: ${object} \n`,

              //         notifyBody: ` 
              //     <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
              //     <html xmlns="http://www.w3.org/1999/xhtml">
              //     <head>
              //       <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              //       <title>HydroPro</title>
              //       <style type="text/css">
              //         * {font-family: Georgia,Times,Times New Roman,serif;}
              //         #outlook a {padding: 0;}
              //         body {width: 100% !important; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; margin: 0; padding: 0; }
              //         img {outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;}
              //         a img {border: none; }
              //         h1, h2, h3, h4, h5, h6,
              //         h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
              //         h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
              //         h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {font-family: Georgia,Times,Times New Roman,serif; color: #201E18;}
              //         a {outline: none; text-decoration: none; color: #DFC475; }
              //         @media only screen and (max-width:600px) {
              //           *[class].full {width: 100% !important; overflow: hidden !important;}
              //           *[class].cen {margin: 0 auto !important; text-align: center !important;}
              //           *[class].img-full {display: block !important; width: 100% !important; height: auto !important;}
              //         }
              //       </style>
              //     </head>
              //     <body style="margin:0;padding:0;background-color:#ffffff;font-family:Georgia,Times,Times New Roman,serif;font-size:16px;">
              //       <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
              //         <tr>
              //           <td valign="top" bgcolor="#ffffff" align="center" width="100%">
              //             <table cellpadding="0" cellspacing="0" border="0" align="center" valign="top" width="600" class="full">
              //               <tr>
              //                 <td valign="top">
          
              //                     <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="20" width="1" border="0" style="display:block;" /></td></tr></table>
                  
              //                     <table cellpadding="0" cellspacing="0" border="0" width="100%">
              //                       <tr>
              //                         <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
              //                         <td style="text-align:left;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">Контакти</td>
              //                         <td style="text-align:right;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">дата ${moment().format(
              //                           "DD.MM.YYYY"
              //                         )}г.</td>
              //                         <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
              //                       </tr>
              //                     </table>
                  
              //                     <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
                  
              //                     <table cellpadding="0" cellspacing="0" border="0" width="100%">
              //                       <tr><td align="center"><a href="/" target="_blank" style="text-decoration:none;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-header.jpg" alt="HydroPro" border="0" class="img-full" /></a></td></tr>
              //                     </table>
                  
              //                     <table cellpadding="0" cellspacing="0" border="0" width="100%">
              //                       <tr>
              //                         <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
              //                         <td style="text-align: left">
                  
              //                         <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="45" width="1" border="0" style="display:block;" /></td></tr></table>
          
              //                           <h1 style="margin:0;padding:0;text-align:center;font-size:34px;line-height:24px;color:#201E18;font-weight:normal;font-family:Georgia,Times,Times New Roman,serif">Свързахте се с HydroPro</h1>
          
              //                           <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="25" width="1" border="0" style="display:block;" /></td></tr></table>
          
              //                           <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif">Вие изпратихте запитване към HydroPro:</p>
                  
              //                           <table cellpadding="0" cellspacing="0" border="0" width="100%">
              //                             <tr>
              //                                 <td><img src="https://hydropro.bg/email/pix.gif" width="20" height="1" /></td>
              //                                 <td align="left">
              //                                     <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
              //                                       Изпратено от: ${Cookies.get(
              //                                         "name"
              //                                       )}<br/>
              //                                       Мейл: ${Cookies.get(
              //                                         "email"
              //                                       )}<br/>
              //                                       Телефон за връзка: ${Cookies.get(
              //                                         "phone"
              //                                       )}<br/>
              //                                       Фирма: ${Cookies.get(
              //                                         "company"
              //                                       )}<br/>
              //                                       Обект: ${object}<br/>
              //                                     </p>
              //                                 </td>
              //                               </tr>
              //                           </table>
                  
              //                           <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
              //                             Съвсем скоро ще отговорим на вашето запитване!<br/>
              //                             - HydroPro
              //                           </p>
          
              //                         </td>
              //                         <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
              //                       </tr>
              //                     </table>
          
              //                     <table cellpadding="0" cellspacing="0" border="0" width="100%">
              //                         <tr>
              //                           <td style="text-align:center;">
          
              //                             <p style="text-align:center;font-size:12px;color:#DFC475;font-family:Georgia,Times,Times New Roman,serif;"><a href="/" target="_blank" style="text-decoration:none;font-size:12px;color:#DFC475;font-weight:bold;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-footer.png" alt="HydroPro" border="0" /></a></p>
                  
              //                             <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
                                          
              //                             <p style="text-align:center;font-size:12px;line-height:21px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
              //                                 <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Телефон</span>: +359 882 666 777<br/>
              //                                 <!--span style="text-transform:lowercase">Мейл:</span> скоро<br/ -->
              //                                 <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Адрес:</span> бул. „Цариградско шосе“ 319, 1582 ж.к. Дружба 2, София, България
              //                             </p>
                  
              //                             <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
              //                                 <a href="https://www.facebook.com/HydroPro-BG-108106688391858/?ref=pages_you_manage" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/facebook.jpg" alt="Facebook" /></a>
              //                                 &nbsp;&nbsp;
              //                                 <a href="https://www.instagram.com/" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/instagram.jpg" alt="Instagram" /></span></a>
              //                             </p>
          
              //                             <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">2020 © HYDROPRO. Всички права запазени.</p>
                  
              //                           </td>
              //                         </tr>
              //                       </table>
                  
              //                       <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="40" width="1" border="0" style="display:block;" /></td></tr></table>
              //                 </td>
              //               </tr>
              //             </table>
              //           </td>
              //         </tr>
              //       </table>
              //     </body>
              //     </html>
              //     `,
              //         notifySubject: "HydroPro - Получено запитване",
              //       }),
              //     })
              //     .then((result) => {
              //       setMailerLoading(false);
              //       alert(translate("formSuccessMessage"));
              //     })
              //     .catch((error) => {
              //       setMailerLoading(false);
              //       console.log(error);
              //     });
              // }}
              onClick={() => {
                postCalculation({
                results: [],
                graph: [],
                dataPipes: {},
                // areas: areas,
                // pipesToCheck: pipes,
                slope: slope.replace(",", "."),
                gravel: gravel,
                // q: Q.replace(",", "."),
                // psi: dropdownValue.value.toString().replace(",", ".") 
              })
              //   postData({
              //   results: [],
              //   graph: [],
              //   dataPipes: {},
              //   // areas: areas,
              //   // pipesToCheck: pipes,
              //   slope: slope.replace(",", "."),
              //   gravel: gravel,
              //   // q: Q.replace(",", "."),
              //   // psi: dropdownValue.value.toString().replace(",", ".") 
              // })
                history.push('/contact/form')
              }}
            >
              {mailerLoading ? (
                <Lottie options={defaultOptions} height={50} width={50} />
              ) : (
                translate("contactButton")
              )}
            </div>
            <div
              className="transparent-button"
              onClick={() => {
                let data = {
                  graphData: graph,
                  calculator: calculator,
                  dataPipes: dataPipes,
                  objectName: object,
                  slope: slope,
                  roughness: dropdownValue.value,
                  rainQuantity: Q,
                  areas: areas,
                };

                console.log(data)

                sessionStorage.setItem(
                  "calculatorGraphData",
                  JSON.stringify(data)
                );
                window.open("/calculation-linear-drainage-pdf");
              }}
            >
              {translate("downloadPDF")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  contactDetails: state.user.contactDetails,
  calculator: state.calculator.data,
  graph: state.calculator.graph,
  loading: state.starter.loading,
  dataPipes: state.calculator.dataPipes,
});

const mapDispatchToProps = (dispatch) => ({
  postData: (payload) => dispatch(postData(payload)),
  postCalculation: (payload) => dispatch(postCalculation(payload)),
  startLoading: (payload) => dispatch(startLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calculator2);
