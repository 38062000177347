import React, { Component } from "react";
import { connect } from "react-redux";
import Chart from "./chart";
import { Base64 } from "js-base64";
import Select from "react-select";
import Cookies from "js-cookie";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./coefficients";
import "./calculator.scss";
import { translate } from "../../translations/localization";
import { coefficents } from "./coefficients";

class Calculator extends Component {
  constructor() {
    super();
    this.state = {
      today: new Date(),
      to: "hydroprobg1@gmail.com, team@fidweb.net",
      subject: "HydroPro - Калкулатор хидравлично оразмеряване",
      contactDetails: { name: null, email: null, phone: null, company: null },
      pipepragma: "",
      emailfrom: "",
      diameter: 0,
      roughness: 0.03,
      slope: 0,
      height: 100,
      speed: 0,
      quantity: 0,
      // chartHeight: 100
    };
  }
  componentDidMount() {
    if (this.state.contactDetails.name === null) {
      this.setState((prevState) => ({
        contactDetails: {
          ...prevState.contactDetails,
          name: Cookies.get("name"),
          email: Cookies.get("email"),
          phone: Cookies.get("phone"),
          company: Cookies.get("company"),
        },
      }));
    }
    this.setState({
      name: this.state.contactDetails.name,
      emailfrom: this.state.contactDetails.emailfrom,
      phone: this.state.contactDetails.phone,
      company: this.state.contactDetails.company,
    });
  }

  onChangeEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit(e) {
    this.setState({ loading: true });

    e.preventDefault();
    window
      .fetch("https://mailer.fidweb.net/mail/send/hydropro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic " +
            Base64.encode(
              "hydropro:P0div3h!!!ot!!T4z!!K4rantinaBEEEEE"
            ),
        },
        body: JSON.stringify({
          from: this.state.emailfrom,
          to: this.state.to,
          subject: this.state.subject,
          body: `Име: ${this.state.contactDetails.name} \n  Телефон: ${this.state.contactDetails.phone} \n Фирма: ${this.state.contactDetails.company} \n Email: ${this.state.contactDetails.email} \n Наклон: ${this.state.slope} \n Грапавина: ${this.state.roughness} \n Диаметър: ${this.state.emailfrom} \n Тръба прагма: ${this.state.pipepragma} \n  Последно Съобщение: ${this.state.lastmessage} \n `,
          notifyBody: ` 
             <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
             <html xmlns="http://www.w3.org/1999/xhtml">
             <head>
               <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
               <meta name="viewport" content="width=device-width, initial-scale=1.0" />
               <title>HydroPro</title>
               <style type="text/css">
                 * {font-family: Georgia,Times,Times New Roman,serif;}
                 #outlook a {padding: 0;}
                 body {width: 100% !important; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; margin: 0; padding: 0; }
                 img {outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;}
                 a img {border: none; }
                 h1, h2, h3, h4, h5, h6,
                 h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
                 h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
                 h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {font-family: Georgia,Times,Times New Roman,serif; color: #201E18;}
                 a {outline: none; text-decoration: none; color: #DFC475; }
                 @media only screen and (max-width:600px) {
                   *[class].full {width: 100% !important; overflow: hidden !important;}
                   *[class].cen {margin: 0 auto !important; text-align: center !important;}
                   *[class].img-full {display: block !important; width: 100% !important; height: auto !important;}
                 }
               </style>
             </head>
             <body style="margin:0;padding:0;background-color:#ffffff;font-family:Georgia,Times,Times New Roman,serif;font-size:16px;">
               <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
                 <tr>
                   <td valign="top" bgcolor="#ffffff" align="center" width="100%">
                     <table cellpadding="0" cellspacing="0" border="0" align="center" valign="top" width="600" class="full">
                       <tr>
                         <td valign="top">
     
                             <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="20" width="1" border="0" style="display:block;" /></td></tr></table>
             
                             <table cellpadding="0" cellspacing="0" border="0" width="100%">
                               <tr>
                                 <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                                 <td style="text-align:left;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">Контакти</td>
                                 <td style="text-align:right;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">дата ${this.state.today}г.</td>
                                 <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                               </tr>
                             </table>
             
                             <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
             
                             <table cellpadding="0" cellspacing="0" border="0" width="100%">
                               <tr><td align="center"><a href="/" target="_blank" style="text-decoration:none;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-header.jpg" alt="HydroPro" border="0" class="img-full" /></a></td></tr>
                             </table>
             
                             <table cellpadding="0" cellspacing="0" border="0" width="100%">
                               <tr>
                                 <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                                 <td style="text-align: left">
             
                                 <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="45" width="1" border="0" style="display:block;" /></td></tr></table>
     
                                   <h1 style="margin:0;padding:0;text-align:center;font-size:34px;line-height:24px;color:#201E18;font-weight:normal;font-family:Georgia,Times,Times New Roman,serif">Свързахте се с HydroPro</h1>
     
                                   <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="25" width="1" border="0" style="display:block;" /></td></tr></table>
     
                                   <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif">Вие изпратихте запитване към HydroPro:</p>
             
                                   <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                     <tr>
                                         <td><img src="https://hydropro.bg/email/pix.gif" width="20" height="1" /></td>
                                         <td align="left">
                                             <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                               Изпратено от:<br/>
                                               Бързина: ${this.state.emailfrom}<br/>
                                               Наклон: ${this.state.slope}<br/>
                                               Грапавина: ${this.state.roughness}<br/>
                                               Мейл: ${this.state.contactDetails.email}<br/>
                                               Име: ${this.state.contactDetails.name}<br/>
                                               Фирма: ${this.state.contactDetails.company}<br/>
                                               Телефон: ${this.state.contactDetails.phone}<br/>
                                               Бързина: ${this.state.emailfrom}<br/>
                                               Последно Съобщение: 
                                             </p>
                                         </td>
                                       </tr>
                                   </table>
             
                                   <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                     Съвсем скоро ще отговорим на вашето запитване!<br/>
                                     - HydroPro
                                   </p>
     
                                 </td>
                                 <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                               </tr>
                             </table>
     
                             <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                 <tr>
                                   <td style="text-align:center;">
     
                                     <p style="text-align:center;font-size:12px;color:#DFC475;font-family:Georgia,Times,Times New Roman,serif;"><a href="/" target="_blank" style="text-decoration:none;font-size:12px;color:#DFC475;font-weight:bold;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-footer.png" alt="HydroPro" border="0" /></a></p>
             
                                     <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
                                     
                                     <p style="text-align:center;font-size:12px;line-height:21px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                         <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Телефон</span>: +359 882 666 777<br/>
                                         <!--span style="text-transform:lowercase">Мейл:</span> скоро<br/ -->
                                         <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Адрес:</span> бул. „Цариградско шосе“ 319, 1582 ж.к. Дружба 2, София, България
                                     </p>
             
                                     <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                         <a href="https://www.facebook.com/HydroPro-BG-108106688391858/?ref=pages_you_manage" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/facebook.jpg" alt="Facebook" /></a>
                                         &nbsp;&nbsp;
                                         <a href="https://www.instagram.com/" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/instagram.jpg" alt="Instagram" /></span></a>
                                     </p>
     
                                     <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">2020 © HYDROPRO. Всички права запазени.</p>
             
                                   </td>
                                 </tr>
                               </table>
             
                               <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="40" width="1" border="0" style="display:block;" /></td></tr></table>
                         </td>
                       </tr>
                     </table>
                   </td>
                 </tr>
               </table>
             </body>
             </html>
             `,
          notifySubject: "HydroPro - Получено запитване за продукта",
        }),
      })
      .then((result) => {
        alert(translate("formSuccessMessage"));

        //console.log("RESULTS:", result)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  }
  onChange(e) {
    // let value = e.target.value
    this.setState(
      { [e.target.name]: e.target.value.replace(/,/g, ".") },
      () => {
        let found = coefficents.find((c) => {
          let cat = c.height;
          if (cat >= this.state.height / 100) {
            return c.speed;
          } else {
            return 0
          }
        });
        //console.log(found)
        this.setState(
          {
            speed:
              Math.round(
                Math.pow(
                  (this.state.diameter / 1000) * this.state.slope * 2 * 9.81,
                  1 / 2
                ) *
                  (-2 *
                    Math.log10(
                      (2.51 * 0.00000131 * 1000) /
                        this.state.diameter /
                        Math.pow(
                          (this.state.diameter / 1000) *
                            this.state.slope *
                            2 *
                            9.81,
                          1 / 2
                        ) +
                        this.state.roughness / (this.state.diameter * 3.71)
                    )) *
                  found.speed *
                  100
              ) / 100,
            quantity:
              Math.round(
                ((Math.pow(
                  (this.state.diameter / 1000) * this.state.slope * 2 * 9.81,
                  1 / 2
                ) *
                  (-2 *
                    Math.log10(
                      (2.51 * 0.00000131 * 1000) /
                        this.state.diameter /
                        Math.pow(
                          (this.state.diameter / 1000) *
                            this.state.slope *
                            2 *
                            9.81,
                          1 / 2
                        ) +
                        this.state.roughness / (this.state.diameter * 3.71)
                    )) *
                  Math.PI *
                  this.state.diameter *
                  this.state.diameter) /
                  4000) *
                  found.quantity *
                  100
              ) / 100,
          },
          () => {
            console.log(this.state.quantity);
          }
        );
      }
    );
  }
  chartHeight(chartHeight) {
    this.setState({ height: (chartHeight / 300) * 100 });
  }
  render() {
    // console.log('details', this.state.contactDetails.name, this.state.contactDetails.email, this.state.contactDetails.phone, this.state.contactDetails.company)
    const pragma = [
      { value: 200, label: "ID 200" },
      { value: 300, label: "ID 300" },
      { value: 315, label: "ID 315" },
      { value: 360, label: "ID 360" },
      { value: 400, label: "ID 400" },
      { value: 500, label: "ID 500" },
      { value: 600, label: "ID 600" },
      { value: 700, label: "ID 700" },
      { value: 800, label: "ID 800" },
      { value: 900, label: "ID 900" },
      { value: 1000, label: "ID 1000" },
      { value: 1100, label: "ID 1100" },
      { value: 1200, label: "ID 1200" },
      { value: 1300, label: "ID 1300" },
      { value: 1400, label: "ID 1400" },
      { value: 1500, label: "ID 1500" },
      { value: 1600, label: "ID 1600" },
      { value: 1700, label: "ID 1700" },
      { value: 1800, label: "ID 1800" },
      { value: 1900, label: "ID 1900" },
      { value: 2000, label: "ID 2000" },
      { value: 2100, label: "ID 2100" },
      { value: 2200, label: "ID 2200" },
    ];
    // const slope = [
    //   { value: "1", label: "m/m" },
    //   // { value: '2', label: 'option2' },
    //   // { value: '3', label: 'option3' },
    // ];
    // const waterquantity = [
    //   { value: "1", label: "l/s" },
    //   // { value: '2', label: 'option2' },
    //   // { value: '3', label: 'option3' },
    // ];
    const customSelectStyles = {
      option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "#434141" : "transparent",
        color: state.isSelected ? "#ffffff" : "#ffffff",
        // padding: '5px 10px',
      }),
    };

    return (
      <div className="calculator-hydraulic-sizing">
        <form
          id="form-contact-us"
          className="form-contact-us"
          onSubmit={this.onSubmit.bind(this)}
        >
          <div id="s1" className="section">
            <h4>{translate("hydroproLineCalculatorHydraulicSizingInputParameters")}</h4>
            <div className="row">
              <div className="col">
                <h4>{translate("hydroproLineCalculatorHydraulicSizingCalculate")}</h4>
                <div className="radios-group">
                  <div>
                    <label className="radio" for="calculate-1">
                      <input
                        checked
                        type="radio"
                        name="calculate"
                        id="calculate-1"
                        value="Водно количество и спорост"
                      />
                      <span className="box">{translate("hydroproLineCalculatorHydraulicSizingWaterQuantity")}</span>
                    </label>
                  </div>
                  {/* <div>
                                    <label className='radio' for='calculate-2'>
                                        <input type='radio' name='calculate' id='calculate-2' value='Диаметър и скорост' />
                                        <span className='box'>Диаметър и скорост</span>
                                    </label>
                                </div> */}
                </div>
              </div>
              <div className="col col-pipe-data">
                <h4>{translate("hydroproLineCalculatorHydraulicSizingSpecData")}</h4>
                <div className="radios-group">
                  <div>
                    <div className="row row-cols-480px">
                      <div className="col col-30">
                        <label className="radio" for="pipes-data-1">
                          <input
                            type="radio"
                            checked
                            name="pipes-data"
                            id="pipes-data-1"
                            value="Тръба PRAGMA"
                          />
                          <span className="box">{translate("hydroproLineCalculatorHydraulicSizingSpec")}</span>
                        </label>
                      </div>
                      <div className="col">
                        <div className="select-box">
                          <Select
                            options={pragma}
                            className="react-select"
                            styles={customSelectStyles}
                            onChange={(selecedOption) =>
                              this.setState({ diameter: selecedOption.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="radios-group">
                    {/* <div>
                                        <label className='radio' for='pipes-data-2'>
                                            <input type='radio' name='pipes-data' id='pipes-data-2' value='Гладкостенна тръба' />
                                            <span className='box'>Гладкостенна тръба</span>
                                        </label>
                                    </div> */}
                    <div>
                      <div className="radios-group">
                        {/* <div className='row'>
                                                <div className='col col-30 sub-item'>
                                                    <label className='radio' for='smooth-walled-tube-1'>
                                                        <input type='radio' name='smooth-walled-tube' id='smooth-walled-tube-1' value='Вътрешен диаметър' />
                                                        <span className='box'>Вътрешен диаметър</span>
                                                    </label>
                                                </div>
                                                <div className='col'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <label className='label'>Du [mm]</label>
                                                            <div>
                                                                <input type='text' className='input' placeholder='0' name='' id='' />
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='label'>SDR</label>
                                                            <div>
                                                                <input type='text' className='input' placeholder='0' name='' id='' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div> */}
                        <div>
                          <div className="row  row-cols-480px">
                            <div className="col col-30 sub-item">
                              <label
                                className="radio"
                                form="smooth-walled-tube-2"
                              >
                                <input
                                  type="radio"
                                  name="smooth-walled-tube"
                                  id="smooth-walled-tube-2"
                                  value="Външен диаметър"
                                  checked
                                />
                                <span className="box">{translate("hydroproLineCalculatorHydraulicSizingInnerDiameter")}</span>
                              </label>
                            </div>
                            <div className="col">
                              <label className="label">Dn [mm]</label>
                              <div>
                                <input
                                  type="text"
                                  className="input"
                                  placeholder="0"
                                  onChange={this.onChange.bind(this)}
                                  name="diameter"
                                  value={this.state.diameter}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-top-space row-cols-480px">
                  <div className="col col-50">
                    <label className="label">{translate("hydroproLineCalculatorHydraulicSizingRoughness")}</label>
                    <div>
                      <input
                        type="text"
                        className="input"
                        placeholder="Второстепенна канализация - 0.4"
                        name="roughness"
                        onInput={this.onChange.bind(this)}
                        value={this.state.roughness}
                        id="roughness"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label className="label">{translate("hydroproLineCalculatorHydraulicSizingSlope")}</label>
                    <div className="row">
                      <div className="col">
                        <input
                          type="text"
                          className="input"
                          placeholder="Второстепенна канализация - 0.4"
                          onChange={this.onChange.bind(this)}
                          name="slope"
                          value={this.state.slope}
                          id="slope"
                        />
                      </div>
                      <div className="col">
                        <p>m/m</p>
                        {/* <div className="select-box">
                                                    <Select
                                                        options={slope}
                                                        className='react-select'
                                                        styles={customSelectStyles}
                                                    />
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="result" className="section section-result">
            <h4>
            {translate("hydroproLineCalculatorHydraulicResults")}
              <small>
              {translate("hydroproLineCalculatorHydraulicResultsDescription")}
              </small>
            </h4>
            <div className="row">
              <div className="col">
                <h5>{translate("hydroproLineCalculatorHydraulicResultsTakeIn")}</h5>
                <div className="row">
                  <div className="col">{translate("hydroproLineCalculatorHydraulicSizingInnerDiameter")}</div>
                  <div className="col">{this.state.diameter} mm</div>
                </div>
                <div className="row">
                  <div className="col">{translate("hydroproLineCalculatorHydraulicResultsRoughness")}</div>
                  <div className="col">{this.state.roughness} mm</div>
                </div>
                <div className="row row-bottom-space">
                  <div className="col">{translate("hydroproLineCalculatorHydraulicSizingSlope")}</div>
                  <div className="col">{this.state.slope} m/m</div>
                </div>

                <h5>{translate("hydroproLineCalculatorHydraulicResultsChosenValues")}</h5>
                <div className="row row-filling-height">
                  <div className="col">{translate("hydroproLineCalculatorHydraulicResultsFillingHeight")}</div>
                  <div className="col">
                    <input
                      type="number"
                      max="100"
                      className="input"
                      placeholder="0.0%"
                      name="height"
                      onChange={this.onChange.bind(this)}
                      value={Math.round(this.state.height)}
                      id=""
                    />
                  </div>
                </div>
                <div className="row row-water-quantity">
                  <div className="col">{translate("hydroproLineCalculatorHydraulicResultsWaterQuantity")}</div>
                  <div className="col">{this.state.quantity} l/s</div>
                  <div className="col">
                    {/* <div className="select-box">
                                            <Select
                                                options={waterquantity}
                                                className='react-select'
                                                styles={customSelectStyles}
                                            />
                                        </div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col">{translate("hydroproLineCalculatorHydraulicResultsVelocity")}</div>
                  <div className="col">{this.state.speed} m/s</div>
                </div>
              </div>
              <div className="col col-chart">
                <Chart
                  fillHeight={this.state.height + "%"}
                  chartHeight={this.chartHeight.bind(this)}
                  // onChange={this.onChange}
                />
              </div>
            </div>
            <button
              className="bttn"
              type="button"
              onClick={this.onChange.bind(this)}
            >
              {translate("hydroproLineCalculatorHydraulicSizingCalculate")}
            </button>
          </div>
          <div className="col">
            <h4>{translate("hydroproLineCalculatorHydraulicResultsLastMessage")}</h4>
            <div className="row">
              <div className="col">
                <div>
                  <textarea
                    type="text"
                    name="lastmessage"
                    id="lastmessage"
                    className="input"
                    placeholder={translate("hydroproLineCalculatorHydraulicResultsLastMessage")}
                    value={this.state.lastmessage}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row-bttn">
            <button className="bttn">{translate("formButton")}</button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  contactDetails: state.user.contactDetails,
});
export default connect(mapStateToProps)(Calculator);
