import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProductsDesktopNew from './ProductsDesktopNew'
import ProductsMobileNew from './ProductsMobileNew'
import { withGetScreen } from 'react-getscreen'

class Products extends Component {
  render() {
    if (this.props.isMobile()) {
      return <ProductsMobileNew />;
    } else {
      return <ProductsDesktopNew />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Products, options))