import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Utilities } from "../../utilities";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MakeAQueryBttn } from "../../components/MakeAQueryBttn";
import { translate } from "../../translations/localization";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";

class Solution extends Component {
  componentDidMount() {
    const product = Utilities.getProductById(
      this.props.products,
      this.props.match.params.id
    );

    this.setState(
      {
        nav1: this.slider1,
        nav2: this.slider2,
        categories: product.categories,
        id: product.id,
        title: product.title,
        solutions: product.solutions,
        description: product.description,
        quality: product.quality,
        efficiency: product.efficiency,
        application: product.application,
        techinfo: product.techinfo,
        drawings: product.drawings,
        installation: product.installation,
        images: product.images,
        product,
        table: product.table || null,
      },
      () => {
        console.log(this.state.title);
      }
    );
  }
  state = {
    product: null,
    id: null,
    nav1: null,
    nav2: null,
    activeTab: null,
    categories: null,
    title: null,
    solutions: null,
    description: null,
    quality: null,
    efficiency: null,
    application: null,
    techinfo: [],
    images: [],
    drawings: [],
    installation: "",
    renderPDF(techinfoLink) {

      // if (techinfoLink.toLowerCase().includes("dt-hydrotank")) {
      //   const techinfoArray = techinfoLink.slice(techinfoLink.lastIndexOf('/') + 1, techinfoLink.length - 4).split('-')
      //   const fileType = techinfoLink.slice(techinfoLink.length - 3)
      //   console.log(techinfoArray)
      //   const enStringLink = `https://hydropro.s3.eu-central-1.amazonaws.com/resources/${techinfoArray[0]}_HYDROtank - ${techinfoArray[2]}_EN.${fileType}`
      //   console.log(enStringLink)
      //   return enStringLink
      //   // https://hydropro.s3.eu-central-1.amazonaws.com/resources/DT_HYDROtank - 105m3_EN.pdf
      // }
    },

    // renderPDFDrawing(drawingsLink) {

    //   if (drawingsLink.toLowerCase().includes("hydrotank")) {
    //     const drawingsArray = drawingsLink.slice(drawingsLink.lastIndexOf('/') + 1, drawingsLink.length - 4).split('-')
    //     const fileType = drawingsLink.slice(drawingsLink.length - 3)
    //     console.log(drawingsArray)

    //     if (drawingsLink.includes('.dwg')) {
    //       const enStringLink = `https://hydropro.s3.eu-central-1.amazonaws.com/resources/${drawingsArray[0]}-${drawingsArray[1]}-${drawingsArray[2]}_EN.${fileType}`
    //       console.log(enStringLink)
    //       return enStringLink
    //     }
    //     else if (drawingsLink.includes('.pdf')) {
    //       const enStringLink = `https://hydropro.s3.eu-central-1.amazonaws.com/resources/${drawingsArray[0]} ${drawingsArray[1]} ${drawingsArray[2]}_EN.${fileType}`
    //       console.log(enStringLink)
    //       return enStringLink
    //     }
    //     // /data/tanks/drawings/HYDROtank-10-m3.pdf
    //     // https://hydropro.s3.eu-central-1.amazonaws.com/resources/HYDROtank V=10 m3_EN.pdf
    //   }
    // }
  };

  render() {
    // const techinfoList = this.state.techinfo.map((techinfo, i) => (
    //   <div key={i} className="techinfo-list">
    //     <a href={
    //         techinfo === "/data/tanks/techinfo/DT-HYDROtank-V=105m3.pdf"  &&
    //         this.props.selectedLanguage === "en"
    //         ? "https://hydropro.s3.eu-central-1.amazonaws.com/resources/HYDROtank V=105 m3_EN.pdf" 
    //         : techinfo.toLowerCase().includes('dt-hydrotank') && this.props.selectedLanguage === "en" ?
    //         this.state.renderPDF(techinfo)
    //         : techinfo
    //     }
    //       title="">
    //       {true && console.log(techinfo.toLowerCase().includes('dt-hydrotank'))}
    //       {techinfo.slice(techinfo.lastIndexOf("/") + 1)}
    //     </a>
    //   </div>
    // ));

    const drawingsList = this.state.drawings.map((drawings, j) => (
      <div
        key={j}
        className="drawings-list"
        style={j % 2 === 1 ? { marginBottom: "15px" } : null}
      >
        <a 
        // href={
        //     drawings === "/data/tanks/drawings/HYDROtank-10-m3.pdf"  &&
        //     this.props.selectedLanguage === "en"
        //     ? "https://hydropro.s3.eu-central-1.amazonaws.com/resources/HYDROtank V=10 m3_EN.pdf" 
        //     : drawings.toLowerCase().includes('hydrotank') && this.props.selectedLanguage === "en" ?
        //     this.state.renderPDFDrawing(drawings)
        //     : drawings
        // } 
        title="">
          {drawings.slice(drawings.lastIndexOf("/") + 1)}
        </a>
      </div>
    ));

    const imagesList = this.state.images.map((images, k) => (
      <div key={k}>
        <div
          className="product-img"
          style={{ backgroundImage: "url(" + images + ")" }}
        >
          <img src="/imgs/blank.gif" alt="blank" />
        </div>
      </div>
    ));
    let structuredJSON = {
      "@context": "https://schema.org/",
      "@type": "Product",
      brand: "Hydroil",
      name: this.state.title,
      description: this.state.description,
      image: [
        `https://hydropro.bg${this.state.images[0]}`,
        `https://hydropro.bg${this.state.images[1]}`,
        `https://hydropro.bg${this.state.images[2]}`,
      ],
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "0",
        ratingCount: "1",
      },
    };
    return (
      <div className="solution page-bg">
        <Helmet>
          <script className="structured-data-list" type="application/ld+json">
            {JSON.stringify(structuredJSON)}
          </script>
        </Helmet>
        <div className="container">
          <div className="top-content">
            <div
              className="breadcrumbs"
              onClick={() => this.props.history.goBack()}
            >
              <div className="link-back">
                <span className="opacity">{translate("headerSolutions")}</span>
              </div>
              {/* <span className='opacity'>Паркинги</span> */}
              <span className="opacity">{this.state.title}</span>
            </div>
          </div>
          <div className="content">
            <MakeAQueryBttn />

            <div className="row">
              <div className="col col-solution-text">
                <div className="tabs-list">
                  <Tabs>
                    <TabList>
                      <Tab>
                        <span tabname="Описание">
                          {translate(
                            "products-KMU-HydrooilBypassTabApplication"
                          )}
                        </span>
                      </Tab>
                      <Tab>
                        <span tabname="Монтаж">
                          {translate(
                            "products-KMU-HydrooilBypassTabInstallation"
                          )}
                        </span>
                      </Tab>
                      <Tab>
                        <span tabname="Техническа информация">
                          {translate(
                            "products-KMU-HydrooilBypassTabTechnicalInformation"
                          )}
                        </span>
                      </Tab>
                      <Tab>
                        <span tabname="Чертежи">
                          {translate("products-KMU-HydrooilBypassTabDrawings")}
                        </span>
                      </Tab>
                    </TabList>

                    <div className="scroll">
                      <PerfectScrollbar>
                        <TabPanel>
                          <div>
                            <div className="box-p">
                              <h1>
                                {translate(
                                  "products-KMU-HydrooilDuoDescription"
                                )}
                              </h1>
                              <p>{this.state.description}</p>
                            </div>
                            {this.state.table && (
                              <div className="scroll-container-x">
                                <div className="table-container">
                                  <div className="table-header">
                                    {this.state.table.columns.map((col) => {
                                      return (
                                        <div
                                          className="header-element"
                                          style={{
                                            minWidth: `${col.size}px`,
                                          }}
                                        >
                                          {col.name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                  {this.state.table.rows.map((row) => {
                                    return (
                                      <div className="table-row">
                                        {row.map((text, index) => {
                                          return (
                                            <div
                                              className="row-element"
                                              style={{
                                                minWidth: `${this.state.table.columns[index].size}px`,
                                              }}
                                            >
                                              {text}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                            <div className="box-p">
                              <h1>
                                {translate(
                                  "products-KMU-HydrooilDuoApplication"
                                )}
                              </h1>
                              <p>{this.state.application}</p>
                            </div>
                            <div className="box-p">
                              <h1>
                                {translate("products-KMU-HydrooilDuoQuality")}
                              </h1>
                              <p>{this.state.quality}</p>
                            </div>
                            {this.state.efficiency && (
                              <div className="box-p">
                                <h1>
                                  {translate(
                                    "products-KMU-HydrooilMiniЕfficiency"
                                  )}
                                </h1>
                                <p>{this.state.efficiency}</p>
                              </div>
                            )}
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div>{this.state.installation}</div>
                        </TabPanel>
                        <TabPanel>
                          <div style={{ display: "flex" }}>
                            <div style={{ marginRight: "50px" }}>
                              {/* {techinfoList} */}
                            </div>{" "}
                            {this.state.product && this.state.product.video ? (
                              <video
                                playsInline={false}
                                muted={true}
                                preload="auto"
                                loop={true}
                                autoPlay={true}
                                style={{
                                  maxWidth: "35%",
                                  minWidth: "35%",
                                  height: "auto",
                                  objectFit: "contain",
                                }}
                              >
                                <source
                                  src={this.state.product.video}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div>{drawingsList}</div>
                        </TabPanel>
                      </PerfectScrollbar>
                    </div>
                  </Tabs>
                </div>
              </div>
              <div className="col col-solution-gallery">
                <div className="product-gallery">
                  <div className="slider-main">
                    <Slider
                      asNavFor={this.state.nav2}
                      ref={(slider) => (this.slider1 = slider)}
                      arrows={false}
                    >
                      {imagesList}
                    </Slider>
                  </div>
                  <div className="slider-thumbs">
                    <Slider
                      asNavFor={this.state.nav1}
                      ref={(slider) => (this.slider2 = slider)}
                      slidesToShow={3}
                      swipeToSlide={true}
                      focusOnSelect={true}
                      arrows={true}
                    >
                      {imagesList}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products.data,
  selectedLanguage: state.language.selectedLanguage,
});

export default connect(mapStateToProps)(withRouter(Solution));
