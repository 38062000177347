import React from 'react';
import './styles.scss';
import { translate } from "../../translations/localization";
import { history } from "../../config/stores";
import { useRef, useEffect } from 'react';

export default function SensorsLoginFailed({ setLoginStep, phoneNumber }) {
  return (
    <div className='inner-container failed'>
      <div className='headers-wrapper'>
        <div className='flex-heading'>
          <img src={require("../../assets/icons/arrow-thic.svg")} alt="< go back" className="arrow-icon" onClick={() => setLoginStep(1)} />
          <h1 className='flex-title'>{translate("sensorsInvalidNumber")}</h1>
        </div>
        <p>{translate("sensorsInvalidNumberText")}</p>
      </div>

      <div className='image-container'>
        <img src={require("../../assets/icons/wrong-number.svg")} alt="invalid number" className="invalid-number-icon" />
        <h2 className='bold'>{`+359${phoneNumber}`}</h2>
      </div>

      <div className='bottom-wrapper small-margin'>
        <div className='bottom-text'>{translate("sensorsInvalidNumberTextContact")} <span className='underline' onClick={() => history.push('/about-us')}>{translate("sensorsLoginContactUs")}</span></div>
      </div>
    </div>
  )
}
