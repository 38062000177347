// import { useSelector } from "react-redux"

import { configureStore } from "../config/stores"

export const Utilities = {

  getCategoryName (categories, id) {
    const index = categories.findIndex(p => {
      return p.id === id
    })
    if (index !== -1) {
      return categories[index]
    }
    return null
  },

  getProductsByCategory (products, category) {
    const filteredProducts = products.filter(p => {
      return p.categories.indexOf(category) !== -1
    })
    return filteredProducts
  },

  getProductById (products, id) {
    const index = products.findIndex(p => {
      return p.id === id
    })
    if (index !== -1) {
      return products[index]
    }
    return null
  },

  checkSolutions(categories) {
    if (categories?.includes('urban-infrastructure-parkinglots') ||
    categories?.includes('urban-infrastructure-pedestrian-areas') ||
    categories?.includes('urban-infrastructure-streets') ||
    categories?.includes('urban-infrastructure-parks-and-gardens') ||
    categories?.includes('transport-infrastructure-highway') ||
    categories?.includes('transport-infrastructure-roads') ||
    categories?.includes('transport-infrastructure-bridge') ||
    categories?.includes('transport-infrastructure-tunnel') ||
    categories?.includes('shopping-areas-gas-stations-and-car-service') || 
    categories?.includes('shopping-areas-malls-and-shopping-centers') || 
    categories?.includes('shopping-areas-supermarkets') || 
    categories?.includes('industrial-and-logistics-areas-airport') ||
    categories?.includes('industrial-and-logistics-areas-dock')) {
      return true
    }
    return false
  },

  translateLanguage(languageObject) {
    // const test = configureStore.getState().language
    const language = localStorage.getItem('code') ?? 'bg'

    if (language === 'bg') {
      return languageObject?.bg
    }
    else if (language === 'en') {
      return languageObject?.en
    }
  },

  renderUrl(url) {
  const urlSplit = url.split('/');

  if (urlSplit?.length) {
    // const fileName = /[^/]*$/.exec(url)[0].slice(22).replace(/%20/g, ' ')
    let fileName = urlSplit.pop()?.slice(22) || ''
    let finalText = fileName.replace(/%20/g, ' ');
    let finalTextDecoded = decodeURI(finalText);

    return finalTextDecoded || ''
  }

    return '';
}
}
