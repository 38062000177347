import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { getProducts, searchCategories } from "../../actions";
import { NavLink, useParams } from "react-router-dom";
import { Footer } from "../../components";
import { Utilities } from "../../utilities";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import _ from "lodash";
import "./mstyles.scss";

export default function ProductsListMobileNew() {
  const { name } = useParams();
  const dispatch = useDispatch();
  const productsRef = useRef();

  const productsData = useSelector((state) => state.products.products);
  const hasNextPage = useSelector(state => state.products.hasNextPage);
  const nextPage = useSelector(state => state.products.nextPage);
  const category = useSelector((state) => state.products.category);
  const [categoryName, setCategoryName] = useState("");

  const handleScrolling = () => {
    if (productsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = productsRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && hasNextPage && scrollTop !== 0) {
        loadPageOnScroll()
      }
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  const loadPageOnScroll = () => {
    if (nextPage) {
      let payload = { page: nextPage }
      dispatch(getProducts(payload)) 
    }
  }

  useEffect(() => {
    if (name) {
      setCategoryName(name)
      dispatch(searchCategories({ search: name }))
      // dispatch(getProducts({ category: name }));
    }
  }, [name]);

  useEffect(() => {
    if (category?._id) {
      dispatch(getProducts({ category: [category?._id] }))
    }
  }, [category]);

  // useEffect(() => {
  //   if (productsData.length > 0) {
  //     setCategoryName(
  //       Utilities.translateLanguage(productsData[0]?.category?.name)
  //     );
  //   }
  // }, [productsData]);

  return (
    <div className="Products-list Products-list-m page-bg">
      <div className="container-m">
        <div className="content-m">
          <div
            className="top-wrapper"
            onClick={() => history.push("/Products/home")}
          >
            <img src={require("../../assets/icons/arrow-back-white.svg")} alt="go back"/>
            {/* <h1>{categoryName}</h1> */}
            <h1>{translate("headerProducts")} - {Utilities.translateLanguage(category?.name) || ""}</h1>
          </div>

          <div className="row row-items" ref={productsRef} onScroll={delayedScrolling}>
            {productsData
              ? productsData?.map((product, i) => (
                  <div className="col" key={`product-list-product-mobile-${i}`}>
                    <NavLink
                      to={
                        product?.pdfFile
                          ? "/Products-list/" + name
                          : "/product/" + product?._id
                      }
                      className="item-link"
                    >
                      <div
                        className="img"
                        onClick={() => {
                          if (product?.pdfFile) {
                            window.open(product?.pdfFile);
                          }
                        }}
                      >
                        {product?.pdfFile && (
                          <img
                            src={require("../../assets/icons/pdf-file-white.svg")}
                            alt="pdf"
                            className="pdf-icon"
                          />
                        )}
                        <img src={product?.mainPhoto} alt="product" />
                      </div>
                      <div className="info">
                        <h1 className="solution-list-name-m">
                          {Utilities.translateLanguage(product?.name)}
                        </h1>
                      </div>
                    </NavLink>
                  </div>
                ))
              : "Няма добавени продукти за тази категория"}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
