import React, { Component } from "react";
import { connect } from "react-redux";
import { NavMainMobile } from "../NavMain";
import { NavLink } from "react-router-dom";
import { SearchBar } from "../../components";
import "./mstyles.scss";

class HeaderMobile extends Component {
  render() {
    return (
      <header className="header-m" id="header">
        <div className="row">
          <div className="col col-logo">
            <NavLink className="logo" to="/">
              <img src="/hydropro.svg" alt="HydroPro" />
            </NavLink>
          </div>
          <SearchBar />
          <div className="col">
            <NavMainMobile />
          </div>
        </div>
      </header>
    );
  }
}

export default connect()(HeaderMobile);
