// import { transformSync } from "@babel/core";
import {
  //  StarterTypes, 
   ReferenceTypes 
  } from "../actions";
// import { translate } from "../translations/localization";

const initialState = {
  references: [],
  searchedReferences: [],
  categories: [],
  page: 1,
  nextPage: null,
  hasNextPage: null,
  singleReference: {}
};

const references = (state = initialState, action) => {
  switch (action.type) {
    case ReferenceTypes.GET_REFERENCES_SUCCESS:
      return { ...state, references: action.payload.page === 1 ? action.payload.docs : [...state.references, ...action.payload.docs], searchedReferences: action.payload.page === 1 ? action.payload.docs : [...state.references, ...action.payload.docs], hasNextPage: action.payload.hasNextPage, nextPage: action.payload.nextPage, singleReference: {} };
    case ReferenceTypes.GET_SINGLE_REFERENCE_SUCCESS:
      return { ...state, singleReference: action.payload, searchedReferences: [] };
    default:
      return state;
  }
};

export default references;
