import { ofType, ActionsObservable } from "redux-observable";
import { ArticleTypes, StarterTypes } from "../actions";
import { switchMap, catchError } from "rxjs/operators";

import { ajax } from "rxjs/ajax";
import { API_URL } from "../config/settings";

export const getArticlesEpic = (action$, state$) => {
    return action$.pipe(
      ofType(ArticleTypes.GET_ARTICLES),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/article/list`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: StarterTypes.STOP_LOADING,
              });
              obs.next({
                type: ArticleTypes.GET_ARTICLES_SUCCESS,
                payload: response.payload,
              });
              obs.complete();
            });
          }),
          catchError((err) => {
            alert("Грешка", "невалидни данни!");
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.next({
                type: StarterTypes.STOP_LOADING,
              });
              // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
              obs.complete();
            });
          })
        );
      })
    );
  };

export const getSingleArticleEpic = (action$, state$) => {
    return action$.pipe(
      ofType(ArticleTypes.GET_SINGLE_ARTICLE),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/article/${payload?._id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: StarterTypes.STOP_LOADING,
              });
              obs.next({
                type: ArticleTypes.GET_SINGLE_ARTICLE_SUCCESS,
                payload: response.payload,
              });
              obs.complete();
            });
          }),
          catchError((err) => {
            alert("Грешка", "невалидни данни!");
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.next({
                type: StarterTypes.STOP_LOADING,
              });
              // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
              obs.complete();
            });
          })
        );
      })
    );
  };
