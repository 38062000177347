import { ofType, ActionsObservable } from "redux-observable";
import { StarterTypes, ProductTypes } from "../actions";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { API_URL } from "../config/settings";

export const getProductsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ProductTypes.GET_PRODUCTS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/product/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({
              type: ProductTypes.GET_PRODUCTS_SUCCESS,
              payload: response.payload,
            });
            
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};

export const getProductsByCategoryEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ProductTypes.GET_PRODUCTS_BY_CATEGORY),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/product/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({
              type: ProductTypes.GET_PRODUCTS_BY_CATEGORY_SUCCESS,
              payload: response.payload
            });
            
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};

export const getSingleProductEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ProductTypes.GET_SINGLE_PRODUCT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/product/${ payload?._id }`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({
              type: ProductTypes.GET_SINGLE_PRODUCT_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};

export const getCategoriesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ProductTypes.GET_CATEGORIES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/category/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({
              type: ProductTypes.GET_CATEGORIES_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};

export const searchCategoriesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ProductTypes.SEARCH_CATEGORIES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/category/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({
              type: ProductTypes.SEARCH_CATEGORIES_SUCCESS,
              payload: { payload: response.payload, category: payload?.search },
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};

export const searchCategoryEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ProductTypes.SEARCH_CATEGORY),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/category/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            obs.next({
              type: ProductTypes.SEARCH_CATEGORY_SUCCESS,
              payload: { payload: response.payload, category: payload?.search },
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          alert("Грешка", "невалидни данни!");
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({
              type: StarterTypes.STOP_LOADING,
            });
            // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};