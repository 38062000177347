import React from 'react';
import moment from 'moment';
import './styles.scss';
import { translate } from '../../translations/localization';
import { setSensorTab } from "../../actions";
import { useDispatch } from "react-redux";

export default function TableCardsHistory({ data, headerElements }) {
    const dispatch = useDispatch();

  return (
    <>
    {data?.map((el, index) => {
        return (
        <div className='table-card-wrapper' key={`${el?.name}-${index}`}>
            <div className='table-row-wrapper'>
                <div className='element-title'>{headerElements[0]?.name}</div>
                <div className='element-content'>{moment(el?.date || '').format('DD MMMM | HH:mm') || "— —"}</div>
            </div>
        
            <div className='table-row-wrapper'>
                <div className='element-title'>{headerElements[1]?.name}</div>
                <div className={`element-content bold ${(el?.levelSlime <= 20 && el?.levelSlime) ? 'border' : ''}`}>{el?.levelSlime ? `${el?.levelSlime} %` : "— —"}</div>
            </div>
        
            <div className='table-row-wrapper'>
                <div className='element-title'>{headerElements[2]?.name}</div>
                <div className={`element-content bold ${(el?.levelGrease <= 20 && el?.levelGrease) ? 'border' : ''}`}>{el?.levelGrease ? `${el?.levelGrease} %` : "— —"}</div>
            </div>
        
            <div className='table-row-wrapper'>
                <div className='element-title'>{headerElements[3]?.name}</div>
                <div className={`element-content bold ${(el?.battery <= 20 && el?.battery) ? 'border' : ''}`}>{el?.battery ? `${el?.battery} %` : "— —"}</div>
            </div>
       </div>
        )
    })}
    </>
  )
}
