import React from "react";
import { Utilities } from "../../utilities";
import { Helmet } from "react-helmet";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MakeAQueryBttn } from "../../components/MakeAQueryBttn";
import { CustomTable } from "../../components";
import { translate } from "../../translations/localization";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";
import { getSingleProduct } from "../../actions";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { history } from "../../config/stores";
import Lottie from "react-lottie";
import animation from "../../LottieAnimations/HydroPro-loading-inbutton.json";

export default function ProductDesktopNew() {
  const { id } = useParams();
  const dispatch = useDispatch();

  // const products = useSelector((state) => state.products.data);
  const productData = useSelector((state) => state.products.singleProduct);
  const [loaded, setLoaded] = useState(false)
  // const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [ data,  ] = useState({
    nav1: '',
    nav2: '',
    categories: [],
    id: '',
    title: '',
    Products: [],
    description: '',
    quality: '',
    efficiency: '',
    application: '',
    techinfo: [],
    drawings: [],
    installation:'',
    images: [],
    product: '',
    table: null,
  });

  const techinfoList = Utilities.translateLanguage(productData.technicalInformation)?.map((techinfo, i) => (
    <div key={i} className="techinfo-list">
      <a
        href={techinfo}
        target="_blank"
        rel="noopener noreferrer"
        className="link"
        title=""
      >
        {Utilities?.renderUrl(techinfo) || techinfo || ''}
        {/* {techinfo?.name || techinfo} */}
      </a>
    </div>
  ));

  const drawingsList = Utilities.translateLanguage(productData.drawings)?.map((drawings, j) => (
    <div
      key={j}
      className="drawings-list"
      style={j % 2 === 1 ? { marginBottom: "15px" } : {}}
    >
      <a href={drawings} target="_blank" rel="noreferrer noopener"
       title="">
        {Utilities?.renderUrl(drawings) || drawings || ''}
        {/* {drawings?.name || drawings || ''} */}
       </a>
    </div>
  ));

  const handleImageLoad = (index, length) => {
    if (index === length - 1) setLoaded(true)
  };

  // let structuredJSON = {
  //   "@context": "https://schema.org/",
  //   "@type": "Product",
  //   brand: "Hydroil",
  //   name: data?.title,
  //   description: data?.description,
  //   image: [
  //     `https://hydropro.bg${data?.images[0]}`,
  //     `https://hydropro.bg${data?.images[1]}`,
  //     `https://hydropro.bg${data?.images[2]}`,
  //   ],
  //   aggregateRating: {
  //     "@type": "AggregateRating",
  //     ratingValue: "5",
  //     bestRating: "5",
  //     worstRating: "0",
  //     ratingCount: "1",
  //   },
  // };

  useEffect(() => {
    dispatch(getSingleProduct({ _id: id }))
  }, [dispatch, id]);

  return (
    <div className="Product page-bg">
      {/* <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(structuredJSON)}
        </script>
      </Helmet> */}
      <div className="container">
        <div className="top-content">
          <div className="breadcrumbs" onClick={() => history.goBack()}>
            <img src={require('../../assets/icons/arrow-back-white.svg')} alt='< go back' className="arrow-icon" />
            <span className="top-text uppercase">{Utilities.translateLanguage(productData?.name)}</span>
          </div>
        </div>
        <div className="content">
          <MakeAQueryBttn />
          <div className="row">
            <div className="col col-Product-text">
              <div className="tabs-list">
                <Tabs>
                  <TabList>
                    <Tab>
                      <span tabname="За продукта">
                        {translate("tabOptionAboutProduct")}
                      </span>
                    </Tab>
                    {(productData?.installation?.bg?.length
                      || productData?.installation?.en?.length
                      || productData?.technicalInformation?.bg?.length
                      || productData?.technicalInformation?.en?.length
                      || productData?.bimInformation?.bg?.length
                      || productData?.bimInformation?.en?.length
                      || productData?.bimFiles?.bg?.length
                      || productData?.bimFiles?.en?.length) ?
                    <Tab>
                      <span tabname="Техническа информация">
                        {translate( "products-KMU-HydrooilDuoTabTechnicalInformation")}
                      </span>
                    </Tab> : ''}
                    {(productData?.solutions?.bg?.[0]?.length || productData?.solutions?.en?.[0]?.length) ?
                    <Tab>
                      <span tabname="Решения">
                        {translate( "headerSolutions")}
                      </span>
                    </Tab> : ''}
                    {(productData?.mediaPhotos?.length || productData?.mediaVideos?.length || productData?.drawings?.bg?.length || productData?.drawings?.en?.length) ?
                    <Tab>
                      <span tabname="Медия и чертежи">
                        {translate("tabOptionMediaAndDrawings")}
                      </span>
                    </Tab> : ''}
                  </TabList>

                  <div className="scroll">
                    <PerfectScrollbar>
                      <TabPanel>
                        <div>
                          <div className="box-p">
                            <h1>
                              {translate("products-KMU-HydrooilBypassDescription")}
                            </h1>
                            <p>{Utilities.translateLanguage(productData?.description)}</p>
                            {/* <p>{data?.description}</p> */}
                          </div>

                          {/* specifications link version */}
                          {(productData?.specifications?.bg?.length
                          || productData?.specifications?.en?.length) ?
                          <div className="box-p">
                            <h1>
                              {translate("tabOptionSpecificationProduct")}
                            </h1>
                            <p className="link" onClick={() => window.open(Utilities.translateLanguage(productData?.specifications))}>{Utilities.translateLanguage(productData?.specifications)}</p>
                          </div> : <div/>}

                          {/* specifications table version */}
                          {(productData?.table?.bg?.length
                          || productData?.table?.en?.length) ?
                          <div className="box-p">
                            <h1>
                              {translate("tabOptionSpecificationProduct")}
                            </h1>

                            {(productData?.specifications?.bg?.length
                             || productData?.specifications?.en?.length) ?
                             <p className="link" onClick={() => window.open(Utilities.translateLanguage(productData?.specifications))}>{Utilities.translateLanguage(productData?.specifications)}</p>
                             : <div/>}
                             
                            <CustomTable data={Utilities.translateLanguage(productData?.table)} />
                          </div> : <div/>}

                          {Utilities.translateLanguage(productData?.appendix)?.length ?
                          <div className="box-p">
                            <h1>
                              {translate("products-KMU-HydrooilBypassApplication")}
                            </h1>
                            <p>{Utilities.translateLanguage(productData?.appendix)}</p>
                          </div> : ''}

                          {Utilities.translateLanguage(productData?.quality)?.length ? 
                          <div className="box-p">
                            <h1>
                              {translate("products-KMU-HydrooilBypassQuality")}
                            </h1>
                            <p>{Utilities.translateLanguage(productData?.quality)}</p>
                          </div> : ''}

                          {Utilities.translateLanguage(productData?.effectiveness)?.length ?
                          <div className="box-p">
                              <h1>
                                {translate("products-KMU-HydrooilBypassЕfficiency")}
                              </h1>
                              <p>{<p>{Utilities.translateLanguage(productData?.effectiveness)}</p>}</p>
                          </div> : ''}

                          {productData?.manufacturer === 'hydropro' &&
                          <div className="box-p">
                              <h1>{translate("producer")}</h1>
                              <img src={require("../../assets/icons/white-hydropro.svg")} className='logo-new' alt="logo"/>
                          </div>}
                        </div>
                      </TabPanel>

                      {(productData?.installation?.bg?.length
                      || productData?.installation?.en?.length
                      || productData?.technicalInformation?.bg?.length
                      || productData?.technicalInformation?.en?.length
                      || productData?.bimInformation?.bg?.length
                      || productData?.bimInformation?.en?.length
                      || productData?.bimFiles?.bg?.length
                      || productData?.bimFiles?.en?.length) ?
                      <TabPanel>
                        <div>
                          {Utilities?.translateLanguage(productData?.installation)?.length ?
                          <div className="box-p">
                            <h1>
                              {translate("products-KMU-HydrooilBypassTabInstallation")}
                            </h1>
                            <p>{Utilities?.translateLanguage(productData?.installation)}</p>
                          </div> : ''}

                          {Utilities.translateLanguage(productData?.technicalInformation)?.length ?
                          <div className="box-p">
                            <h1>
                              {translate("products-KMU-HydrooilDuoTabTechnicalInformation")}
                            </h1>
                            <div>{techinfoList}</div>
                          </div> : ''}

                          {(productData?.bimInformation?.bg?.length || productData?.bimInformation?.en?.length) ?
                          <div className="box-p">
                            <h1>Building Information Modeling – BIM</h1>
                            <p>{Utilities.translateLanguage(productData?.bimInformation)}</p>
                          </div> : ''}

                          {(productData?.bimFiles?.bg?.length || productData?.bimFiles?.en?.length) ?
                          <div className="box-p">
                            <h1>REVIT BIM FILES</h1>
                            <p>{Utilities.translateLanguage(productData?.bimFiles)?.map((file, i) => {
                              return (
                                <div key={`bim-file-${file}`} className="techinfo-list">
                                <a
                                  href={file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="link"
                                  title=""
                                >
                                  {Utilities?.renderUrl(file) || file || ''}
                                  {/* {file?.name || file || ''} */}
                                </a>
                              </div>
                              )
                            })}</p>
                          </div> : ''}
                        </div>
                      </TabPanel> : ''}

                      {/* {Utilities.translateLanguage(productData?.solutions) ? */}
                      {(productData?.solutions?.bg?.[0]?.length
                      || productData?.solutions?.en?.[0]?.length) ?
                      <TabPanel>
                        <div>
                          {Utilities.translateLanguage(productData?.solutions)?.map(solution => {
                            return (
                              <div className="box-p flex">
                                 <h1 className="border">{solution?.split(':')?.[0] + ':'}&nbsp;</h1>
                                 <span className="regular">{solution?.split(':')?.[1]}</span>
                              </div>
                            )
                          })}
                        </div>
                      </TabPanel> 
                      : ''}
                      {/* {checkSolutions(data?.categories) &&
                      <TabPanel>
                        <div>
                          {(data?.categories?.includes('urban-infrastructure-parkinglots') ||
                            data?.categories?.includes('urban-infrastructure-pedestrian-areas') ||
                            data?.categories?.includes('urban-infrastructure-streets') ||
                            data?.categories?.includes('urban-infrastructure-parks-and-gardens')) &&
                          <div className="box-p">
                            <h1 className="border">
                              {translate("solutionsUrbanInfrastructure")}
                            </h1>
                          </div>}
                          {(data?.categories?.includes('transport-infrastructure-highway') ||
                            data?.categories?.includes('transport-infrastructure-roads') ||
                            data?.categories?.includes('transport-infrastructure-bridge') ||
                            data?.categories?.includes('transport-infrastructure-tunnel')) &&
                          <div className="box-p">
                            <h1 className="border">
                              {translate("solutionsTransportInfrastructure")}
                            </h1>
                          </div>}
                          {(data?.categories?.includes('shopping-areas-gas-stations-and-car-service') || 
                            data?.categories?.includes('shopping-areas-malls-and-shopping-centers') || 
                            data?.categories?.includes('shopping-areas-supermarkets')) &&
                          <div className="box-p">
                            <h1 className="border">
                              {translate("solutionsTradeAreas")}
                            </h1>
                          </div>}
                          {(data?.categories?.includes('industrial-and-logistics-areas-airport') ||
                            data?.categories?.includes('industrial-and-logistics-areas-dock')) &&
                          <div className="box-p">
                            <h1 className="border">
                              {translate("solutionsConstructions")}
                            </h1>
                          </div>}
                        </div>
                      </TabPanel>} */}
                      {(productData?.drawings?.bg?.length
                      || productData?.drawings?.en?.length
                      || productData?.mediaPhotos?.length
                      || productData?.mediaVideos?.length) ?
                      <TabPanel>
                        <div>
                            <div className={`${productData?.mediaPhotos?.length 
                               || productData?.mediaVideos?.length ? 'box-p larger-padding' : ''}`}>
                          
                              {(productData?.mediaPhotos?.length 
                               || productData?.mediaVideos?.length) ?
                                <h1>{translate("tabOptionMedia")}</h1> : ''}

                              {(productData?.mediaPhotos?.length 
                               || productData?.mediaVideos?.length) ?
                               <div className={`img-wrapper ${!loaded ? 'center' : ''}`}>
                                {productData?.mediaPhotos?.map((img, index) => {
                                  return <img src={img} onLoad={() => handleImageLoad(index, productData?.mediaPhotos?.length)} alt='drawing' />
                              })}

                              {productData?.mediaVideos?.map(video => {
                                return (
                                <div className="video-container" style={{ position: "relative", height: "100%", width: "100%", cursor: "pointer" }}>
                                  <video
                                    controls={true}
                                    playsInline={false}
                                    muted={true}
                                    preload="auto"
                                    loop={true}
                                    autoPlay={true}
                                    style={{ position: "absolute", top: "20%", bottom: "73%", maxWidth: "100%", minWidth: "100%", height: "auto", objectFit: "contain" }}
                                  >
                                    <source src={video} type="video/mp4" />
                                  </video>
                                </div>
                                )
                            })}

                            {!loaded && (<Lottie options={defaultOptions} height={50} width={50} />)}
                              </div> : ''}
                            
                          </div>

                          {(productData?.drawings?.bg?.length
                         || productData?.drawings?.en?.length) ?
                          <div className="box-p">
                            <h1>
                              {translate("products-KMU-HydrooilBypassTabDrawings")}
                            </h1>
                            <div>{drawingsList}</div>
                          </div> : ''}
                        </div>
                      </TabPanel> : ''}
                    </PerfectScrollbar>
                  </div>
                </Tabs>
              </div>
            </div>
            {/* <div className="col col-Product-gallery">
              <div className="product-gallery">
                <div className="slider-main">
                  <Slider
                    asNavFor={data?.nav2}
                    // ref={(slider) => (this.slider1 = slider)}
                    arrows={false}
                  >
                    {imagesList}
                  </Slider>
                </div>
                <div className="slider-thumbs">
                  <Slider
                    asNavFor={data?.nav1}
                    // ref={(slider) => (this.slider2 = slider)}
                    slidesToShow={3}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={true}
                  >
                    {imagesList}
                  </Slider>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
