import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../actions';
import './styles.scss';

export default function HeaderRightSection() {
    const language = useSelector(state => state.language.selectedLanguage);
    const dispatch = useDispatch();

  return (
    <div className="flex-wrapper-languages">
    <div className="languages-container">
      <div
        className={`lang ${language === "en" ? "active" : ""}`}
        onClick={() => {
          dispatch(setLanguage("en"));
          window.location.reload();
        }}
      >
        EN
      </div>
      <div
        className={`lang ${language === "bg" ? "active" : ""}`}
        onClick={() => {
          dispatch(setLanguage("bg"));
          window.location.reload();
        }}
      >
        BG
      </div>
    </div>
  </div>
  )
}
