import React, { Component } from "react";
import { translate } from "../../translations/localization";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Calculator2 from "./calculator2";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import "./styles.scss";

class CalculationLinearDrainage extends Component {
  render() {
    return (
      <div className="calculation-liner-drainage page-bg">
        <div
          className="container"
          style={window.innerWidth < 1600 ? { width: "100%" } : {}}
        >
          <div className="content">
            <div className="top-content">
              <div className="breadcrumbs">
                <Link to="/specialized-materials-list" className="link-back">
                  <span className="opacity">{translate("forDesignersLinearDrainage")}</span>
                </Link>
              </div>
            </div>

            <div className="main-content">
              <div className="scroll">
                <PerfectScrollbar>
                  <h1 className="calculator-title">{translate("forDesignersLinearDrainage")}</h1>
                  <p className="calculator-subtitle">{translate("extraData")}</p>
                  <Calculator2 />
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
        {/* <NavSide /> */}
      </div>
    );
  }
}

export default connect()(CalculationLinearDrainage);
