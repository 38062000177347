import React, { Component } from 'react'
import { connect } from 'react-redux'
import SensorsDesktop from './SensorsDesktop';
import SensorsMobile from './SensorsMobile';
import { withGetScreen } from 'react-getscreen'

class Sensors extends Component {
  render() {
    // if (this.props.isMobile()) {
    //   return <SensorsMobile />;
    // } else {
    //   return <SensorsDesktop />;
    // }
   return <SensorsDesktop />
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Sensors, options))