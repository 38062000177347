import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "../../translations/localization";
import "./styles.scss";

class BIM extends Component {
  render() {
    return (
      <div className="BIM-container">
        <div className="inner-container">
          <div className="left-container">
            <h1>Building Information Modeling – BIM</h1>
            <p>{translate("hydroproBIMFirstParagraph")}</p>
            <p>{translate("hydroproBIMSecondParagraph")}</p>
            <p>{translate("hydroproBIMThirdParagraph")}</p>
            <p>{translate("hydroproBIMForthParagraph")}</p>
            <div className="flex-container">
              <div
                className="bim-button"
                onClick={() => {
                  window.open("/bim/HYDROPRO-Line.rfa");
                  window.open("/bim/HYDROPRO-Line.txt");
                }}
              >
                {translate("BIMOptionA")}
              </div>
              <div
                className="bim-button"
                onClick={() => window.open("/bim/HYDROPRO-Line.rvt")}
              >
                {translate("BIMOptionB")}
              </div>
              <div
                className="bim-button"
              >
                {translate("BIMUserGuidance")}
              </div>
            </div>
          </div>
          <div className="right-container" />
        </div>
        {/* <NavSide /> */}
      </div>
    );
  }
}
export default connect()(BIM);
