import { ofType, ActionsObservable } from "redux-observable";
import { StarterTypes, ReferenceTypes } from "../actions";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { API_URL } from "../config/settings";

export const getReferencesEpic = (action$, state$) => {
    return action$.pipe(
      ofType(ReferenceTypes.GET_REFERENCES),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/reference/list`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: StarterTypes.STOP_LOADING,
              });
              obs.next({
                type: ReferenceTypes.GET_REFERENCES_SUCCESS,
                payload: response.payload,
              });
              obs.complete();
            });
          }),
          catchError((err) => {
            alert("Грешка", "невалидни данни!");
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.next({
                type: StarterTypes.STOP_LOADING,
              });
              // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
              obs.complete();
            });
          })
        );
      })
    );
  };

export const getSingleReferenceEpic = (action$, state$) => {
    return action$.pipe(
      ofType(ReferenceTypes.GET_SINGLE_REFERENCE),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/reference/${payload?._id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: StarterTypes.STOP_LOADING,
              });
              obs.next({
                type: ReferenceTypes.GET_SINGLE_REFERENCE_SUCCESS,
                payload: response.payload,
              });
              obs.complete();
            });
          }),
          catchError((err) => {
            alert("Грешка", "невалидни данни!");
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.next({
                type: StarterTypes.STOP_LOADING,
              });
              // obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
              obs.complete();
            });
          })
        );
      })
    );
  };