import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { startLoading } from "../../actions";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import "./mstyles.scss";

class HomeMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeproducts: [
        {
          name: translate("homepageTreatmentPlant"),
          img: "/home/prechistvatelna-stancia.png",
          description: translate("homepageTreatmentPlantDescription"),
          link: "",
          pdf: false,
        },
        {
          name: translate("homepageHydrotank"),
          img: "/home/hydrotank.png",
          description: translate("homepageHydrotankDescription"),
          link: "/solution/tanks",
          pdf: false,
        },
        {
          name: translate("homepagePumpingStation"),
          img: "/home/pompena-stancia.png",
          description: translate("homepagePumpingStationDescription"),
          link: "",
          pdf: false,
        },
        // {
        //   name: translate("homepageBench"),
        //   img: "/home/bench.png",
        //   description: translate("homepageBenchDescription"),
        //   link: "",
        //   pdf: false,
        // },
        {
          name: translate("homepageCascadeShaft"),
          img: "/home/kaskadna.png",
          description: translate("homepageCascadeShaftDescription"),
          link: "/solution/shaft-cascading",
        },
        {
          name: translate("homepageHydroproLine"),
          img: "/home/hydroline.png",
          description: translate("homepageHydroproLineDescription"),
          link: "/solution/hydropro-line",
          pdf: false,
        },
        {
          name: translate("homepageMaxi150"),
          img: "/home/61540000-maxi-150-d400-r00045.png",
          description: translate("homepageMaxi150Description"),
          link:
            "",
          pdf: true,
        },
        {
          name: translate("homepageTreeProtectionSystem"),
          img: "/home/treeprotectors.png",
          description: translate("homepageTreeProtectionSystemDescription"),
          link: "/data/hydrotek-centralparts-bars/HYDROTEC-CATALOG-TREE.pdf",
          pdf: true,
        },
        {
          name: translate("homepageDsrsRevision"),
          img: "/home/dsrs-s-cap.png",
          description: translate("homepageDsrsRevisionDescription"),
          link: "/solution/outflowpoints-revision",
          pdf: false,
        },
        {
          name: translate("homepageHydrooilBypass"),
          img: "/home/hydrooil-bypass.png",
          description: translate("homepageHydrooilBypassDescription"),
          link: "/solution/hydrooil-bypass",
          pdf: false,
        },
        // {
        //   name: translate("homepageBin"),
        //   img: "/home/bin.png",
        //   description: translate("homepageBinDescription"),
        //   link:
        //     "https://hydropro.s3.eu-central-1.amazonaws.com/hydropro-website-data/hydrotek-centralparts-container/HYDROTEC-FAIL-1.pdf",
        //   pdf: true,
        // },
        {
          name: translate("homepageHydrotank"),
          img: "/home/hydrotank.png",
          description: translate("homepageHydrotankDescription"),
          link: "/solution/tanks",
          pdf: false,
        },
      ],
    };
  }

  startLoading() {
    this.props.dispatch(startLoading());
  }
  render() {
    const homeProducts = this.state.homeproducts.map((homeproducts, index) => (
      <div key={index}>
        {homeproducts?.pdf ? (
          <div className="item-wrap">
            <div className="item" id={"item-" + index}>
              <a
                href={homeproducts.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="box-info">
                  <img src={homeproducts.img} alt={homeproducts.name} />
                  <h1>{homeproducts.name}</h1>
                  <p>{homeproducts.description}</p>
                  {/* <div
                    className="additional-info"
                    onClick={() => {
                      if (homeproducts.pdf) {
                        window.open(homeproducts.link);
                      } else if (homeproducts.link) {
                        history.push(homeproducts.link);
                      } else {
                        return;
                      }
                    }}
                  >
                    Прочетете повече
                  </div> */}
                </div>
              </a>
            </div>
          </div>
        ) : (
          <Link to={homeproducts?.link} className="item-wrap">
            <div className="item" id={"item-" + index}>
              <div className="box-info">
                <img src={homeproducts?.img} alt={homeproducts?.name} />
                <h1>{homeproducts?.name}</h1>
                <p>{homeproducts?.description}</p>
                {/* <div className="additional-info">Прочетете повече</div> */}
              </div>
            </div>
          </Link>
        )}
      </div>
    ));
    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   arrows: false,
    // };
    let structuredJSON = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "HydroPro Ltd",
      image: "https://hydropro.bg/HydroPro-color.png",
      "@id": "",
      url: "https://hydropro.bg/",
      telephone: "+359 2/99 280 16",
      address: {
        "@type": "PostalAddress",
        streetAddress: "bul. Tsarigradsko shose 164",
        addressLocality: "Sofia",
        postalCode: "1138",
        addressCountry: "BG",
      },
    };
    return (
      <div className="home-m">
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(structuredJSON)}
        </script>
        <div className="container-m">
          <div className="products-list-m">
            <div className="wrapper">{homeProducts}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(HomeMobile);
