import React from "react";
import "./styles.scss";
import { translate } from "../../translations/localization";
import { setSensorTab } from "../../actions";
import { useDispatch } from "react-redux";

export default function TableCardsSensors({ data, headerElements }) {
  const dispatch = useDispatch();

  return (
    <>
      {data?.map((el, index) => {
        return (
          <div className="table-card-wrapper" key={`${el?.name}-${index}`}>
            <div className="table-row-wrapper">
              <div className="element-title">{headerElements[0]?.name}</div>
              <div className="element-content">{el?.number}</div>
            </div>

            <div className="table-row-wrapper">
              <div className="element-title">{headerElements[1]?.name}</div>
              <div className="element-content">{el?.details}</div>
            </div>

            <div className="table-row-wrapper">
              <div className="element-title">{headerElements[2]?.name}</div>
              <div className={`element-content bold ${el?.levelSlime <= 20 && el?.levelSlime ? "border" : ""}`}>
                {el?.levelSlime} %
              </div>
            </div>

            <div className="table-row-wrapper">
              <div className="element-title">{headerElements[3]?.name}</div>
              <div
                className={`element-content bold ${
                  el?.levelGrease <= 20 && el?.levelGrease ? "border" : ""
                }`}
              >
                {el?.levelGrease} %
              </div>
            </div>

            <div className="table-row-wrapper">
              <div className="element-title">{headerElements[3]?.name}</div>
              <div className={`element-content bold ${el?.battery <= 20 && el?.battery ? "border" : ""}`}>
                {el?.battery} %
              </div>
            </div>

            <div className="text-wrapper" onClick={() => dispatch(setSensorTab("history"))}>
              <div className="text">
                {translate("sensorsObjectTableHistory")}
              </div>
              <img src={require("../../assets/icons/history.svg")} alt="history" />
            </div>
          </div>
        );
      })}
    </>
  );
}
