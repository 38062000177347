import React from 'react'
import './styles.scss'

export default function Table({ headerElements, rowElements, onScroll, scrollRef, handleSort }) {
  return (
    <div className='table-wrapper desktop'>
      <div className='table-header'>
        {headerElements?.map((el, index) => {
            return (
                <div className={`header-element-wrapper ${el?.size}`} key={`${el?.name}-${index}`} onClick={() => handleSort && handleSort(el.name, el.sort)}>
                    <div className='element'>{el?.name}</div>
                    <img src={require('../../assets/icons/arrow-down.svg').default} className='arrow' alt="open" style={ el?.sort ? { transform: "rotate(180deg)" } : {} } />
                </div>
            )
        })}
      </div>

      <div className='table-main' onScroll={onScroll} ref={scrollRef} >
            {rowElements}
      </div>
    </div>
  )
}
