import { SearchTypes } from "../actions";

const initialState = {
  query: "",
};

const search = (state = initialState, { type, payload }) => {
  switch (type) {
    case SearchTypes.SET_QUERY:
      return { ...state, query: payload };
    default:
      return state;
  }
};
export default search;
