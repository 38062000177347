import React from 'react';
import { history } from '../../config/stores';
import { translate } from '../../translations/localization';
import { NavMainMobile, NavMainMobileItems } from '../NavMain';
import { useState, useEffect } from 'react';
import './styles.scss';

export default function HeaderSensors({ login }) {
  const [ toggleNav, setToggleNav ] = useState(false)
  const [ width, setWidth ] = useState(0);

  let isMobile = width < 900;

  useEffect(() => {
    window.addEventListener('resize', setWidth(window.innerWidth));
    isMobile = window.innerWidth < 900

    return () => window.removeEventListener('resize', setWidth(window.innerWidth));
  }, [window.innerWidth]);

  return (
    <div className='header sensors'>
      <div className={`header-left-section-wrapper ${(toggleNav && isMobile) ? 'hidden' : ''}`}>
        <img src={require("../../assets/icons/white-hydropro.svg")} className='header-logo-new'
        // onClick={() => history.push("/")} 
        onClick={() => {
         if (isMobile) setToggleNav(!toggleNav)
         else history.push("/")
        }} 
        alt="logo" />

        {login && 
        <div className='option-wrapper flex mobile'>
           <img src={require('../../assets/icons/call.svg')} alt="phone" />
        </div>}
      </div>

      {login &&
      <div className={`header-right-section-wrapper flex ${(toggleNav && isMobile) ? 'hidden' : ''}`}>
        <div className='option-wrapper flex' onClick={() => history.push('/sensors')}>
            <p>{translate("OMVBulgaria")}</p>
            <img src={require('../../assets/icons/logout.svg')} className='icon small' alt="log out" />
        </div>
        <div className='option-wrapper flex desktop'>
           <img src={require('../../assets/icons/call.svg')} alt="phone" />
        </div>
      </div>}

      {/* <div className="col">
        <NavMainMobile />
      </div> */}

      {/* {toggleNav &&
      <div className="col">
        <div className='nav-main-mobile-wrap isOpen'>
          <div onClick={() => setToggleNav(!toggleNav)}  className='nav-main-toggle'>
            <NavMainMobileItems />
          </div>
        </div>
      </div>} */}

      {(toggleNav && isMobile) &&
      <div className="col">
        <div className='flex'>
          <img src={require("../../assets/icons/white-hydropro.svg")} className='header-logo-new' onClick={() => history.push("/")}  alt="logo" />
          <img src={require("../../assets/icons/close-light.svg")} className="close-icon" onClick={() => setToggleNav(!toggleNav)} alt="close"/>
        </div>
        <div className='nav-main-mobile-wrap isOpen'>
          <div onClick={() => setToggleNav(!toggleNav)}  className='nav-main-toggle'>
            <NavMainMobileItems />
          </div>
        </div>
      </div>}
    </div>
  )
}
