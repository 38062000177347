import React from 'react';
import moment from 'moment';
import { translate } from "../../translations/localization";
import { setSensorTab } from "../../actions";
import { useDispatch } from "react-redux";

export default function SensorsHistoryElements({ data }) {
    const dispatch = useDispatch();

  return (
    <>
      {data?.map((el, i) => {
        return (
          <div className={`table-row ${i + 1}`}>
            <div className="element medium">{moment(el?.date || '').format('DD MMMM | HH:mm') || "— —"}</div>
            <div className='element small bold'>
              <div className={`element-text ${(el?.levelSlime <= 20 && el?.levelSlime) ? 'border' : ''}`}>{el?.levelSlime ? `${el?.levelSlime} %` : "— —"}</div>
            </div>
            <div className='element small bold'>
              <div className={`element-text ${(el?.levelGrease <= 20 && el?.levelGrease) ? 'border' : ''}`}>{el?.levelGrease ? `${el?.levelGrease} %` : "— —"}</div>
            </div>
            <div className='element last bold'>
              <div className={`element-text ${(el?.battery <= 20 && el?.battery) ? 'border' : ''}`}>{el?.battery ? `${el?.battery} %` : "— —"}</div>
            </div>
          </div>
        );
      })}
    </>
  )
}
