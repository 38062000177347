import React, { Component } from "react";
import { connect } from "react-redux";
// import { Socials, NavMainMobileItems } from '../../components'
import "./styles.scss";

class FooterMobile extends Component {
  render() {
    return (
      <div className="footer-m">
        {/* <div className='footer-nav-main'>
          <NavMainMobileItems />
        </div> */}
        <div className="row row-socials">
          <div className="col">
            <a
              href="https://www.linkedin.com/in/hydropro-bg-7612a91b5/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-linkedIn"></span>
            </a>
          </div>
          <div className="col">
            <a
              href="https://www.facebook.com/HydroPro-BG-108106688391858/?ref=pages_you_manage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-facebook"></span>
            </a>
          </div>
        </div>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2930.3276638797297!2d23.2514173!3d42.7391193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7fccb60061c9f2c!2sHydropro%20BG%20Ltd.!5e0!3m2!1sbg!2sbg!4v1662724304063!5m2!1sbg!2sbg" width="600" height="450" style={{border: 0, display: 'none'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      </div>
    );
  }
}

export default connect()(FooterMobile);
