export const ProductTypes = {
    GET_PRODUCTS: "product/GET_PRODUCTS",
    GET_PRODUCTS_SUCCESS: "product/GET_PRODUCTS_SUCCESS",
    GET_PRODUCTS_BY_CATEGORY: "product/GET_PRODUCTS_BY_CATEGORY",
    GET_PRODUCTS_BY_CATEGORY_SUCCESS: "product/GET_PRODUCTS_BY_CATEGORY_SUCCESS",
    GET_SINGLE_PRODUCT: "product/GET_SINGLE_PRODUCT",
    GET_SINGLE_PRODUCT_SUCCESS: "product/GET_SINGLE_PRODUCT_SUCCESS",
    GET_CATEGORIES: "product/GET_CATEGORIES",
    GET_CATEGORIES_SUCCESS: "product/GET_CATEGORIES_SUCCESS",
    SEARCH_CATEGORIES: "product/SEARCH_CATEGORIES",
    SEARCH_CATEGORIES_SUCCESS: "product/SEARCH_CATEGORIES_SUCCESS",
    SEARCH_CATEGORY: "product/SEARCH_CATEGORY",
    SEARCH_CATEGORY_SUCCESS: "product/SEARCH_CATEGORY_SUCCESS",
  };
  
  export const getProducts = (payload) => ({
    type: ProductTypes.GET_PRODUCTS,
    payload
  });
  
  export const getProductsSuccess = (payload) => ({
    type: ProductTypes.GET_PRODUCTS_SUCCESS,
    payload
  });
  
  export const getProductsByCategory = (payload) => ({
    type: ProductTypes.GET_PRODUCTS_BY_CATEGORY,
    payload
  });
  
  export const getProductsByCategorySuccess = (payload) => ({
    type: ProductTypes.GET_PRODUCTS_BY_CATEGORY_SUCCESS,
    payload
  });

  export const getSingleProduct = (payload) => ({
    type: ProductTypes.GET_SINGLE_PRODUCT,
    payload
  });
  
  export const getSingleProductSuccess = (payload) => ({
    type: ProductTypes.GET_SINGLE_PRODUCT_SUCCESS,
    payload
  });

  export const getCategories = (payload) => ({
    type: ProductTypes.GET_CATEGORIES,
    payload
  });
  
  export const getCategoriesSuccess = (payload) => ({
    type: ProductTypes.GET_CATEGORIES_SUCCESS,
    payload
  });

  export const searchCategories = (payload) => ({
    type: ProductTypes.SEARCH_CATEGORIES,
    payload
  });
  
  export const searchCategoriesSuccess = (payload) => ({
    type: ProductTypes.SEARCH_CATEGORIES_SUCCESS,
    payload
  });

  export const searchCategory = (payload) => ({
    type: ProductTypes.SEARCH_CATEGORY,
    payload
  });
  
  export const searchCategorySuccess = (payload) => ({
    type: ProductTypes.SEARCH_CATEGORY_SUCCESS,
    payload
  });