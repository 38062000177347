import React from 'react';
import { translate } from '../../translations/localization'
import 'react-perfect-scrollbar/dist/css/styles.css';
import './stylesNew.scss';
import { history } from '../../config/stores';

export default function SpecializedMaterialsListDesktopNew() {

  return (
    <div className='specialized-materials-list-new page-bg'>
    <div className='container'>
      <div className='content'>
        <div className='main-content'>
          <div className='section' onClick={() => history.push('/calculation-linear-drainage')}>
            <div className='top-wrapper'>
              <h1>{translate('forDesignersLinearDrainage')}</h1>
              <img src={require('../../assets/icons/arrow.svg')} alt="arrow"/>
            </div>

            <div className='main-wrapper'>
              <p>{translate('forDesignersTankCalculatorDescription')}</p>
              <img src={require('../../assets/images/calculator-1-thumbnail.png')}  alt='calculator thumbnail'/>
            </div>
          </div>

          <div className='section' 
          style={{ cursor: 'default' }}
          // onClick={() => history.push('/calculation-tank-volume')}
          >
            <div className='top-wrapper'>
              <h1>{translate('forDesignersTankCalculator')}</h1>
              <img src={require('../../assets/icons/arrow.svg')} alt='arrow' />
            </div>

            <div className='main-wrapper'>
              <p>{translate('forDesignersTankCalculatorDescription')}</p>
              <img src={require('../../assets/images/calculator-2-thumbnail.png')}  alt='calculator thumbnail'/>
            </div>
          </div>
        </div>

        {/* <div className='scroll'>
          <PerfectScrollbar>
            <div className='hidden-x'>
              <div className='row'>
                {materialsItems}
              </div>
            </div>
          </PerfectScrollbar>
        </div> */}
      </div>
    </div>
  </div>
  )
}
