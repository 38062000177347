import React from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Utilities } from "../../utilities";
import { translate } from "../../translations/localization";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import animation from "../../LottieAnimations/HydroPro-loading-inbutton.json";
import { getProducts, searchCategories, searchCategory, getProductsByCategory, startLoading } from "../../actions";
import "./styles.scss";

export default function ProductsListDesktopNew() {
  const { name } = useParams();
  const dispatch = useDispatch();

  const productsData = useSelector((state) => state.products.products);
  const productsByCategoryData = useSelector((state) => state.products.productsByCategory);
  const category = useSelector((state) => state.products.category);
  const categoryId = useSelector((state) => state.products.categoryId);
  const hasNextPage = useSelector(state => state.products.hasNextPage);
  const nextPage = useSelector(state => state.products.nextPage);
  const loading = useSelector(state => state.starter.loading);
  const [ categoryName, setCategoryName ] = useState('')
  const [ isLoaded, setIsLoaded ] = useState(false)

  const productsList = productsData?.map((product, i) => {
    return (
      <div className="col" key={product?._id}>
        <Link
          // to={product?.pdfFile ? "/Products-list/" + name : "/product/" + product?._id}
          to={product?.pdfFile ? '/Products-list/' + name : "/product/" + product?._id}
          className={`item-link ${product?.pdfFile ? 'pdf' : ''}`}>
          <span className="img" onClick={() => {
              if (product?.pdfFile) {
                window.open(product.pdfFile);
              }
            }}>
            {product?.pdfFile && <img src={require('../../assets/icons/pdf-file-blue.svg')} alt='pdf' className='pdf-icon' /> }
            <img className="main-photo" src={product?.mainPhoto} alt="product" />
          </span>
          <span className="info">
            <h2 className="h3">{Utilities.translateLanguage(product?.name)}</h2>
          </span>
        </Link>
      </div>
    );
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // useEffect(() => {
  //   const products = Utilities.getProductsByCategory(allProducts, name);
  //   const category = Utilities.getCategoryName(categories, name);

  //   setProducts(products);
  //   setCategoryName(category.categoryName);
  // }, []);

  const loadPageOnScroll = () => {
    if (nextPage) {
      let payload = { page: nextPage }
      dispatch(getProducts(payload)) 
      // dispatch(getProductsByCategory(payload)) 
    }
  }

  useEffect(() => {
    if (name) {
      setCategoryName(name)
      dispatch(searchCategories({ search: name }))
    }
  }, [name]);
  
  // useEffect(() => {
  //   setCategoryName(name)
  //   dispatch(searchCategories({ search: name }))
  // }, [dispatch, name]);

  useEffect(() => {
    // if (category?._id) {
    //   dispatch(getProductsByCategory({ category: [category?._id] }))
    //   // dispatch(getProducts({ category: [category?._id] }))
    //   setIsLoaded(true)
    // }
    // dispatch(startLoading())
    if (category?._id) {
      dispatch(getProducts({ category: [category?._id] }))

      setTimeout(() => setIsLoaded(true), 800);
    }
    // setIsLoaded(true)
    // dispatch(getProducts({ payload: { category: [category?._id] }, onSuccess: () => setIsLoaded(true)}))
  }, [category]);

  // useEffect(() => {
  //   if (productsData.length > 0) {
  //     setCategoryName(Utilities.translateLanguage(productsData[0]?.category?.name))
  //   }
  // }, [productsData]);

  console.log(productsData)

  return (
    <div className="Products-list page-bg">
      <div className="container products-list">
        <div className="content">
          <div className="top-content">
            <div className="breadcrumbs">
              <img src={require('../../assets/icons/arrow-back-white.svg')} alt='< go back' className="arrow-icon" />
              <Link to={`/Products/${name}`} className="link-back uppercase">
                {translate("headerProducts")} - {Utilities.translateLanguage(category?.name) || ""}
              </Link>
            </div>
          </div>

          <div className="main-content">
            <div className={`scroll ${(!productsData?.length || !isLoaded) ? 'center' : ''}`}>
              <PerfectScrollbar onYReachEnd={() => loadPageOnScroll()}>
                <div className="row row-items">
                  {/* {productsData?.length === 0
                    ? "Няма добавени продукти"
                    : productsList} */}

                  {/* {loading ?
                    (<Lottie options={defaultOptions} height={50} width={50} />)
                   : productsList} */}

                  {!isLoaded ?
                    (<Lottie options={defaultOptions} height={50} width={50} />)
                   : (productsData?.length === 0 && isLoaded)
                    ? translate("productsEmptyState")
                    : productsList}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
