import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../translations/localization';
import './chart.scss'

class Chart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            x: 0,
            y: 0,
            circleHeight: 300,
            fillHeight: 0
        };

        this._onMouseMove = this._onMouseMove.bind(this);
    }


    _onMouseMove(e) {
        let circleHeight = this.state.circleHeight;
        this.setState({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY, fillHeight: (circleHeight - e.nativeEvent.offsetY) }, ()=>this.props.onChange);
        this.props.chartHeight(this.state.fillHeight)
        // document.getElementById('fill').addEventListener('mousedown', function (e) {
        //     e.preventDefault();
        //     window.addEventListener('mousemove', resize);
        //     //window.addEventListener('mouseup', stopResize);
        // });

        // resize(e) {
        //     document.getElementById('fill').style.height = (300-e.nativeEvent.offsetY);

        //     // if (currentResizer.classList.contains('bottom-right')) {
        //     //     element.style.width = e.pageX - element.getBoundingClientRect().left + 'px'
        //     // }
        // };
        //document.getElementById('fill').style.height = (300-e.nativeEvent.offsetY);
        //this.setState({ x: e.screenX, y: e.screenY });
    }


    render() {

        // const {
        //     x, y, circleHeight, fillHeight
        // } = this.state;


        return (
            <div>
                <div className='chart'>
                    <div className='chart-axis'>
                        <div className='circle-box'>
                            <div className='circle' id='circle' onMouseMove={this._onMouseMove.bind(this)}>
                            </div>
                            <div className='fill' id='fill' style={{ height: this.props.fillHeight }}></div>
                        </div>
                    </div>
                    <div className='axis-x'>{translate("hydroproLineCalculatorHydraulicSizingGraphicQuantityVelocity")}</div>
                    <div className='axis-y'>{translate("hydroproLineCalculatorHydraulicSizingGraphicFillingHeight")}</div>
                </div>


                {/* 
                                <br /><br /><br /><br />
                                <div>
                    x = {this.state.x}, y = {this.state.y}
                    <br />{fillHeight} fill height
                    <br />
                    {(fillHeight / 300) * 100} %
                </div> */}
            </div>
        )
    }
}

export default connect()(Chart)