import React from "react";
import { setModal } from "../../actions";
import { useDispatch } from "react-redux";
import "./styles.scss";

export default function SearchBar() {
  const dispatch = useDispatch();

  return (
    <div className="search-bar-container">
        <div
          className="search-icon"
          onClick={() => {
            dispatch(setModal(true))
          }}
        />
    </div>
  );
}
