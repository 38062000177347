export const ReferenceTypes = {
    GET_REFERENCES: 'reference/GET_REFERENCES',
    GET_REFERENCES_SUCCESS: 'reference/GET_REFERENCES_SUCCESS',
    GET_SINGLE_REFERENCE: 'reference/GET_SINGLE_REFERENCE',
    GET_SINGLE_REFERENCE_SUCCESS: 'reference/GET_SINGLE_REFERENCE_SUCCESS',
}

export const getReferences = (payload) => ({
    type: ReferenceTypes.GET_REFERENCES,
    payload
})

export const getReferencesSuccess = (payload) => ({
    type: ReferenceTypes.GET_REFERENCES_SUCCESS,
    payload
})

export const getSingleReference = (payload) => ({
    type: ReferenceTypes.GET_SINGLE_REFERENCE,
    payload
})

export const getSingleReferenceSuccess = (payload) => ({
    type: ReferenceTypes.GET_SINGLE_REFERENCE_SUCCESS,
    payload
})