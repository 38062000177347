import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import { history } from "../../config/stores";
import { Utilities } from "../../utilities";
import { Footer } from "../../components";
import { MakeAQueryBttn } from "../../components";
import { translate } from "../../translations/localization";
import "./mstyles.scss";
import { Helmet } from "react-helmet";

class SolutionMobile extends Component {
  componentDidMount() {
    const product = Utilities.getProductById(
      this.props.products,
      this.props.match.params.id
    );

    this.setState(
      {
        nav1: this.slider1,
        nav2: this.slider2,
        categories: product.categories,
        title: product.title,
        solutions: product.solutions,
        description: product.description,
        quality: product.quality,
        efficiency: product.efficiency,
        application: product.application,
        techinfo: product.techinfo,
        drawings: product.drawings,
        installation: product.installation,
        images: product.images,
        product,
      },
      () => {
        console.log(this.state.title);
      }
    );

  }

  state = {
    nav1: null,
    nav2: null,
    openModal: false,
    height: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },

    product: null,
    activeTab: null,
    categories: null,
    title: null,
    solutions: null,
    description: null,
    quality: null,
    efficiency: null,
    application: null,
    techinfo: [],
    images: [],
    drawings: [],
    installation: "",
  };

  toggle = (index) => {
    const { height } = this.state;
    height[index] = height[index] === 0 ? "auto" : 0;
    this.setState({
      height: height,
    });
  };
  onOpenModal = () => {
    this.setState({ openModal: true });
  };
  onCloseModal = () => {
    this.setState({ openModal: false });
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };
    const techinfoList = this.state.drawings.map((techinfo, index) => (
      <div className="techinfo-item">
        <a href={techinfo} title="">
          {/* {techinfo.slice(techinfo.lastIndexOf("/") + 1)} */}
          {Utilities.renderUrl(techinfo) || techinfo?.slice(techinfo.lastIndexOf("/") + 1) || techinfo || ''}
          {/* {techinfo.slice(techinfo.lastIndexOf("/") + 1) || techinfo} */}
        </a>
      </div>
    ));

    const drawingsList = this.state.drawings.map((drawings, index) => (
      <div className="drawings-item">
        <a href={drawings} title="">
          {/* {drawings?.slice(drawings.lastIndexOf("/") + 1) || drawings} */}
          {Utilities.renderUrl(drawings) || drawings || ''}
        </a>
      </div>
    ));

    const imagesList = this.state.images.map((images, index) => (
      <div className="img-wrapper-margin">
        <div className="product-img">
          <img src={images} alt="product image" />
        </div>
      </div>
    ));
    let structuredJSON = {
      "@context": "https://schema.org/",
      "@type": "Product",
      brand: "Hydroil",
      name: this.state.title,
      description: this.state.description,
      image: [
        `https://hydropro.bg${this.state.images[0]}`,
        `https://hydropro.bg${this.state.images[1]}`,
        `https://hydropro.bg${this.state.images[2]}`,
      ],
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "0",
        ratingCount: "1",
      },
    };

    return (
      <div className="solution-m page-bg">
        <Helmet>
          <script className="structured-data-list" type="application/ld+json">
            {JSON.stringify(structuredJSON)}
          </script>
        </Helmet>
        <div className="container-m">
            <div className='top-wrapper' onClick={() => history.goBack()}>
              <img src={require("../../assets/icons/arrow-back-white.svg")} alt="go back" />
              <h1>{this.state.title}</h1>
            </div>
          <div className="content-m">

            <div className="tabs-list-m">
              <div>
                <div
                  className="tab-name"
                  style={{ borderBottom: "2px solid #1fecff" }}
                >
                  {translate("tabOptionAboutProduct")}
                </div>
              </div>

              <div>
                <div className="tab-name subtitle">{translate("description")}</div>
                <div className="description">{this.state.description}</div>
              </div>

              <div>
                <div className="tab-name subtitle">{translate("tabOptionSpecificationProduct")}</div>
                {/* <div>{this.state.description}</div> */}
              </div>

              <div>
                <div className="tab-name subtitle">{translate("products-KMU-HydrooilBypassTabApplication")}</div>
                <div className="description">{this.state.application}</div>
              </div>

              <div className="margin">
                <div className="tab-name subtitle">{translate("quality")}</div>
                <div className="description">{this.state.quality}</div>
              </div>

              <div>
                <div className="tab-name subtitle">{translate("efficiency")}</div>
                <div className="description">{this.state.efficiency}</div>
              </div>

              <div className="flex-col-wrapper margin">
                <div className="tab-name subtitle">{translate("producer")}</div>
                <img src={require("../../assets/icons/white-hydropro.svg")} className='header-logo-new' alt="logo" />
              </div>

              <div className="full-width-query-bttn">
                <MakeAQueryBttn />
              </div>

              <div className="margin">
                <div className="tab-name" style={{ borderBottom: "2px solid #1fecff" }}>
                  {translate("products-KMU-HydrooilBypassTabTechnicalInformation")}
                </div>
              </div>

              <div className="flex-col-wrapper">
                <div className="tab-name subtitle">
                  {translate("products-KMU-HydrooilBypassTabInstallation")}
                </div>
                <div className="description">{this.state.installation}</div>
              </div>

              <div className="flex-col-wrapper margin">
                  <div className="tab-name subtitle">
                    {translate("products-KMU-HydrooilBypassTabTechnicalInformation")}
                  </div>
                  <div className="description">{techinfoList}</div>
              </div>

              {Utilities.checkSolutions(this.state.categories) &&
              <div className="flex-col-wrapper margin">
                  <div className="tab-name" style={{ borderBottom: "2px solid #1fecff" }}>
                    {translate("headerSolutions")}
                  </div>
                  <div className="description">{this.state.solutions}</div>
              </div>}

              {Utilities.checkSolutions(this.state.categories) &&
                        <div>
                          {(this.state?.categories?.includes('urban-infrastructure-parkinglots') ||
                            this.state?.categories?.includes('urban-infrastructure-pedestrian-areas') ||
                            this.state?.categories?.includes('urban-infrastructure-streets') ||
                            this.state?.categories?.includes('urban-infrastructure-parks-and-gardens')) &&
                          <div className="box-p">
                            <h1 className="border">
                              {translate("solutionsUrbanInfrastructure")}
                            </h1>
                          </div>}
                          {(this.state?.categories?.includes('transport-infrastructure-highway') ||
                            this.state?.categories?.includes('transport-infrastructure-roads') ||
                            this.state?.categories?.includes('transport-infrastructure-bridge') ||
                            this.state?.categories?.includes('transport-infrastructure-tunnel')) &&
                          <div className="box-p">
                            <h1 className="border">
                              {translate("solutionsTransportInfrastructure")}
                            </h1>
                          </div>}
                          {(this.state?.categories?.includes('shopping-areas-gas-stations-and-car-service') || 
                            this.state?.categories?.includes('shopping-areas-malls-and-shopping-centers') || 
                            this.state?.categories?.includes('shopping-areas-supermarkets')) &&
                          <div className="box-p">
                            <h1 className="border">
                              {translate("solutionsTradeAreas")}
                            </h1>
                          </div>}
                          {(this.state?.categories?.includes('industrial-and-logistics-areas-airport') ||
                            this.state?.categories?.includes('industrial-and-logistics-areas-dock')) &&
                          <div className="box-p">
                            <h1 className="border">
                              {translate("solutionsConstructions")}
                            </h1>
                          </div>}
                        </div>}

              {/* <div className="foldable-tab">
                <div className="tab-name subtitle" onClick={() => this.toggle(5)}>
                  {translate("products-KMU-HydrooilBypassTabTechnicalInformation")}
                </div>
                <AnimateHeight
                  className="tab-content"
                  duration={500}
                  height={this.state.height[5]}
                >
                  <div>{techinfoList}</div>
                </AnimateHeight>
              </div> */}

              {/* <div>
                <div className="tab-name subtitle">
                  {translate("products-KMU-HydrooilBypassTabInstallation")}
                </div>
                <div>{this.state.installation}</div>
              </div> */}

              {/* <div className="foldable-tab">
                <div className="tab-name subtitle" onClick={() => this.toggle(6)}>
                  {translate("products-KMU-HydrooilBypassTabDrawings")}
                </div>
                <AnimateHeight
                  className="tab-content"
                  duration={500}
                  height={this.state.height[6]}
                >
                  <div>{drawingsList}</div>
                </AnimateHeight>
              </div> */}

              <div className="margin">
                <div className="tab-name" style={{ borderBottom: "2px solid #1fecff" }}>
                  {translate("tabOptionMediaAndDrawings")}
                </div>
              </div>

              <h2>{translate("tabOptionMedia")}</h2>

              <div className="product-gallery-m">
                <div className="slider-main">
                  <Slider {...settings}>{imagesList}</Slider>
                </div>
              </div>

              <div className="flex-col-wrapper margin">
                <div className="tab-name subtitle">
                  {translate("products-KMU-HydrooilBypassTabDrawings")}
                </div>
                <div className="description">{drawingsList}</div>
              </div>

              <div className="full-width-query-bttn">
                <MakeAQueryBttn />
              </div>

            </div>

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products.data,
});

export default connect(mapStateToProps)(withRouter(SolutionMobile));
