import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../translations/localization'
import { Link } from 'react-router-dom'
import Calculator from './calculator'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

import './styles.scss'

class CalculationWallThicknessDesktop extends Component {

    render() {
        return (
            <div className='calculation-wall-thickness page-bg'>
                <div className='container'>

                    <div className='content'>

                        <div className='top-content'>
                            <div className='breadcrumbs'>
                                <Link to='/specialized-materials-list' className='link-back'><span className='opacity'>{translate("forDesignersSpecializedMaterials")}</span></Link>
                            </div>
                        </div>

                        <div className='main-content'>

                            {/* <div className='soon'>
                                <h1>Очаквайте скоро</h1>
                            </div>
                             */}
                            <div className='scroll'>
                                <PerfectScrollbar>
                                    <h1>{translate("hydroproLineCalculatorWallThickness")}</h1>
                                    <Calculator/>
                                </PerfectScrollbar>
                            </div>

                        </div>

                    </div>
                </div>

                {/* <NavSide /> */}

            </div>
        )
    }

}
export default connect()(CalculationWallThicknessDesktop)