import React from "react";
import { Utilities } from "../../utilities";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCategories, startLoading } from "../../actions";
import { useEffect, useRef } from "react";
import { translate } from "../../translations/localization";
import "./mstyles.scss";
import _ from "lodash";

export default function ProductsMobileNew() {
  const dispatch = useDispatch();
  const productsRef = useRef();

  const categoriesData = useSelector((state) => state.products.categories);
  const hasNextPage = useSelector((state) => state.products.hasNextPage);
  const nextPage = useSelector((state) => state.products.nextPage);

  const handleScrolling = () => {
    if (productsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = productsRef.current;

      if (
        Math.abs(scrollHeight - clientHeight - scrollTop) < 1 &&
        hasNextPage &&
        scrollTop !== 0
      ) {
        loadPageOnScroll();
      }
    }
  };

  const delayedScrolling = _.debounce(() => {
    handleScrolling();
  }, 300);

  const loadPageOnScroll = () => {
    let payload = { page: nextPage };

    dispatch(startLoading());
    dispatch(getCategories(payload));
  };

  useEffect(() => {
    dispatch(getCategories({ page: 1, showProducts: true, productsCount: 5 }));
  }, [dispatch]);

  return (
    <div className="Products-m page-bg">
      <div className="container-m">
        <div className="content-m" onScroll={delayedScrolling}>
          <div className="select-Products">
            <h1>{translate("headerProducts")}</h1>
          </div>

          {categoriesData?.map((category, index) => (
            <div className="solution-item" id={index} key={category?._id || index}>
              <NavLink to={`/Products-list/${category?.name?.en}`}>
                <span className="solution-img">
                  {category?.products?.length > 0 &&
                  <img
                    src={category?.products[0]?.mainPhoto || ''}
                    alt={category?.name?.en || "category-name"}
                  />}
                </span>
                <span className="solution-info">
                  <span className="solution-name">
                    <span>{Utilities.translateLanguage(category?.name)}</span>
                  </span>
                  <span className="icon-arrow-right"></span>
                </span>
                <p>
                  {category?.products
                    ?.map((p) => Utilities.translateLanguage(p?.name))
                    .join(", ")}
                </p>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
