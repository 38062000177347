import { CalculatorTypes } from "../actions";

const initialState = {
  data: [],
  graph: [],
  proceed: false,
  code: null,
  dataPipes: {},
};

const calculator = (state = initialState, { type, payload }) => {
  switch (type) {
    case CalculatorTypes.POST_CALCULATION_SUCCESS:
      return {
        ...state,
        data: payload.results,
        graph: payload.graph,
        dataPipes: payload.dataPipes,
      };
    case CalculatorTypes.POST_DATA_SUCCESS:
      return {
        ...state,
        data: payload.results,
        graph: payload.graph,
        dataPipes: payload.dataPipes,
      };
    case CalculatorTypes.POST_DATA_FAILURE:
      return { ...state, data: [], graph: [] };
    case CalculatorTypes.REQUEST_CODE_SUCCESS:
      return { ...state, proceed: payload === "SEND_OK" ? true : false };
    case CalculatorTypes.SEND_CODE_SUCCESS:
      return { ...state, code: payload };
    default:
      return state;
  }
};

export default calculator;
